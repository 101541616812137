import { Image, Stack, Text, IconButton, Spacer } from '@chakra-ui/react';
import File from '../../assets/images/file2.png';
import { CloseIcon } from '@chakra-ui/icons';

export default function LandCustomFile({ name, onRemove }) {
    return (
        <Stack
            boxShadow={'0px 0px 25px -14px #000'}
            w={{base: '100%', lg: '48%'}}
            borderRadius={'15px'}
            direction={'row'}
            alignItems={'center'}
            gap={2}
            p={4}
            mb={'25px !important'}
            _hover={{cursor: 'pointer'}}
        >
            <Image src={File} />
            <Text 
                fontFamily={'Poppins'}
                fontSize={'14px'}
            >
                {name ?? 'Loading...'}
            </Text>
            <Spacer />
            <IconButton
                icon={<CloseIcon />}
                size="sm"
                variant="ghost"
                color="gray.500"
                aria-label="Remove file"
                onClick={onRemove}
            />
        </Stack>
    )
}
