import { Stack, Box, FormLabel, Input, Text, Button, Icon, Heading, Image, Link, useToast, Spinner } from '@chakra-ui/react';
import { useState, useRef, useCallback, useEffect } from 'react';
import { GoogleMap, DrawingManager, Marker } from '@react-google-maps/api';
import { Link as ReactLink } from 'react-router-dom';
import { BiSearchAlt } from 'react-icons/bi';
import FullLoc from '../../assets/images/fullLoc.png';
import LandCustomFile from '../Customs/LandCustomFile.js';
import { landRegistration } from '../../utilities/ApiProvider';

export default function LandRegForm() {
    const [location, setLocation] = useState(null);
    const [latitude, setLatitude] = useState(null);
    const [longitude, setLongitude] = useState(null);
    const [area, setArea] = useState(0);
    const [coordinates, setCoordinates] = useState([]);
    const drawingManagerRef = useRef(null);
    const mapRef = useRef(null);
    const polygonRef = useRef(null);

    const onPolygonComplete = useCallback((polygon) => {
        const path = polygon.getPath();
        const polygonArea = window.google.maps.geometry.spherical.computeArea(path);
        const polygonCoordinates = path.getArray().map((point) => ({
            lat: point.lat(),
            lng: point.lng(),
        }));
        setArea(polygonArea);
        setCoordinates(polygonCoordinates);
        polygonRef.current = polygon;
    }, []);

    const onMarkerClick = useCallback((marker) => {
        const markerPoint = {
            lat: marker.getPosition().lat(),
            lng: marker.getPosition().lng(),
        };
        console.log('Selected marker:', markerPoint);
    }, []);

    const resetMap = useCallback(() => {
        if (polygonRef.current) {
            polygonRef.current.setMap(null);
            polygonRef.current = null;
        }
        setArea(0);
        setCoordinates([]);
    }, []);

    useEffect(() => {
        const loadGoogleMaps = () => {
            const googleMapsScript = document.createElement('script');
            googleMapsScript.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyB61admgPA3uYHaDuB8PYwLB48dvS3-14c&callback=initMap&libraries=drawing&v=weekly`;
            googleMapsScript.async = true;
            window.initMap = initMap;
            window.document.body.appendChild(googleMapsScript);
        };

        const initMap = () => {
        const map = new window.google.maps.Map(mapRef.current, {
            center: { lat: -34.397, lng: 150.644 },
            zoom: 8,
        });

        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
              (position) => {
                const { latitude, longitude } = position.coords;
                const initialLocation = new window.google.maps.LatLng(latitude, longitude);
                map.setCenter(initialLocation);
                setLatitude(latitude);
                setLongitude(longitude);
          
                // Use Google Maps Geocoding API to get location name
                const geocoder = new window.google.maps.Geocoder();
                geocoder.geocode({ location: initialLocation }, (results, status) => {
                  if (status === "OK") {
                    if (results[0]) {
                      const locationName = results[0].formatted_address;
                      setLocation(locationName);
                      // Use the locationName as needed in your code
                    } else {
                      console.log("No results found");
                    }
                  } else {
                    console.log("Geocoder failed due to: " + status);
                  }
                });
              },
              (error) => {
                console.error('Error getting the user location:', error);
              }
            );
        }        

        drawingManagerRef.current = new window.google.maps.drawing.DrawingManager({
            drawingMode: window.google.maps.drawing.OverlayType.POLYGON,
            drawingControl: true,
            drawingControlOptions: {
                position: window.google.maps.ControlPosition.TOP_CENTER,
                drawingModes: [window.google.maps.drawing.OverlayType.POLYGON],
            },
            circleOptions: {
                fillColor: "#ffff00",
                fillOpacity: 1,
                strokeWeight: 5,
                clickable: false,
                editable: true,
                zIndex: 1,
            },
        });

        drawingManagerRef.current.setMap(map);

        window.google.maps.event.addListener(
            drawingManagerRef.current,
            'polygoncomplete',
            onPolygonComplete
        );
        };

        loadGoogleMaps();
    }, [onPolygonComplete]);

    useEffect(() => {
        if (polygonRef.current) {
            window.google.maps.event.addListener(polygonRef.current, 'click', (event) => {
                const point = {
                    lat: event.latLng.lat(),
                    lng: event.latLng.lng(),
                };
                const marker = new window.google.maps.Marker({
                    position: point,
                    map: mapRef.current,
                });
                window.google.maps.event.addListener(marker, 'click', () => {
                    onMarkerClick(marker);
                });
                // Remove the previous marker
                if (polygonRef.current.previousMarker) {
                    polygonRef.current.previousMarker.setMap(null);
                }
                // Set the current marker as the previousMarker
                polygonRef.current.previousMarker = marker;
            });
        }
    }, [onMarkerClick]);

    const toast = useToast();
    const OwnershipImagesRef = useRef(null);
    const LandPropertyImagesRef = useRef(null);
    const [validation, setValidation] = useState(null);
    const [isLoading, setIsLoading] = useState(false); // Initialize loading state
    const [formData, setFormData] = useState({
        name: '',
        full_address: '',
    });
    const [selectedOwnershipImages, setSelectedOwnershipImages] = useState([]);
    const [selectedLandPropertyImages, setSelectedLandPropertyImages] = useState([]);

    const handleOwnershipImagesUpload = (event) => {
        const ownership_files = event.target.files;
        const ownership_readers = [];
        
        for (let i = 0; i < ownership_files.length; i++) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setSelectedOwnershipImages((prevImages) => [...prevImages, reader.result]);
            };
            reader.readAsDataURL(ownership_files[i]);
            ownership_readers.push(reader);
        }
    };
      
    const handleLandPropertyImagesUpload = (event) => {
        const property_files = event.target.files;
        const property_readers = [];
        
        for (let i = 0; i < property_files.length; i++) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setSelectedLandPropertyImages((prevImages) => [...prevImages, reader.result]);
            };
            reader.readAsDataURL(property_files[i]);
            property_readers.push(reader);
        }
    };

    const removeSelectedImage = (index, type) => {
        if (type === 'ownership') {
          setSelectedOwnershipImages((prevImages) => {
            const updatedImages = [...prevImages];
            updatedImages.splice(index, 1);
            return updatedImages;
          });
        } else if (type === 'property') {
          setSelectedLandPropertyImages((prevImages) => {
            const updatedImages = [...prevImages];
            updatedImages.splice(index, 1);
            return updatedImages;
          });
        }
    };      
      

    const handleInputChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    const handleFormSubmit = (event, data) => {
        event.preventDefault();

        setIsLoading(true);

        const formDataWithImages = {
            ...data,
            location: location,
            latitude: latitude,
            longitude: longitude,
            area: area,
            coordinates: JSON.stringify(coordinates),
            ownership_images: selectedOwnershipImages,
            lands_property_images: selectedLandPropertyImages
        };
        
        landRegistration(formDataWithImages)
            .then(responseData => {
                setValidation(responseData); // Set the data in validation
                toast({
                    title: 'Success',
                    description: responseData.message,
                    status: 'success',
                    duration: 2000,
                    isClosable: true,
                });
                // Clear the input fields
                setFormData({
                    name: '',
                    full_address: '',
                });
                setLocation(null);
                setLatitude(null);
                setLongitude(null);
                setArea(0);
                setCoordinates([]);
                setSelectedOwnershipImages([]);
                setSelectedLandPropertyImages([]);
                setIsLoading(false);
            }).catch(error => {
                setValidation(error.response.data);
                toast({
                    title: 'Error',
                    description: error.response.data.message,
                    status: 'error',
                    duration: 2000,
                    isClosable: true,
                });
                setIsLoading(false);
            });
    }

    const formLabelCss = {
        color: '#000',
        fontFamily: 'Poppins',
        fontSize: '18px',
        ml: '10px',
    }

    const inputCss = {
        px: 3,
        py: 6,
        bgColor: '#f3f3f3',
        borderRadius: '50px',
        placeholder: 'John Doe',
        border: 0,
    }

    return (
        <Stack>
            <form onSubmit={(event) => handleFormSubmit(event, formData)}>
                <Stack
                    direction={{base: 'column', lg: 'row'}}
                    alignItems={'center'}
                    justifyContent={'space-between'}
                    gap={4}
                >
                    <Box mb={'20px !important'} w={{base: '100%', lg: '48%'}}>
                        <FormLabel sx={formLabelCss}>Land Name</FormLabel>
                        <Input
                            sx={inputCss}
                            _placeholder={{ fontWeight: '600' }}
                            _focusVisible={{ outline: 'none' }}
                            type='text'
                            name='name'
                            value={formData.name}
                            onChange={handleInputChange} 
                        />
                        {validation && validation.status == 0 && validation.data && validation.data.name && validation.data.name[0] && (
                            <Text color='red'>{validation.data.name[0]}</Text>
                        )}
                    </Box>
                    <Box mb={'20px !important'} w={{base: '100%', lg: '48%'}}>
                        <FormLabel sx={formLabelCss}>Full Address</FormLabel>
                        <Input
                            sx={inputCss}
                            _placeholder={{ fontWeight: '600' }}
                            _focusVisible={{ outline: 'none' }}
                            type='text'
                            name='full_address'
                            value={formData.full_address}
                            onChange={handleInputChange} 
                        />
                        {validation && validation.status == 0 && validation.data && validation.data.full_address && validation.data.full_address[0] && (
                            <Text color='red'>{validation.data.full_address[0]}</Text>
                        )}
                    </Box>
                </Stack>
                <Stack
                    direction={{base: 'column', lg: 'row'}}
                    alignItems={'center'}
                    justifyContent={'space-between'}
                    gap={4}
                >
                    <Box mb={{base: '0px !important', lg: '20px !important'}} w={{base: '100%', lg: '48%'}}>
                        <FormLabel sx={formLabelCss}>Select Pin Point Location</FormLabel>
                    </Box>
                    <Box 
                        mb={'20px !important'}
                        w={{base: '100%', lg: '48%'}}
                        px={3}
                        bgColor={'#f3f3f3'}
                        borderRadius={'50px'}
                        display={'flex'}
                        alignItems={'center'}
                    >
                        <Icon as={BiSearchAlt} fontSize={'26'} />
                        <Input
                            placeholder={'Search'}
                            border={0}
                            _placeholder={{ fontWeight: '600' }}
                            _focusVisible={{ outline: 'none' }}
                        />
                    </Box>
                </Stack>
                <Stack>
                    <Stack>
                        <div ref={mapRef} style={{ width: '100%', height: '400px' }}></div>
                        <Box
                            w={'100%'}
                            mb={'20px'}
                        >
                            <Button
                                onClick={resetMap}
                                fontFamily={"Poppins"}
                                bgGradient='linear(to-b, primaryBlue.100, primaryBlue.200)'
                                _hover={{
                                    bg: 'primaryBlue.100',
                                    color: '#fff'
                                }}
                                w={'full'}
                                borderRadius={8}
                                py={6}
                                mt={3}
                            >Reset Map</Button>
                        </Box>
                    </Stack>
                    <Stack
                        direction={{base: 'column', lg: 'row'}}
                        alignItems={'center'}
                        justifyContent={'space-between'}
                        gap={4}
                    >
                        <Box mb={'20px !important'} w={{base: '100%', lg: '100%'}}>
                            <FormLabel sx={formLabelCss}>GPS Location</FormLabel>
                            <Input
                                sx={inputCss}
                                _placeholder={{ fontWeight: '600' }}
                                _focusVisible={{ outline: 'none' }}
                                type='text'
                            />
                        </Box>
                    </Stack>
                    <Box>
                        <FormLabel sx={formLabelCss}>Proof of OwnerShip</FormLabel>
                        <Box
                            w={'100%'}
                            mb={'40px !important'}
                        >
                            <Input
                                ref={OwnershipImagesRef}
                                display={'none'}
                                type="file"
                                name="ownership_images"
                                accept="image/*"
                                multiple  // Allow multiple image selection
                                onChange={handleOwnershipImagesUpload}
                            />
                            <Box
                                display={'flex'}
                                justifyContent={'center'}
                                alignItems={'center'}
                                flexDir={'column'}
                                bgColor={'#f3f3f3'}
                                border={'4px dashed'}
                                borderRadius={'25px'}
                                borderColor={'primaryBlue.100'}
                                py={'40px'}
                            >
                                <Text
                                    fontFamily={'Poppins'}
                                    color={"primaryBlue.100"}
                                    fontWeight={'600'}
                                    fontSize={{base: '18', lg: '22'}}
                                >Drag and Drop your files here</Text>
                                <Text
                                    fontFamily={'Poppins'}
                                    color={"primaryBlue.100"}
                                    fontWeight={'600'}
                                    fontSize={'18'}
                                    py={2}
                                >or</Text>
                                <Button
                                    onClick={() => OwnershipImagesRef.current.click()}
                                    fontFamily={"Poppins"}
                                    bgGradient='linear(to-b, primaryBlue.100, primaryBlue.200)'
                                    px={14}
                                    _hover={{
                                        bg: 'primaryBlue.100',
                                        color: '#fff'
                                    }}
                                >Browse Files</Button>
                            </Box>
                            {validation && validation.status == 0 && validation.data && validation.data.ownership_images && validation.data.ownership_images[0] && (
                                <Text color='red'>{validation.data.ownership_images[0]}</Text>
                            )}
                        </Box>

                        {selectedOwnershipImages.length > 0 && (
                            <Stack
                                direction={'row'}
                                flexWrap={'wrap'}
                                justifyContent={'space-between'}
                                spacing={0}
                                mt={10}
                            >
                                {selectedOwnershipImages.map((file, index) => (
                                    <LandCustomFile key={index} name={file.name} onRemove={() => removeSelectedImage(index, 'ownership')} />
                                ))}
                            </Stack>
                        )}
                    </Box>
                    <Box>
                        <FormLabel sx={formLabelCss}>Upload Pictures of your Property</FormLabel>
                        <Box
                            w={'100%'}
                            mb={'40px !important'}
                        >
                            <Input
                                ref={LandPropertyImagesRef}
                                display={'none'}
                                type="file"
                                name="lands_property_images"
                                accept="image/*"
                                multiple  // Allow multiple image selection
                                onChange={handleLandPropertyImagesUpload}
                            />
                            <Box
                                display={'flex'}
                                justifyContent={'center'}
                                alignItems={'center'}
                                flexDir={'column'}
                                bgColor={'#f3f3f3'}
                                border={'4px dashed'}
                                borderRadius={'25px'}
                                borderColor={'primaryBlue.100'}
                                py={'40px'}
                            >
                                <Text
                                    fontFamily={'Poppins'}
                                    color={"primaryBlue.100"}
                                    fontWeight={'600'}
                                    fontSize={{base: '18', lg: '22'}}
                                >Drag and Drop your files here</Text>
                                <Text
                                    fontFamily={'Poppins'}
                                    color={"primaryBlue.100"}
                                    fontWeight={'600'}
                                    fontSize={'18'}
                                    py={2}
                                >or</Text>
                                <Button
                                    onClick={() => LandPropertyImagesRef.current.click()}
                                    fontFamily={"Poppins"}
                                    bgGradient='linear(to-b, primaryBlue.100, primaryBlue.200)'
                                    px={14}
                                    _hover={{
                                        bg: 'primaryBlue.100',
                                        color: '#fff'
                                    }}
                                >Browse Files</Button>
                            </Box>
                            {validation && validation.status == 0 && validation.data && validation.data.lands_property_images && validation.data.lands_property_images[0] && (
                                <Text color='red'>{validation.data.lands_property_images[0]}</Text>
                            )}
                        </Box>

                        {selectedLandPropertyImages.length > 0 && (
                            <Stack
                                direction={'row'}
                                flexWrap={'wrap'}
                                justifyContent={'space-between'}
                                spacing={0}
                                mt={10}
                            >
                                {selectedLandPropertyImages.map((file, index) => (
                                    <LandCustomFile key={index} name={file.name} onRemove={() => removeSelectedImage(index, 'property')} />
                                ))}
                            </Stack>
                        )}
                    </Box>
                </Stack>
                <Box
                    w={'100%'}
                    mb={'20px'}
                >
                    <Button
                        type='submit'
                        fontFamily={"Poppins"}
                        bgGradient='linear(to-b, primaryBlue.100, primaryBlue.200)'
                        _hover={{
                            bg: 'primaryBlue.100',
                            color: '#fff'
                        }}
                        w={'full'}
                        borderRadius={8}
                        py={6}
                        isDisabled={isLoading} // Disable the button when loading
                    >
                        {isLoading ? <Spinner thickness='4px' speed='0.65s' emptyColor='primaryBlue.200' color='white' size='lg' /> : "Register Land"}
                    </Button>
                </Box>
            </form>
        </Stack>
    )
}
