import { Stack, Box, FormLabel, Input, Text, Button, Icon, Heading, Image, Link, useToast, Spinner } from '@chakra-ui/react';
import { useState, useRef, useEffect } from 'react';
import { GoogleMap, DrawingManager, Marker } from '@react-google-maps/api';
import { Link as ReactLink, useParams } from 'react-router-dom';
import { editLand, updateLand } from '../../utilities/ApiProvider';

export default function EditLandForm() {

    const { id } = useParams(); // Get the "id" parameter from the URL
    const toast = useToast();
    const [validation, setValidation] = useState(null);
    const [isLoading, setIsLoading] = useState(false); // Initialize loading state
    const [formData, setFormData] = useState({
        name: '',
        full_address: '',
    });  
    
    const handleInputChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    const handleFormSubmit = (event, data) => {
        event.preventDefault();

        setIsLoading(true);

        // Include land_id in the data object
        data.land_id = id;
        
        updateLand(data)
            .then(responseData => {
                setValidation(responseData);
                toast({
                    title: 'Success',
                    description: responseData.message,
                    status: 'success',
                    duration: 2000,
                    isClosable: true,
                });
                setIsLoading(false);
            }).catch(error => {
                setValidation(error.response.data);
                toast({
                    title: 'Error',
                    description: error.response.data.message,
                    status: 'error',
                    duration: 2000,
                    isClosable: true,
                });
                setIsLoading(false);
            });
    }

    useEffect(() => {
        editLand(id)
            .then(responseData => {
                const { name, full_address } = responseData.data; // Destructure name and full_address from responseData
                // Set the form data with the values from responseData
                setFormData({
                    name: name,
                    full_address: full_address,
                });
            }).catch(error => {
                console.log(error);
                if (error.response && error.response.status === 400) {
                    toast({
                        title: 'Error',
                        description: error.response.data.message,
                        status: 'error',
                        duration: 2000,
                        isClosable: true,
                    });
                    // Redirect to the home page
                    window.history.pushState({}, '', '/');
                    // Trigger a popstate event to notify React Router about the URL change
                    const event = new Event('popstate');
                    window.dispatchEvent(event);
                }
            });
    }, []);

    const formLabelCss = {
        color: '#000',
        fontFamily: 'Poppins',
        fontSize: '18px',
        ml: '10px',
    }

    const inputCss = {
        px: 3,
        py: 6,
        bgColor: '#f3f3f3',
        borderRadius: '50px',
        placeholder: 'John Doe',
        border: 0,
    }

    return (
        <Stack>
            <form onSubmit={(event) => handleFormSubmit(event, formData)}>
                <Stack
                    direction={{base: 'column', lg: 'row'}}
                    alignItems={'center'}
                    justifyContent={'space-between'}
                    gap={4}
                >
                    <Box mb={'20px !important'} w={{base: '100%', lg: '48%'}}>
                        <FormLabel sx={formLabelCss}>Land Name</FormLabel>
                        <Input
                            sx={inputCss}
                            _placeholder={{ fontWeight: '600' }}
                            _focusVisible={{ outline: 'none' }}
                            type='text'
                            name='name'
                            value={formData.name}
                            onChange={handleInputChange} 
                        />
                        {validation && validation.status == 0 && validation.data && validation.data.name && validation.data.name[0] && (
                            <Text color='red'>{validation.data.name[0]}</Text>
                        )}
                    </Box>
                    <Box mb={'20px !important'} w={{base: '100%', lg: '48%'}}>
                        <FormLabel sx={formLabelCss}>Full Address</FormLabel>
                        <Input
                            sx={inputCss}
                            _placeholder={{ fontWeight: '600' }}
                            _focusVisible={{ outline: 'none' }}
                            type='text'
                            name='full_address'
                            value={formData.full_address}
                            onChange={handleInputChange} 
                        />
                        {validation && validation.status == 0 && validation.data && validation.data.full_address && validation.data.full_address[0] && (
                            <Text color='red'>{validation.data.full_address[0]}</Text>
                        )}
                    </Box>
                </Stack>
                <Box
                    w={'100%'}
                    mb={'20px'}
                >
                    <Button
                        type='submit'
                        fontFamily={"Poppins"}
                        bgGradient='linear(to-b, primaryBlue.100, primaryBlue.200)'
                        _hover={{
                            bg: 'primaryBlue.100',
                            color: '#fff'
                        }}
                        w={'full'}
                        borderRadius={8}
                        py={6}
                        isDisabled={isLoading} // Disable the button when loading
                    >
                        {isLoading ? <Spinner thickness='4px' speed='0.65s' emptyColor='primaryBlue.200' color='white' size='lg' /> : "Update Land"}
                    </Button>
                </Box>
            </form>
        </Stack>
    )
}
