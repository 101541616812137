import {
  Box,
  Flex,
  Text,
  IconButton,
  Button,
  Stack,
  Collapse,
  Icon,
  Link,
  Popover,
  PopoverTrigger,
  PopoverContent,
  useColorModeValue,
  useBreakpointValue,
  useDisclosure,
  Image,
  Container,
  Input
} from '@chakra-ui/react';
import {
  HamburgerIcon,
  CloseIcon,
  ChevronDownIcon,
  ChevronRightIcon,
} from '@chakra-ui/icons';
import Logo from '../../assets/images/logo.png';
import { BiSearchAlt } from 'react-icons/bi';
import { Link as ReactLink, useLocation, Navigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import LoginUser from '../Customs/LoginUser.js';

export default function WithSubnavigation() {
  const { isOpen, onToggle } = useDisclosure();
  const location = useLocation();
  const [searchQuery, setSearchQuery] = useState('');
  const FRONTEND_BASE_URL = process.env.REACT_APP_FRONTEND_BASE_URL; // Replace with your frontend base URL

  // Function to handle the search action
  const handleSearch = () => {
    if (searchQuery.trim() !== '') {
      // Use the current location to build the new URL
      const queryParams = new URLSearchParams(location.search);
      queryParams.set('name', searchQuery);

      // Use Link component to navigate to the updated URL
      const newUrl = `${FRONTEND_BASE_URL}search?${queryParams.toString()}`;
      // Redirect the user to the new URL
      // Use window.history.pushState to update the URL without page reload
      window.history.pushState({}, '', newUrl);

      // Trigger a popstate event to notify React Router about the URL change
      const event = new Event('popstate');
      window.dispatchEvent(event);
    }
  };

  // Function to handle search input change
  const handleInputChange = (event) => {
    setSearchQuery(event.target.value);
  };


  return (
    <Box>
      <Container maxW={'6xl'}>
        <Flex
          color={useColorModeValue('gray.600', 'white')}
          minH={'60px'}
          py={{ base: 2 }}
          px={{ base: 4 }}
          borderStyle={'solid'}
          borderColor={useColorModeValue('gray.200', 'gray.900')}
          align={'center'}>
          <Flex
            flex={{ base: 1, md: 'auto' }}
            ml={{ base: -2 }}
            display={{ base: 'flex', md: 'none' }}
            right={'40px'}
            top={'60px'}
            zIndex={5}
            >
            <IconButton
              fontSize={'24px'}
              onClick={onToggle}
              icon={
                isOpen ? <CloseIcon w={3} h={3} /> : <HamburgerIcon w={5} h={5} />
              }
              variant={'ghost'}
              aria-label={'Toggle Navigation'}
            />
          </Flex>
          <Flex
            px={'25px'}
            py={'20px'}
            borderRadius={'8px'}
            maxW={{base: '90%', lg: '80%'}}
            marginX={'auto'}
            flex={{ base: 1 }}
            justifyContent={{ base: 'center', md: 'space-between' }}
            position={{base: 'initial', lg: 'fixed'}}
            top={'40px'}
            right={0}
            left={0}
            zIndex={2}
            bgColor={'#ffffffd6'}
            boxShadow={{base: 'none', lg: '0px 0px 25px -14px #000'}}
            w={'100%'}
            backdropFilter={'blur(10px)'}
          >
            <Flex
              gap={16}
            >
              <Link
                as={ReactLink}
                to={'/'}
              >
                <Image
                  src={Logo}
                  alt={"logo"}
                />
              </Link>
              <Box
                px={3}
                bgColor={'#f3f3f3'}
                borderRadius={'50px'}
                display={{ base: 'none', lg: 'flex' }}
                w={'220px'}
                alignItems={'center'}
              >
                <Icon as={BiSearchAlt} fontSize={'26'} />
                <Input
                  placeholder={'Search'}
                  border={0}
                  _placeholder={{ fontWeight: '600' }}
                  _focusVisible={{ outline: 'none' }}
                  value={searchQuery}
                  onChange={handleInputChange}
                  onKeyPress={(e) => {
                    if (e.key === 'Enter') {
                      handleSearch();
                    }
                  }}
                />
                <IconButton
                  aria-label="Search"
                  icon={<ChevronRightIcon />}
                  onClick={handleSearch}
                />
              </Box>
            </Flex>
            <Flex w={'60%'} display={{ base: 'none', md: 'flex' }} ml={10} alignItems={'center'}>
              <DesktopNav />
            </Flex>
          </Flex>

        </Flex>

        <Collapse in={isOpen} animateOpacity>
          <MobileNav />
        </Collapse>
      </Container>
    </Box>
  );
}

const DesktopNav = () => {
  const linkColor = useColorModeValue('gray.600', 'gray.200');
  const linkHoverColor = useColorModeValue('gray.800', 'white');
  const popoverContentBgColor = useColorModeValue('white', 'gray.800');
  const location = useLocation();
  const authenticatedUserData = JSON.parse(localStorage.getItem('authenticatedUserData'));

  return (
    <>
      <Stack direction={'row'} spacing={4} w={'full'}>
        {NAV_ITEMS.map((navItem) => (
          <Box key={navItem.label}>
            <Popover trigger={'hover'} placement={'bottom-start'}>
              <PopoverTrigger>
                <Link
                  as={ReactLink}
                  p={2}
                  fontFamily={'Poppins'}
                  to={navItem.href ?? '#'}
                  fontSize={{base: '12px', '2xl': '16px'}}
                  fontWeight={700}
                  color={'262626'}
                  _hover={{
                    textDecoration: 'none',
                    color: linkHoverColor,
                  }}>
                  {navItem.label}
                </Link>
              </PopoverTrigger>

              {navItem.children && (
                <PopoverContent
                  border={0}
                  boxShadow={'xl'}
                  bg={popoverContentBgColor}
                  p={4}
                  rounded={'xl'}
                  minW={'sm'}>
                  <Stack>
                    {navItem.children.map((child) => (
                      <DesktopSubNav key={child.label} {...child} />
                    ))}
                  </Stack>
                </PopoverContent>
              )}
            </Popover>
          </Box>
        ))}

      </Stack>
      {
        authenticatedUserData ?
          <LoginUser />
          :
          <Stack
            flex={{ base: 1, md: 0 }}
            justify={'flex-end'}
            direction={'row'}
            spacing={6}
            fontFamily={'Poppins'}
          >
            <Button
              as={ReactLink}
              fontSize={'15px'}
              fontWeight={700}
              variant={'link'}
              to={'/sign-in'}
              _hover={{ textDecoration: 'none' }}
            >
              Sign In
            </Button>
            <Button
              as={ReactLink}
              display={{ base: 'none', md: 'inline-flex' }}
              fontSize={'sm'}
              fontWeight={600}
              color={'white'}
              px={8}
              bgGradient='linear(to-b, primaryBlue.100, primaryBlue.200)'
              to={'/sign-up'}
              _hover={{
                bg: 'primaryBlue.100',
              }}>
              Sign Up
            </Button>
          </Stack>
      }
    </>
  );
};

const DesktopSubNav = ({ label, href, subLabel }) => {
  return (
    <Link
      href={href}
      role={'group'}
      display={'block'}
      p={2}
      rounded={'md'}
      _hover={{ bg: useColorModeValue('pink.50', 'gray.900') }}>
      <Stack direction={'row'} align={'center'}>
        <Box>
          <Text
            transition={'all .3s ease'}
            _groupHover={{ color: 'pink.400' }}
            fontWeight={500}>
            {label}
          </Text>
          <Text fontSize={'sm'}>{subLabel}</Text>
        </Box>
        <Flex
          transition={'all .3s ease'}
          transform={'translateX(-10px)'}
          opacity={0}
          _groupHover={{ opacity: '100%', transform: 'translateX(0)' }}
          justify={'flex-end'}
          align={'center'}
          flex={1}>
          <Icon color={'pink.400'} w={5} h={5} as={ChevronRightIcon} />
        </Flex>
      </Stack>
    </Link>
  );
};

const MobileNav = () => {
  return (
    <Stack
      bg={useColorModeValue('white', 'gray.800')}
      p={4}
      display={{ md: 'none' }}>
      {NAV_ITEMS.map((navItem) => (
        <MobileNavItem key={navItem.label} {...navItem} />
      ))}
    </Stack>
  );
};

const MobileNavItem = ({ label, children, href }) => {
  const { isOpen, onToggle } = useDisclosure();

  return (
    <Stack spacing={4} onClick={children && onToggle}>
      <Flex
        py={2}
        as={ReactLink}
        to={href ?? '#'}
        justify={'space-between'}
        align={'center'}
        _hover={{
          textDecoration: 'none',
        }}>
        <Text
          fontWeight={600}
          color={useColorModeValue('gray.600', 'gray.200')}>
          {label}
        </Text>
        {children && (
          <Icon
            as={ChevronDownIcon}
            transition={'all .25s ease-in-out'}
            transform={isOpen ? 'rotate(180deg)' : ''}
            w={6}
            h={6}
          />
        )}
      </Flex>

      <Collapse in={isOpen} animateOpacity style={{ marginTop: '0!important' }}>
        <Stack
          mt={2}
          pl={4}
          borderLeft={1}
          borderStyle={'solid'}
          borderColor={useColorModeValue('gray.200', 'gray.700')}
          align={'start'}>
          {children &&
            children.map((child) => (
              <Link key={child.label} py={2} href={child.href}>
                {child.label}
              </Link>
            ))}
        </Stack>
      </Collapse>
    </Stack>
  );
};

const NAV_ITEMS = [
  {
    label: 'Home',
    href: '/',
  },
  {
    label: 'Privacy Policy',
    href: '/privacy-policy',
  },
  {
    label: 'Terms & Conditions',
    href: '/terms-and-conditions',
  },
];