import axios from "axios";
const BASE_URL = process.env.REACT_APP_BASE_URL;

// Auth API Start Created By MYTECH MAESTRO
export function signUp(data) {
    return axios.post(`${BASE_URL}api/v1/customer/register`, {
        name: data.name,
        email: data.email,
        password: data.password,
        password_confirmation: data.password_confirmation,
        image: data.image,
        verified_by: 'email',
    }).then(response => {
        return response.data;
    }).catch(error => {
        throw error;
    });
}

export function signIn(data) {
    return axios.post(`${BASE_URL}api/v1/customer/login`, {
        email: data.email,
        password: data.password,
    }).then(response => {
        return response.data;
    }).catch(error => {
        throw error;
    });
}

export function verifyOtp(data) {
    const userSignUpData = JSON.parse(localStorage.getItem('userSignUpData'));

    return axios.post(`${BASE_URL}api/v1/customer/verify-otp`, {
        email: userSignUpData?.email,
        otp: data.otp,
    }).then(response => {
        return response.data;
    }).catch(error => {
        throw error;
    });
}

export function resendOTP() {
    const userSignUpData = JSON.parse(localStorage.getItem('userSignUpData'));

    return axios.post(`${BASE_URL}api/v1/customer/resend-otp`, {
        email: userSignUpData?.email,
    }).then(response => {
        return response.data;
    }).catch(error => {
        throw error;
    });
}

export function changePassword(data) {

    const authenticatedUserData = JSON.parse(localStorage.getItem('authenticatedUserData'));
    const headers = {
        Authorization: `${authenticatedUserData?.token}`,
        'Content-Type': 'application/json',
    };

    return axios.post(`${BASE_URL}api/v1/customer/change-password`, {
        old_password: data.old_password,
        password: data.password,
        password_confirmation: data.password_confirmation,
    }, { headers }).then(response => {
        return response.data;
    }).catch(error => {
        throw error;
    });
}

export function forgetPassword(data) {
    return axios.post(`${BASE_URL}api/v1/customer/forgot-password`, {
        email: data.email,
    }).then(response => {
        return response.data;
    }).catch(error => {
        throw error;
    });
}

export function updateProfile(data) {

    const authenticatedUserData = JSON.parse(localStorage.getItem('authenticatedUserData'));
    const headers = {
        Authorization: `${authenticatedUserData?.token}`,
        'Content-Type': 'application/json',
    };

    return axios.post(`${BASE_URL}api/v1/customer/update-profile`, {
        name: data.name,
        password: data.password,
        password_confirmation: data.password_confirmation,
        image: data.image,
    }, { headers }).then(response => {
        return response.data;
    }).catch(error => {
        throw error;
    });
}

export function getOtherUserProfile(user_id) {

    const authenticatedUserData = JSON.parse(localStorage.getItem('authenticatedUserData'));
    const headers = {
        Authorization: `${authenticatedUserData?.token}`,
        'Content-Type': 'application/json',
    };

    if(authenticatedUserData) {
        return axios.get(`${BASE_URL}api/v1/customer/getAuthenticatedOtherUserProfile?user_id=${user_id}`, { headers }).then(response => {
            return response.data;
        }).catch(error => {
            throw error;
        });
    } else {
        return axios.get(`${BASE_URL}api/v1/customer/getOtherUserProfile?user_id=${user_id}`).then(response => {
            return response.data;
        }).catch(error => {
            throw error;
        });
    }
}

export function searchUser(name) {

    return axios.get(`${BASE_URL}api/v1/customer/searchUser?name=${name}`).then(response => {
        return response.data;
    }).catch(error => {
        throw error;
    });
}

export function signOut() {

    const authenticatedUserData = JSON.parse(localStorage.getItem('authenticatedUserData'));
    const headers = {
        Authorization: `${authenticatedUserData?.token}`,
        'Content-Type': 'application/json',
    };

    return axios.get(`${BASE_URL}api/v1/customer/sign-out`, { headers })
        .then(response => {
            return response.data;
        }).catch(error => {
            throw error;
        });
}
// Auth API End Created By MYTECH MAESTRO

// Land API Start Created By MYTECH MAESTRO
export function landRegistration(data) {

    const authenticatedUserData = JSON.parse(localStorage.getItem('authenticatedUserData'));
    const headers = {
        Authorization: `${authenticatedUserData?.token}`,
        'Content-Type': 'application/json',
    };

    return axios.post(`${BASE_URL}api/v1/lands/addLand`, {
        name: data.name,
        full_address: data.full_address,
        location: data.location,
        latitude: data.latitude,
        longitude: data.longitude,
        area: data.area,
        coordinates: data.coordinates,
        ownership_images: data.ownership_images,
        lands_property_images: data.lands_property_images,
    }, { headers }).then(response => {
        return response.data;
    }).catch(error => {
        throw error;
    });
}

export function getMyLands() {

    const authenticatedUserData = JSON.parse(localStorage.getItem('authenticatedUserData'));
    const headers = {
        Authorization: `${authenticatedUserData?.token}`,
        'Content-Type': 'application/json',
    };

    return axios.get(`${BASE_URL}api/v1/lands/getMyLands`, { headers })
        .then(response => {
            return response.data;
        }).catch(error => {
            throw error;
        });
}

export function editLand(land_id) {

    const authenticatedUserData = JSON.parse(localStorage.getItem('authenticatedUserData'));
    const headers = {
        Authorization: `${authenticatedUserData?.token}`,
        'Content-Type': 'application/json',
    };

    return axios.get(`${BASE_URL}api/v1/lands/editLand?land_id=${land_id}`, { headers }).then(response => {
        return response.data;
    }).catch(error => {
        throw error;
    });
}

export function updateLand(data) {

    const authenticatedUserData = JSON.parse(localStorage.getItem('authenticatedUserData'));
    const headers = {
        Authorization: `${authenticatedUserData?.token}`,
        'Content-Type': 'application/json',
    };

    return axios.post(`${BASE_URL}api/v1/lands/updateLand`, {
        land_id: data.land_id,
        name: data.name,
        full_address: data.full_address,
    }, { headers }).then(response => {
        return response.data;
    }).catch(error => {
        throw error;
    });

}

export function deleteLand(land_id) {

    const authenticatedUserData = JSON.parse(localStorage.getItem('authenticatedUserData'));
    const headers = {
        Authorization: `${authenticatedUserData?.token}`,
        'Content-Type': 'application/json',
    };

    return axios.get(`${BASE_URL}api/v1/lands/deleteLand?land_id=${land_id}`, { headers }).then(response => {
        return response.data;
    }).catch(error => {
        throw error;
    });
}

export function searchNft(name) {

    return axios.get(`${BASE_URL}api/v1/nfts/searchNft?name=${name}`).then(response => {
        return response.data;
    }).catch(error => {
        throw error;
    });
}
// Land API End Created By MYTECH MAESTRO

// Environment API Start Created By MYTECH MAESTRO
export function getEnvironments(environment_id = null) {

    const authenticatedUserData = JSON.parse(localStorage.getItem('authenticatedUserData'));
    const headers = {
        Authorization: `${authenticatedUserData?.token}`,
        'Content-Type': 'application/json',
    };

    if(authenticatedUserData) {
        return axios.get(`${BASE_URL}api/v1/environments/getAuthenticatedEnvironments?environment_id=${environment_id}`, { headers })
        .then(response => {
            return response.data;
        }).catch(error => {
            throw error;
        });
    } else {
        return axios.get(`${BASE_URL}api/v1/environments/getEnvironments?environment_id=${environment_id}`)
        .then(response => {
            return response.data;
        }).catch(error => {
            throw error;
        });
    }
}

export function getMyEnvironments() {

    const authenticatedUserData = JSON.parse(localStorage.getItem('authenticatedUserData'));
    const headers = {
        Authorization: `${authenticatedUserData?.token}`,
        'Content-Type': 'application/json',
    };

    return axios.get(`${BASE_URL}api/v1/environments/getMyEnvironments`, { headers })
        .then(response => {
            return response.data;
        }).catch(error => {
            throw error;
        });
}

export function editEnvironment(environment_id) {

    const authenticatedUserData = JSON.parse(localStorage.getItem('authenticatedUserData'));
    const headers = {
        Authorization: `${authenticatedUserData?.token}`,
        'Content-Type': 'application/json',
    };

    return axios.get(`${BASE_URL}api/v1/environments/editEnvironment?environment_id=${environment_id}`, { headers }).then(response => {
        return response.data;
    }).catch(error => {
        throw error;
    });
}

export function updateEnvironment(data) {

    const authenticatedUserData = JSON.parse(localStorage.getItem('authenticatedUserData'));
    const headers = {
        Authorization: `${authenticatedUserData?.token}`,
        'Content-Type': 'application/json',
    };

    return axios.post(`${BASE_URL}api/v1/environments/updateEnvironment`, {
        environment_id: data.environment_id,
        name: data.name,
        description: data.description,
    }, { headers }).then(response => {
        return response.data;
    }).catch(error => {
        throw error;
    });
}

export function deleteEnvironment(environment_id) {

    const authenticatedUserData = JSON.parse(localStorage.getItem('authenticatedUserData'));
    const headers = {
        Authorization: `${authenticatedUserData?.token}`,
        'Content-Type': 'application/json',
    };

    return axios.get(`${BASE_URL}api/v1/environments/deleteEnvironment?environment_id=${environment_id}`, { headers }).then(response => {
        return response.data;
    }).catch(error => {
        throw error;
    });
}

export function searchEnvironment(name) {

    const authenticatedUserData = JSON.parse(localStorage.getItem('authenticatedUserData'));
    const headers = {
        Authorization: `${authenticatedUserData?.token}`,
        'Content-Type': 'application/json',
    };

    if(authenticatedUserData) {
        return axios.get(`${BASE_URL}api/v1/environments/authenticatedSearchEnvironment?name=${name}`, { headers }).then(response => {
            return response.data;
        }).catch(error => {
            throw error;
        });
    } else {
        return axios.get(`${BASE_URL}api/v1/environments/searchEnvironment?name=${name}`).then(response => {
            return response.data;
        }).catch(error => {
            throw error;
        });
    }
}

export function environmentLikeRemove(environment_id) {
    
    const authenticatedUserData = JSON.parse(localStorage.getItem('authenticatedUserData'));
    const headers = {
        Authorization: `${authenticatedUserData?.token}`,
        'Content-Type': 'application/json',
    };

    return axios.post(`${BASE_URL}api/v1/environments/environmentLikeRemove`, {
        environment_id: environment_id,
    }, { headers }).then(response => {
        return response.data;
    }).catch(error => {
        throw error;
    });
}

export function shareEnvironment(data) {

    const authenticatedUserData = JSON.parse(localStorage.getItem('authenticatedUserData'));
    const headers = {
        Authorization: `${authenticatedUserData?.token}`,
        'Content-Type': 'application/json',
    };

    return axios.post(`${BASE_URL}api/v1/environments/shareEnvironment`, {
        environment_id: data.environment_id,
        description: data.description,
    }, { headers }).then(response => {
        return response.data;
    }).catch(error => {
        throw error;
    });
}
// Environment API End Created By MYTECH MAESTRO

// Nfts API Start Created By MYTECH MAESTRO
export function getMyNfts() {

    const authenticatedUserData = JSON.parse(localStorage.getItem('authenticatedUserData'));
    const headers = {
        Authorization: `${authenticatedUserData?.token}`,
        'Content-Type': 'application/json',
    };

    return axios.get(`${BASE_URL}api/v1/nfts/getMyNfts`, { headers })
        .then(response => {
            return response.data;
        }).catch(error => {
            throw error;
        });

}

export function getNfts(nft_id) {
    return axios.get(`${BASE_URL}api/v1/nfts/getNfts`)
        .then(response => {
            return response.data;
        }).catch(error => {
            throw error;
        });
}

export function getCheckout(nft_id) {

    const authenticatedUserData = JSON.parse(localStorage.getItem('authenticatedUserData'));
    const headers = {
        Authorization: `${authenticatedUserData?.token}`,
        'Content-Type': 'application/json',
    };

    return axios.get(`${BASE_URL}api/v1/checkout?nft_id=${nft_id}`, { headers }).then(response => {
        return response.data;
    }).catch(error => {
        throw error;
    });
}
// Nfts API End Created By MYTECH MAESTRO

export const POST = async(url, data = {}, headers = {}) => {
    try {
        const res = await axios.post(
            url, 
            data, 
            {
                headers,
                validateStatus: status => {
                    // console.log(status);
                    return status>=200
                }
            }
        );
        return res.data;
    } catch (error) {
        // console.log(error);
        return error;
    }
}

export const PUT = async(url, data = {}, headers = {}) => {
    try {
        const res = await axios.put(
            url, 
            data, 
            {
                headers,
                validateStatus: status => {
                    // console.log(status);
                    return status>=200
                }
            }
        );
        return res.data;
    } catch (error) {
        // console.log(error);
        return error;
    }
}

export const GET = async(url, headers = {}) => {
    try {
        const res = await axios.get(
            url, 
            {
                headers,
                validateStatus: status => {
                    // console.log(status);
                    return status>=200
                }
            }
        );
        return res.data;
    } catch (error) {
        // console.log(error);
        return error;
    }
}

export const DELETE = async(url, headers = {}) => {
    try {
        const res = await axios.delete(
            url,
            {
                headers,
                validateStatus: status => {
                    // console.log(status);
                    return status>=200
                }
            }
        );
        return res.data;
    } catch (error) {
        // console.log(error);
        return error;
    }
}