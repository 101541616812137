import { Heading, Stack, Link, Box, Text, Image } from "@chakra-ui/react";
import Image1 from '../../assets/images/collection.png'

export default function Collections() {

    return (
        <Stack
            boxShadow={'0px 0px 25px -14px #000'}
            borderRadius={12}
            py={'20px'}
            px={'15px'}
        >
            <Stack
                fontFamily={'Poppins'}
                mb={'20px'}
            >
                <Heading
                    fontSize={'24px'}
                    fontWeight={'700'}
                    fontFamily={'Poppins'}
                >
                    Your Collections</Heading>
            </Stack>
            <Box
                boxShadow={'0px 0px 20px -14px #000'}
                borderRadius={12}
                mb={6}

            >
                <Image src={Image1} alt="loc image" />
                <Box
                    p={4}
                >
                    <Heading fontSize={'22px'} fontFamily={'Poppins'}>Collection 1</Heading>
                    <Text fontSize={'18px'} color={'#8f8f8f'} >Crazy Skullzs, Bored Apes</Text>
                </Box>
            </Box>
        </Stack>
    );
}