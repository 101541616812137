import { Box, Button, Heading, Icon, Image, MenuButton, Stack, Text, Menu, MenuList, MenuItem, Link, useToast, Flex, Popover, PopoverContent, PopoverTrigger, Wrap, WrapItem } from '@chakra-ui/react';
import { BsThreeDots } from 'react-icons/bs';
import { FaThumbsUp, FaShare, FaShareAlt } from "react-icons/fa";
import { Link as ReactLink } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { FacebookShareButton, TwitterShareButton, LinkedinShareButton, WhatsappShareButton, WorkplaceShareButton, EmailShareButton, FacebookIcon, TwitterIcon, LinkedinIcon, WhatsappIcon, WorkplaceIcon, EmailIcon  } from 'react-share';
import { environmentLikeRemove } from "../../utilities/ApiProvider";

export default function EnvironmentFeedBox({ id, sketchfab_uid, name, slug, embedUrl, description, created_at, isLike, environments_likes_count, environments_share_count, user, parent, onDelete }) {

    const authenticatedUserData = JSON.parse(localStorage.getItem('authenticatedUserData'));
    const isUserEnvironment = authenticatedUserData && authenticatedUserData.id === user.id;
    const imageUrl = user ? user.image : null;
    const parentImageUrl = parent?.user ? parent?.user.image : null;
    const BASE_URL = process.env.REACT_APP_BASE_URL; // Replace with your backend base URL
    const FRONTEND_BASE_URL = process.env.REACT_APP_FRONTEND_BASE_URL; // Replace with your frontend base URL
    const profileImage = imageUrl ? `${BASE_URL}${imageUrl}` : null;
    const parentProfileImage = parentImageUrl ? `${BASE_URL}${parentImageUrl}` : null;
    const [isDeleted, setIsDeleted] = useState(false); // Add isDeleted state
    const toast = useToast();
    const [isLiked, setIsLiked] = useState(isLike);
    const [likeCount, setLikeCount] = useState(environments_likes_count);
    const [shareCount, setShareCount] = useState(environments_share_count);
    const [showShareButtons, setShowShareButtons] = useState(false);
    const shareUrl = `${FRONTEND_BASE_URL}environment/${id}`;
    const shareTitle = `Check out ${name}`;

    const handleDeleteEnvironment = () => {
        // Call the onDelete callback from the parent component
        onDelete();
        // Set isDeleted to true to visually cue the deletion
        setIsDeleted(true);

        // Reset isDeleted to false after a certain delay (e.g., 600 ms)
        setTimeout(() => {
            setIsDeleted(false);
        }, 600); // Adjust the delay as needed (600 ms)
    }

    const handleLikeRemove = (environmentId) => {
        environmentLikeRemove(environmentId)
            .then(responseData => {
                console.log(responseData.message);
            }).catch(error => {
                console.log(error.response.data.message);
            });

            if (!authenticatedUserData) {
                // Redirect to the login page
                window.history.pushState({}, '', '/sign-in');
                // Trigger a popstate event to notify React Router about the URL change
                const event = new Event('popstate');
                window.dispatchEvent(event);
                return
            }

            // Here, we are toggling the like status based on the current state
            const isLikedNow = !isLiked;

            // Display different toast messages for "like" and "unlike" actions
            const message = isLikedNow ? "Environment Liked Successfully!" : "Environment Unliked Successfully!";
            const status = isLikedNow ? "success" : "info"; // Use "info" for unliking

            toast({
                title: isLikedNow ? "Like" : "Unlike",
                description: message,
                status,
                duration: 2000,
                isClosable: true,
            });

            // Update the like count based on the action
            setLikeCount(isLikedNow ? parseInt(likeCount, 10) + 1 : parseInt(likeCount, 10) - 1);
            setIsLiked(isLikedNow); // Update the like state
    }

    const handleShareButtonClick = () => {
        setShowShareButtons(!showShareButtons);
    };

    // useEffect to reset isDeleted when the component unmounts
    useEffect(() => {
        return () => {
            setIsDeleted(false);
        };
    }, []);

    return (
        <>
        {parent ? (
            <Stack
                marginBottom={'30px !important'}
                borderRadius={'12px'}
                px={{ base: '10px', lg: '25px' }}
                py={'20px'}
                border={'1px solid #e1e1e1'}
                transition="opacity 0.3s ease-in-out" // Add a CSS transition for opacity
                opacity={isDeleted ? 0 : 1} // Set opacity to 0 when isDeleted is true
            >
                <Stack
                    direction={'row'}
                    alignItems={'flex-start'}
                    justifyContent={'space-between'}
                >
                    <Link
                        as={ReactLink}
                        to={`/profile/${user.id}`}
                        _hover={{
                            textDecoration: 'none'
                        }}
                    >
                        <Box
                            display={'flex'}
                            alignItems={'center'}
                            gap={4}
                            mb={'15px'}
                        >
                            <Image src={profileImage} alt={user?.name} border={'4px solid'} borderColor={'primaryBlue.100'} borderRadius={'100%'} width="52px" height="52px" />
                            <Box>
                                <Heading fontFamily={'Poppins'} fontSize={'24px'} fontWeight={'700'}>{user?.name}</Heading>
                                <Text fontFamily={'Poppins'} fontSize={'14px'}>{created_at}</Text>
                            </Box>
                        </Box>
                    </Link>
                    <>
                        {isUserEnvironment && (
                            <Menu>
                                <Button
                                    as={MenuButton}
                                    bgColor={'transparent'}
                                    _hover={{ bgColor: 'transparent' }}
                                    _focus={{ bgColor: 'transparent' }}
                                >
                                    <Icon as={BsThreeDots} fontSize={28} />
                                </Button>
                                <MenuList>
                                    <Link 
                                        as={ReactLink}
                                        to={`/environment/edit/${id}`}
                                        _hover={{
                                            textDecoration: 'none'
                                        }}
                                    >
                                        <MenuItem>Edit</MenuItem>
                                    </Link>
                                    <MenuItem onClick={handleDeleteEnvironment}>Delete</MenuItem>
                                </MenuList>
                            </Menu>
                        )}
                    </>
                </Stack>
                <Text fontFamily={'Poppins'} fontSize={{ base: '14px', lg: '18px' }} pb={3}>{description}</Text>
                <Stack
                    marginBottom={'30px !important'}
                    borderRadius={'12px'}
                    px={{ base: '10px', lg: '25px' }}
                    py={'20px'}
                    border={'1px solid #e1e1e1'}
                    transition="opacity 0.3s ease-in-out" // Add a CSS transition for opacity
                    opacity="1"
                >
                    <Stack
                        direction={'row'}
                        alignItems={'flex-start'}
                        justifyContent={'space-between'}
                    >
                        <Link
                            as={ReactLink}
                            to={`/profile/${parent.user.id}`}
                            _hover={{
                                textDecoration: 'none'
                            }}
                        >
                            <Box
                                display={'flex'}
                                alignItems={'center'}
                                gap={4}
                                mb={'15px'}
                            >
                                <Image src={parentProfileImage} alt={parent.user?.name} border={'4px solid'} borderColor={'primaryBlue.100'} borderRadius={'100%'} width="52px" height="52px" />
                                <Box>
                                    <Heading fontFamily={'Poppins'} fontSize={'24px'} fontWeight={'700'}>{parent.user?.name}</Heading>
                                    <Text fontFamily={'Poppins'} fontSize={'14px'}>{parent.created_at}</Text>
                                </Box>
                            </Box>
                        </Link>
                    </Stack>
                    <Text fontFamily={'Poppins'} fontSize={{ base: '14px', lg: '18px' }} pb={3}>{parent.description}</Text>
                    {
                        parent.embedUrl &&
                        <div className="sketchfab-embed-wrapper">
                            <iframe
                                frameBorder="0"
                                allowFullScreen
                                allow="autoplay; fullscreen; xr-spatial-tracking"
                                src={parent.embedUrl}
                                style={{ width: '100%', height: '569px' }}
                            >
                            </iframe>
                        </div>
                    }
                </Stack>
                <Flex alignItems="center" marginTop="10px">
                    <Button
                        onClick={() => handleLikeRemove(id)}
                        leftIcon={<Icon as={FaThumbsUp} color={isLiked ? "#1877F2" : "#606770"} />}
                        colorScheme={isLiked ? "#1877F2" : "#606770"}
                        variant="outline"
                        style={{ margin: '5px' }}
                        size='md'
                    >
                        {likeCount}
                    </Button>
                    <Button
                        as={ReactLink}
                        to={`/environment/share/${id}`}
                        leftIcon={<Icon as={FaShare} color="#1877F2" />}
                        colorScheme="blue"
                        variant="outline"
                        style={{ margin: '5px' }}
                        size='md'
                    >
                        {shareCount}
                    </Button>
                    <Popover placement="top" trigger="click" isOpen={showShareButtons}>
                        <PopoverTrigger>
                            <Button
                                onClick={handleShareButtonClick}
                                leftIcon={<Icon as={FaShareAlt} color="#1877F2" />}
                                colorScheme="blue"
                                variant="outline"
                                style={{ margin: '5px' }}
                                size="md"
                            >
                            </Button>
                        </PopoverTrigger>
                        <PopoverContent
                            bg="white"
                            border="1px solid #e1e1e1"
                            p="10px"
                            borderRadius="8px"
                        >
                            <Wrap justify="center">
                                <WrapItem>
                                    <FacebookShareButton url={shareUrl}>
                                        <FacebookIcon size={32} round />
                                    </FacebookShareButton>
                                </WrapItem>
                                <WrapItem>
                                    <TwitterShareButton url={shareUrl} title={shareTitle}>
                                        <TwitterIcon size={32} round />
                                    </TwitterShareButton>
                                </WrapItem>
                                <WrapItem>
                                    <LinkedinShareButton url={shareUrl} title={shareTitle}>
                                        <LinkedinIcon size={32} round />
                                    </LinkedinShareButton>
                                </WrapItem>
                                <WrapItem>
                                    <WhatsappShareButton url={shareUrl} title={shareTitle}>
                                        <WhatsappIcon size={32} round />
                                    </WhatsappShareButton>
                                </WrapItem>
                                <WrapItem>
                                    <WorkplaceShareButton url={shareUrl} title={shareTitle}>
                                        <WorkplaceIcon size={32} round />
                                    </WorkplaceShareButton>
                                </WrapItem>
                                <WrapItem>
                                    <EmailShareButton url={shareUrl} subject={shareTitle}>
                                        <EmailIcon size={32} round />
                                    </EmailShareButton>
                                </WrapItem>
                            </Wrap>
                        </PopoverContent>
                    </Popover>
                </Flex>
            </Stack>
        ) : (
            <Stack
                marginBottom={'30px !important'}
                borderRadius={'12px'}
                px={{ base: '10px', lg: '25px' }}
                py={'20px'}
                border={'1px solid #e1e1e1'}
                transition="opacity 0.3s ease-in-out" // Add a CSS transition for opacity
                opacity={isDeleted ? 0 : 1} // Set opacity to 0 when isDeleted is true
            >
                <Stack
                    direction={'row'}
                    alignItems={'flex-start'}
                    justifyContent={'space-between'}
                >
                    <Link
                        as={ReactLink}
                        to={`/profile/${user.id}`}
                        _hover={{
                            textDecoration: 'none'
                        }}
                    >
                        <Box
                            display={'flex'}
                            alignItems={'center'}
                            gap={4}
                            mb={'15px'}
                        >
                            <Image src={profileImage} alt={user?.name} border={'4px solid'} borderColor={'primaryBlue.100'} borderRadius={'100%'} width="52px" height="52px" />
                            <Box>
                                <Heading fontFamily={'Poppins'} fontSize={'24px'} fontWeight={'700'}>{user?.name}</Heading>
                                <Text fontFamily={'Poppins'} fontSize={'14px'}>{created_at}</Text>
                            </Box>
                        </Box>
                    </Link>
                    <>
                        {isUserEnvironment && (
                            <Menu>
                                <Button
                                    as={MenuButton}
                                    bgColor={'transparent'}
                                    _hover={{ bgColor: 'transparent' }}
                                    _focus={{ bgColor: 'transparent' }}
                                >
                                    <Icon as={BsThreeDots} fontSize={28} />
                                </Button>
                                <MenuList>
                                    <Link 
                                        as={ReactLink}
                                        to={`/environment/edit/${id}`}
                                        _hover={{
                                            textDecoration: 'none'
                                        }}
                                    >
                                        <MenuItem>Edit</MenuItem>
                                    </Link>
                                    <MenuItem onClick={handleDeleteEnvironment}>Delete</MenuItem>
                                </MenuList>
                            </Menu>
                        )}
                    </>
                </Stack>
                <Text fontFamily={'Poppins'} fontSize={{ base: '14px', lg: '18px' }} pb={3}>{description}</Text>
                {
                    embedUrl &&
                    <div className="sketchfab-embed-wrapper">
                        <iframe
                            frameBorder="0"
                            allowFullScreen
                            allow="autoplay; fullscreen; xr-spatial-tracking"
                            src={embedUrl}
                            style={{ width: '100%', height: '569px' }}
                        >
                        </iframe>
                        <Flex alignItems="center" marginTop="10px">
                            <Button
                                onClick={() => handleLikeRemove(id)}
                                leftIcon={<Icon as={FaThumbsUp} color={isLiked ? "#1877F2" : "#606770"} />}
                                colorScheme={isLiked ? "#1877F2" : "#606770"}
                                variant="outline"
                                style={{ margin: '5px' }}
                                size='md'
                            >
                                {likeCount}
                            </Button>
                            <Button
                                as={ReactLink}
                                to={`/environment/share/${id}`}
                                leftIcon={<Icon as={FaShare} color="#1877F2" />}
                                colorScheme="blue"
                                variant="outline"
                                style={{ margin: '5px' }}
                                size='md'
                            >
                                {shareCount}
                            </Button>
                            <Popover placement="top" trigger="click" isOpen={showShareButtons}>
                                <PopoverTrigger>
                                    <Button
                                        onClick={handleShareButtonClick}
                                        leftIcon={<Icon as={FaShareAlt} color="#1877F2" />}
                                        colorScheme="blue"
                                        variant="outline"
                                        style={{ margin: '5px' }}
                                        size="md"
                                    >
                                    </Button>
                                </PopoverTrigger>
                                <PopoverContent
                                    bg="white"
                                    border="1px solid #e1e1e1"
                                    p="10px"
                                    borderRadius="8px"
                                >
                                    <Wrap justify="center">
                                        <WrapItem>
                                            <FacebookShareButton url={shareUrl}>
                                                <FacebookIcon size={32} round />
                                            </FacebookShareButton>
                                        </WrapItem>
                                        <WrapItem>
                                            <TwitterShareButton url={shareUrl} title={shareTitle}>
                                                <TwitterIcon size={32} round />
                                            </TwitterShareButton>
                                        </WrapItem>
                                        <WrapItem>
                                            <LinkedinShareButton url={shareUrl} title={shareTitle}>
                                                <LinkedinIcon size={32} round />
                                            </LinkedinShareButton>
                                        </WrapItem>
                                        <WrapItem>
                                            <WhatsappShareButton url={shareUrl} title={shareTitle}>
                                                <WhatsappIcon size={32} round />
                                            </WhatsappShareButton>
                                        </WrapItem>
                                        <WrapItem>
                                            <WorkplaceShareButton url={shareUrl} title={shareTitle}>
                                                <WorkplaceIcon size={32} round />
                                            </WorkplaceShareButton>
                                        </WrapItem>
                                        <WrapItem>
                                            <EmailShareButton url={shareUrl} subject={shareTitle}>
                                                <EmailIcon size={32} round />
                                            </EmailShareButton>
                                        </WrapItem>
                                    </Wrap>
                                </PopoverContent>
                            </Popover>
                        </Flex>
                    </div>
                }
            </Stack>
        )}
        </>
    )
}
