import { Box, Container, Heading, Image, Stack, Text, Link, Button, Spinner, useToast, color } from "@chakra-ui/react";
import MyLand from "../components/Home/MyLand";
import LandBox from "../components/Home/LandBox";
import NftBox from "../components/Home/NftBox";
import { Link as ReactLink, useParams } from "react-router-dom";
import { useState, useEffect } from 'react';
import EnvironmentFeedBox from "../components/Home/EnvironmentFeedBox";
import NftPic from '../assets/images/shop.png';
import NoRecordFound from '../assets/images/no-record-found.webp';
import { getMyEnvironments, getMyLands, deleteLand, deleteEnvironment, getMyNfts } from "../utilities/ApiProvider";

export default function Profile() {

    const authenticatedUserData = JSON.parse(localStorage.getItem('authenticatedUserData'));
    const imageUrl = authenticatedUserData ? authenticatedUserData.image : null;
    const BASE_URL = process.env.REACT_APP_BASE_URL; // Replace with your base URL
    const profileImage = imageUrl ? `${BASE_URL}${imageUrl}` : null;

    const params = useParams();
    const toast = useToast();
    const [validation, setValidation] = useState(null);
    const [selectedBtn, setSelectedBtn] = useState('Environment');
    const [isLoading, setIsLoading] = useState(true);
    const [myLands, setMyLands] = useState([]);
    const [myEnvironments, setMyEnvironments] = useState([]);
    const [myNfts, setMyNfts] = useState([]);
    const [myNft, setMyNft] = useState([
        NftPic, NftPic, NftPic, NftPic, NftPic, NftPic, NftPic, NftPic, NftPic,
    ]);

    useEffect(() => {
        setIsLoading(true); // Set loading to true when making API requests
        if (selectedBtn === 'Environment') {
            getMyEnvironments()
                .then(responseData => {
                    setMyEnvironments(responseData.data.records);
                    setIsLoading(false);
                }).catch(error => {
                    console.log(error);
                    setIsLoading(false);
                });
        } else if (selectedBtn === 'Lands') {
            getMyLands()
                .then(responseData => {
                    setMyLands(responseData.data.records);
                    setIsLoading(false);
                }).catch(error => {
                    console.log(error);
                    setIsLoading(false);
                });
        } else if (selectedBtn === 'Nfts') {
            getMyNfts()
                .then(responseData => {
                    setMyNfts(responseData.data.records);
                    setIsLoading(false);
                }).catch(error => {
                    console.log(error);
                    setIsLoading(false);
                });
        }
    }, [selectedBtn]);

    // Function to update myLands state when a land is deleted
    const handleDeleteLand = (landId) => {
        // Call the deleteLand API or perform the deletion logic here
        deleteLand(landId)
            .then(responseData => {
                toast({
                    title: 'Success',
                    description: responseData.message,
                    status: 'success',
                    duration: 2000,
                    isClosable: true,
                });

                // Update the myLands state by removing the deleted land
                const updatedLands = myLands.filter(land => land.id !== landId);
                setMyLands(updatedLands);
            }).catch(error => {
                toast({
                    title: 'Error',
                    description: error.response.data.message,
                    status: 'error',
                    duration: 2000,
                    isClosable: true,
                });
            });
    }

    const handleDeleteEnvironment = (environmentId) => {
        // Call the deleteEnvironment API or perform the deletion logic here
        deleteEnvironment(environmentId)
            .then(responseData => {
                toast({
                    title: 'Success',
                    description: responseData.message,
                    status: 'success',
                    duration: 2000,
                    isClosable: true,
                });

                // Update the myEnvironments state by removing the deleted Environment
                const updatedEnvironments = myEnvironments.filter(environment => environment.id !== environmentId);
                setMyEnvironments(updatedEnvironments);
            }).catch(error => {
                toast({
                    title: 'Error',
                    description: error.response.data.message,
                    status: 'error',
                    duration: 2000,
                    isClosable: true,
                });
            });

    }

    const buttonCss = {
        bgColor: 'transparent',
    }

    return (
        <Stack
            pb={12}
        >
            <Container maxW={'8xl'}>
                <Stack
                    boxShadow={'0px 0px 25px -14px #000'}
                    borderRadius={12}
                    px={'15px'}
                    py={'20px'}
                    mb={'40px'}
                    direction={{base: 'column', lg: 'row'}}
                    alignItems={'center'}
                    justifyContent={'space-betwen'}
                    w={'full'}
                >
                    <Box
                        flex={1}
                        display={{base: 'block', lg: 'flex'}}
                        alignItems={'center'}
                        gap={'5'}
                    >
                        <Image src={profileImage} style={{ width: '190px', height: '190px' }} alt={ authenticatedUserData && authenticatedUserData.name } />
                        <Heading fontFamily={'Poppins'}>{ authenticatedUserData && authenticatedUserData.name }</Heading>
                    </Box>
                    <Box>
                        <Button
                            as={ReactLink}
                            fontFamily={"Poppins"}
                            bgGradient='linear(to-b, primaryBlue.100, primaryBlue.200)'
                            color={'#fff'}
                            px={14}
                            to={'/edit-profile'}
                            _hover={{
                                bg: 'primaryBlue.100',
                            }}
                        >Edit Profile</Button>
                    </Box>
                </Stack>
                <Stack
                    flexWrap={{ base: 'warp', lg: 'no-wrap' }}
                    direction={'row'}
                    justifyContent={'space-between'}
                    gap={6}
                >
                    <Stack
                        flex={1}
                        gap={6}
                        display={{ base: 'none', lg: 'none' }}
                    >
                        {/* My Land Component */}
                        <MyLand />
                    </Stack>
                    <Stack
                        flex={2}
                    >
                        {/* Feed Component */}
                        <Stack
                            boxShadow={'0px 0px 25px -14px #000'}
                            borderRadius={12}
                            px={'15px'}
                            py={'20px'}
                        >
                            <Stack
                                direction={'row'}
                                justifyContent={'space-between'}
                                alignItems={'center'}
                                mb={'35px'}
                            >
                                <Heading fontSize={24} fontFamily={'Poppins'}>Feed</Heading>
                                <Box
                                    display={'flex'}
                                    alignItems={'center'}
                                    gap={2}
                                    fontFamily={'Poppins'}
                                    flexWrap={{ base: 'wrap', lg: 'no-wrap' }}
                                    justifyContent={'flex-end'}
                                >
                                    <Button bgGradient={selectedBtn === 'Environment' ? 'linear(to-b, primaryBlue.100, primaryBlue.200)' : 'transparent'} color={selectedBtn === 'Environment' ? '#fff' : '#c2c2c2'} _hover={{ bgColor: 'transparent' }} _focus={{ bgColor: '#573cc2' }} onClick={() => setSelectedBtn('Environment')}>My Environments</Button>
                                    <Button bgGradient={selectedBtn === 'Lands' ? 'linear(to-b, primaryBlue.100, primaryBlue.200)' : 'transparent'} color={selectedBtn === 'Lands' ? '#fff' : '#c2c2c2'} _hover={{ bgColor: 'transparent' }} _focus={{ bgColor: '#573cc2' }} onClick={() => setSelectedBtn('Lands')}>My Lands</Button>
                                    <Button bgGradient={selectedBtn === 'Nfts' ? 'linear(to-b, primaryBlue.100, primaryBlue.200)' : 'transparent'} color={selectedBtn === 'Nfts' ? '#fff' : '#c2c2c2'} _hover={{ bgColor: 'transparent' }} _focus={{ bgColor: '#573cc2' }} onClick={() => setSelectedBtn('Nfts')}>My NFTs</Button>
                                </Box>
                            </Stack>
                            <Stack>
                                {isLoading ? (
                                    <div
                                        style={{
                                        position: "fixed",
                                        top: "50%",
                                        left: "50%",
                                        transform: "translate(-50%, -50%)",
                                        }}
                                    >
                                        <Spinner size="xl" color="blue.500" />
                                    </div>
                                ) : selectedBtn === 'Environment' ? (
                                    myEnvironments && myEnvironments.length > 0 ? (
                                        <>
                                        {myEnvironments.map((v, i) => (
                                            <EnvironmentFeedBox key={i} {...v} onDelete={() => handleDeleteEnvironment(v.id) } />
                                        ))}
                                        </>
                                    ) : (
                                        <Stack
                                            justifyContent="center"
                                            alignItems="center"
                                            w="100%" // Set the width to 100% to center horizontally
                                            h="50vh" // Set the height to 100vh to center vertically
                                        >
                                            <Box textAlign="center" p={4} color="gray.500">
                                                <Image src={NoRecordFound} w="200px" />
                                            </Box>
                                            <Box textAlign="center" p={4} color="gray.500">
                                                <Heading fontSize="xl" mb={2}>
                                                    Oops, No Records Found!
                                                </Heading>
                                            </Box>
                                        </Stack>
                                    )
                                ) : selectedBtn === 'Lands' ? (
                                    myLands && myLands.length > 0 ? (
                                        <>
                                        {myLands.map((v, i) => (
                                            <LandBox key={i} {...v} onDelete={() => handleDeleteLand(v.id) } />
                                        ))}
                                        </>
                                    ) : (
                                        <Stack
                                            justifyContent="center"
                                            alignItems="center"
                                            w="100%" // Set the width to 100% to center horizontally
                                            h="50vh" // Set the height to 100vh to center vertically
                                        >
                                            <Box textAlign="center" p={4} color="gray.500">
                                                <Image src={NoRecordFound} w="200px" />
                                            </Box>
                                            <Box textAlign="center" p={4} color="gray.500">
                                                <Heading fontSize="xl" mb={2}>
                                                    Oops, No Records Found!
                                                </Heading>
                                            </Box>
                                        </Stack>
                                    )
                                ) : selectedBtn === 'Nfts' ? (
                                    <Stack
                                        spacing={0}
                                        direction={'row'}
                                        alignItems={'center'}
                                        gap={6}
                                        flexWrap={'wrap'}
                                    >
                                        { myNfts.length > 0 ? (
                                            myNfts.length &&
                                            myNfts.map((v, i) => <NftBox key={i} {...v} />)
                                        ) : (
                                            <Stack
                                                justifyContent="center"
                                                alignItems="center"
                                                w="100%" // Set the width to 100% to center horizontally
                                                h="50vh" // Set the height to 100vh to center vertically
                                            >
                                                <Box textAlign="center" p={4} color="gray.500">
                                                    <Image src={NoRecordFound} w="200px" />
                                                </Box>
                                                <Box textAlign="center" p={4} color="gray.500">
                                                    <Heading fontSize="xl" mb={2}>
                                                        Oops, No Records Found!
                                                    </Heading>
                                                </Box>
                                            </Stack>
                                        )}
                                    </Stack>
                                ) : null}
                            </Stack>
                        </Stack>
                    </Stack>
                    <Stack
                        flex={1}
                        display={{ base: 'none', lg: 'none' }}
                    >
                        <Stack
                            boxShadow={'0px 0px 25px -14px #000'}
                            gap={6}
                            borderRadius={12}
                            px={'15px'}
                            py={'20px'}>

                            <Heading fontSize={24} fontFamily={'Poppins'}>My NFT</Heading>
                            <Stack
                                direction={'row'}
                                flexWrap={'wrap'}
                                spacing={'0'}
                                gap={1}
                            >
                                {
                                    myNft?.length &&
                                    myNft?.map((v, i) => <Image src={v} key={i} w={'32%'} />)
                                }
                            </Stack>
                        </Stack>
                    </Stack>
                </Stack>
            </Container>
        </Stack>
    );
}