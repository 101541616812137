import { Box, Container, Heading, Image, Stack, Text, Link, FormLabel, Input } from "@chakra-ui/react";
import MyLand from "../components/Home/MyLand";
import Gimage from '../assets/images/g.png'
import { Link as ReactLink } from "react-router-dom";
import FullLoc from '../assets/images/fullLoc.png'
import PropertyPic from '../assets/images/pic.png'
import CustomFile from "../components/Customs/CustomFile";
import { useState } from "react";

export default function SingleLand() {
    const formLabelCss = {
        color: '#000',
        fontFamily: 'Poppins',
        fontSize: '16px',
        ml: '10px',
    }

    const inputCss = {
        px: 3,
        py: 6,
        bgColor: '#f3f3f3',
        borderRadius: '50px',
        placeholder: 'John Doe',
        border: 0,
    }

    const [singleLandInfo, setSingleLandInfo] = useState({
        map: FullLoc,
        address: '45th Street, Manhattan, New York, US',
        proof: ['Land Properties.jpeg', 'Land Properties.jpeg', 'Land Properties.jpeg'],
        images: [PropertyPic, PropertyPic, PropertyPic, PropertyPic]
    });

    return (
        <Stack
            pb={12}
        >
            <Container maxW={'8xl'}>
                <Stack
                    flexWrap={{ base: 'warp', lg: 'no-wrap' }}
                    direction={'row'}
                    justifyContent={'space-between'}
                    gap={6}
                >
                    <Stack
                        flex={1}
                        gap={6}
                        display={{base: 'none', lg: 'initial'}}
                    >
                        {/* My Land Component */}
                        <MyLand />
                    </Stack>
                    <Stack
                        flex={2}
                    >
                        {/* Single Land Component */}
                        <Stack
                            boxShadow={'0px 0px 25px -14px #000'}
                            borderRadius={12}
                            px={'15px'}
                            py={'20px'}
                        >
                            <Stack
                                direction={'row'}
                                justifyContent={'space-between'}
                                alignItems={'center'}
                                mb={'35px'}
                            >
                                <Heading fontSize={24} fontFamily={'Poppins'}>Land 1</Heading>
                                <Text color={"#6249c6"} fontSize={20} fontWeight={'600'}>Owned</Text>
                            </Stack>
                            <Stack>
                                <Image
                                    src={singleLandInfo?.map}
                                    alt="Gallery Land"
                                />
                                <Box my={'20px !important'}>
                                    <FormLabel sx={formLabelCss}>Full Address</FormLabel>
                                    <Input
                                        readOnly
                                        sx={inputCss}
                                        _placeholder={{ fontWeight: '600' }}
                                        _focusVisible={{ outline: 'none' }}
                                        type='text'
                                        value={singleLandInfo?.address}
                                    />
                                </Box>
                                <Box mb={'20px !important'}>
                                    <FormLabel sx={formLabelCss}>Proof of OwnerShip</FormLabel>
                                    <Stack
                                        direction={{base: 'column', lg: 'row'}}
                                        flexWrap={'wrap'}
                                        justifyContent={'space-between'}
                                        spacing={0}
                                    >
                                        {
                                            singleLandInfo?.proof.length &&
                                            singleLandInfo?.proof.map((v, i) => <CustomFile key={i} name={v} />)
                                        }
                                    </Stack>
                                </Box>
                            </Stack>
                            <Stack
                                direction={'row'}
                                justifyContent={'space-between'}
                                alignItems={'center'}
                                mb={'15px !important'}
                            >
                                <Heading fontSize={24} fontFamily={'Poppins'}>Property Pics</Heading>
                                <Link to={"/single-land"} as={ReactLink} color={"#6249c6"} fontSize={20} fontWeight={'600'}>Add New Pics</Link>
                            </Stack>
                            <Stack
                                direction={'row'}
                                justifyContent={'space-between'}
                                alignItems={'center'}
                                mb={'35px'}
                                flexWrap={'wrap'}
                                spacing={0}
                            >
                                {
                                    singleLandInfo?.images.length &&
                                    singleLandInfo?.images.map((v, i) => <Image w={{base: '49%', lg: '24%'}} key={i} src={v} borderRadius={'12px'} />)
                                }
                            </Stack>
                        </Stack>
                    </Stack>
                    <Stack
                        flex={1}
                        display={{base: 'none', lg: 'initial'}}
                        gap={6}
                    >
                        {/* Coins Component */}
                        <Box
                            boxShadow={'0px 0px 25px -14px #000'}
                            borderRadius={12}
                            padding={6}
                            display={'flex'}
                            alignItems={'center'}
                            justifyContent={'space-between'}
                            position={'relative'}
                            mb={'10px'}
                        >
                            <Image src={Gimage} alt="gallery land logo" />
                            <Box>
                                <Text fontFamily={'Poppins'} fontSize={'18px'} fontWeight={'600'}>Coins</Text>
                                <Heading color={'#573cc2'} fontFamily={'Poppins'} fontStyle={'italic'} fontSize={'36px'}>3845550m</Heading>
                                <Link _hover={{ textDecoration: 'none' }} to={'/'} as={ReactLink} fontSize={'18px'} color={'#573cc2'} fontWeight={'600'}>Buy More</Link>
                            </Box>
                        </Box>
                    </Stack>
                </Stack>
            </Container>
        </Stack>
    );
}