import { Container, Heading, Stack, Text, Link, Skeleton } from "@chakra-ui/react";
import { useState } from "react";

export default function TermsCondition() {
    const [isLoading, setIsLoading] = useState(true);

    // Simulate a delay to demonstrate loading
    setTimeout(() => {
        setIsLoading(false);
    }, 600);

    // Function to generate content conditionally
    const generateContent = (text) => {
        if (isLoading) {
            return (
                <Stack>
                    <Skeleton height='15px' />
                    <Skeleton height='15px' />
                    <Skeleton height='15px' />
                    <Skeleton height='15px' />
                </Stack>
            );
        } else {
            return text;
        }
    }

    return (
        <Stack pb={12}>
            <Container maxW={'8xl'}>
                <Stack
                    boxShadow={'0px 0px 25px -14px #000'}
                    borderRadius={12}
                    w={'full'}
                    p={'20px'}
                    spacing={4}
                >
                    <Stack
                        direction={{ base: 'column', lg: 'row' }}
                        justifyContent={'space-between'}
                        alignItems={'center'}
                        mb={'25px'}
                        pb={'25px'}
                        borderBottom={'2px solid #adadad'}
                    >
                        <Heading fontSize={{ base: 'xl', lg: '3xl' }} fontFamily={'Poppins'}>Terms & Conditions</Heading>
                    </Stack>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                PLEASE READ SECTIONS 22 AND 23 BELOW CAREFULLY AS THEY CONTAIN A BINDING ARBITRATION AGREEMENT AND A CLASS ACTION WAIVER, WHICH MAY AFFECT YOUR LEGAL RIGHTS.
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                <strong>1. AGREEMENT TO TERMS</strong>
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                These Terms of Service constitute a legally binding agreement made between you, whether personally or on behalf of an entity ("you") and Gallery Land ("we," "us" or "our"), concerning your access to and use of the Site and App as well as any other media form, media channel, mobile website or mobile application related, linked, or otherwise connected thereto (collectively, the "Service").
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                You agree that by accessing the Service, you have read, understood, and agree to be bound by all of these Terms of Service. If you do not agree with all of these Terms of Service, then you are expressly prohibited from using the Service and you must discontinue use immediately.
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                Supplemental Terms of Service or documents that may be posted on the Site or the App from time to time are hereby expressly incorporated herein by reference. We reserve the right, in our sole discretion, to make changes or modifications to these Terms of Service at any time and for any reason.
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                We will alert you about any changes by updating the "Last updated" date of these Terms of Service, and you waive any right to receive specific notice of each such change except herein or on the Site or App.
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                It is your responsibility to periodically review these Terms of Service to stay informed of updates. You will be subject to and will be deemed to have been made aware of and to have accepted, the changes in any revised Terms of Service by your continued use of the Site and the App after the date such revised Terms of Service are posted.
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                The information provided on the Site and the App is not intended for distribution to or use by any person or entity in any jurisdiction or country where such distribution or use would be contrary to law or regulation or which would subject us to any registration requirement within such jurisdiction or country.
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                Accordingly, those persons who choose to access the Service from other locations do so on their own initiative and are solely responsible for compliance with local laws, if and to the extent local laws are applicable.
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                The Service is intended for users who are at least 18 years of age. All users who are minors in the jurisdiction in which they reside (generally under the age of 18) must have the permission of, and be directly supervised by, their parent or guardian to use the Service. If you are a minor, you must have your parent or guardian read and agree to these Terms of Service prior to you using the Service.
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                <strong>2. INTELLECTUAL PROPERTY RIGHTS</strong>
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                Gallery Land is the "Big Tent" of social networking. Gallery Land's codebase is free and open sourced, derived from the Mastodon project and licensed under the GNU Affero General Public License v. 3.0 (the "AGPLV3"). For more information, please see Legal Docs.
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                Unless otherwise indicated, the functionality, website designs, images, audio, video, text, photographs, and graphics on the Site and the App (collectively, the "Content") and the trademarks, service marks, and logos contained therein (the "Marks") are owned or controlled by us or licensed to us, and are protected by copyright and trademark laws and various other intellectual property rights and unfair competition laws of the United States, foreign jurisdictions, and international conventions.
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                The Content and the Marks are provided on the Site and the App "AS IS" for your information and personal use only. Except as expressly provided in these Terms of Service, no part of the Site or the App and no Content or Marks may be copied, reproduced, aggregated, republished, uploaded, posted, publicly displayed, encoded, translated, transmitted, distributed, sold, licensed, or otherwise exploited for any commercial purpose whatsoever, without our express prior written permission.
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                Provided that you are eligible to use the Service, you are granted a limited license to access and use the Service and to download or print a copy of any portion of the Content to which you have properly gained access solely for your personal, non-commercial use. We reserve all rights not expressly granted to you in and to the Service, the Site, the App, the Content and the Marks.
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                <strong>3. PRIVACY POLICY</strong>
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                We care about data privacy and security. We have developed a Privacy Policy in order to inform you of its practices with respect to the collection, use, disclosure and protection of your information. Please review our Privacy Policy posted on the Site. You can also find it posted on the App. By using the Service, you agree to be bound by our Privacy Policy, which is incorporated into these Terms of Service. Please be advised the Site and the App are hosted in the United States.
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                If you access the Service from the European Union, Asia, or any other region of the world with laws or other requirements governing personal data collection, use, or disclosure that differ from applicable laws in the United States, then through your continued use of the Service, if applicable, you are transferring your data to the United States, and you expressly consent to have your data transferred to and processed in the United States. Please review our Data Privacy Policy (GDPR) as to data collection and processing for all users, including those outside the United States.
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                Further, we do not knowingly accept, request, target or solicit information from children or knowingly market to children. Therefore, in accordance with the U.S. Children's Online Privacy Protection Act, if we receive actual knowledge that anyone under the age of 18 has provided personal information to us without the requisite and verifiable parental consent, we will delete that information from the Site as quickly as is reasonably practical. To contact us: Please email us at{' '}
                                <Link href="mailto:Legal@tmtgcorp.com" color="blue.500" textDecoration="underline">
                                    Legal@tmtgcorp.com
                                </Link>{' '}
                                and provide the username and other details as to any underage information.
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                <strong>4. USER REPRESENTATIONS</strong>
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                By using the Service, you represent and warrant that:
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                (1) all registration information you submit will be true, accurate, current, and complete;
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                (2) you will maintain the accuracy of such information and promptly update such registration information as necessary;
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                (3) you have the legal capacity and you agree to comply with these Terms of Service;
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                (4) you are not under the age of 18. The Service is intended for users who are at least 18 years of age. All users who are minors in the jurisdiction in which they reside (generally under the age of 18) must have the permission of, and be directly supervised by, their parent or guardian to use the Service. If you are a minor, you must have your parent or guardian read and agree to these Terms of Service prior to you using the Service;
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                (5) not a minor in the jurisdiction in which you reside, or if a minor, you have received parental permission to use the Site, if applicable;
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                (6) you will not access the Service through automated or non-human means, whether through a bot, script, or otherwise;
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                (7) you will not use the Service for any illegal or unauthorized purpose;
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                (8) your use of the Service will not violate any applicable law or regulation.
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                If you provide any information that is untrue, inaccurate, not current, or incomplete, we have the right to suspend or terminate your account and refuse any and all current or future use of the Service (or any portion thereof).
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                <strong>5. USER REGISTRATION</strong>
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                You may be required to register for the Service, by providing your name, date of birth, and email address as well as to create a username and password to set up your Profile and your account. You agree to keep your password confidential, if applicable, and will be responsible for all use of your account and password. We reserve the right to remove, reclaim, or change a username you select if we determine, in our sole discretion, (a) that such username is inappropriate, obscene, or otherwise objectionable; (b) that such username is dormant, inactive or unused for an extended period of time exceeding 5 months or more; or (c) that such username is being used for user impersonation or username squatting which is a violation hereunder.
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                <strong>6. COMMUNICATION PREFERENCES AND CONSENT.</strong>
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                From time to time we may send you communications to the email address associated with your account or used to register for the Service. These communications may include, but are not limited to, information, new products and recommendations, special offers, and other account-related or transactional messages. These communications will also include consent request, opt-in and opt-out instructions. Also, when you register for the Service, you may receive SMS or text messages and communications from or related to this Service.
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                <strong>7. PROHIBITED ACTIVITIES</strong>
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                You may not access or use the Service for any purpose other than that for which we make the Service available.
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                As a user of the Service, you agree not to:
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                1.Systematically retrieve data or other content from the Service to create or compile, directly or indirectly, a collection, compilation, database, or directory without written permission from us.
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                2.Make any unauthorized use of the Service, including collecting usernames and/or email addresses of users by electronic or other means for the purpose of sending unsolicited email, or creating user accounts by automated means or under false pretenses.
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                3.Use a buying agent, purchasing agent or other agent to make purchases or to upload content on the Site or the App.
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                4.Use the Service to advertise or offer to sell goods and services in a manner that degrades the user experience, e.g., via off-topic replies to other users’ Contributions, or that appears to be inconsistent with any applicable law, regulation, or professional standard.
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                5.Circumvent, disable, or otherwise interfere with security-related features of the Service, including features that prevent or restrict the use or copying of any Content or enforce limitations on the use of the Service and/or the Content contained therein.
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                6.Engage in unauthorized framing of or linking to the Service.
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                7.Trick, defraud, or mislead us and other users, especially in any attempt to learn sensitive account information such as user passwords.
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                8.Make improper use of our support services or submit false reports of abuse or misconduct.
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                9.Engage in any automated use of the system, such as using scripts to send comments or messages, or using any data mining, robots, or similar data gathering and extraction tools.
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                10.Interfere with, disrupt, or create an undue burden on the Service or the networks or other services connected to the Site or the App.
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                11.Attempt to impersonate another user or person or use the username of another user of the Service.
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                12.Sell or otherwise transfer your profile or account.
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                13.Use any information obtained from the Service in order to harass, abuse, or harm another person.
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                14.Use the Service as part of any effort to compete with us or otherwise use the Service and/or the Content for any revenue-generating endeavor or commercial enterprise.
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                15.Decipher, decompile, disassemble, or reverse engineer any of the software comprising or in any way making up a part of the Service.
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                16.Attempt to bypass any measures of the Service designed to prevent or restrict access to the Service, or any portion of the Service.
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                17.Harass, , intimidate, or threaten any of our employees or agents engaged in providing any portion of the Service to you.
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                18.Delete the copyright or other proprietary rights notice from any Content.
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                19.Copy or adapt the Service's software, including but not limited to Flash, PHP, HTML, JavaScript, or other code.
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                20.Upload or transmit (or attempt to upload or to transmit) viruses, Trojan horses, or other material, that interferes with any party's uninterrupted use and enjoyment of the Service or modifies, impairs, disrupts, alters, or interferes with the use, features, functions, operation, or maintenance of the Service.
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                21.Upload or transmit (or attempt to upload or to transmit) any material that acts as a passive or active information collection or transmission mechanism, including without limitation, clear graphics interchange formats ("gifs"), 1×1 pixels, web bugs, cookies, or other similar devices (sometimes referred to as "spyware" or "passive collection mechanisms" or "pcms").
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                22.Except as may be the result of standard search engine or Internet browser usage, use, launch, develop, or distribute any automated system, including without limitation, any spider, robot, cheat utility, scraper, or offline reader that accesses the Site, or using or launching any unauthorized script or other software.
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                23.Infringe upon any copyright materials owned or controlled by others.
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                24.Use the Service in a manner inconsistent with any applicable laws or regulations.
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                25.Attempts to sell, buy, or solicit other forms of payment in exchange for usernames on the Site or App.
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                26.Post offensive or sexual content on the Site or App, including but not limited to:
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                a. Sexual content or language;
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                b. Content that includes sexual activity, sexual intercourse or any type of sexual act;
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                c. Any content that portrays or suggest explicit sexual acts or sexually suggestive positions or poses;
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                d. Sexually suggestive (explicit or vague) statements, texts or phrases; or
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                e. Content in which sexual acts are requested or offered, including pornography, prostitution, sugar babies, sex trafficking or sexual fetishes.
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                <strong>8. USER GENERATED CONTRIBUTIONS</strong>
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                The Service may invite you to post comments, post opinions, chat, contribute to, or participate in chats or blogs, message boards, online forums, and other functionality, and may provide you with the opportunity to create, submit, post, display, transmit, perform, publish, distribute, or broadcast content and materials to us or on the Service, including but not limited to text, writings, video, audio, photographs, graphics, comments, suggestions, or personal information or other material (collectively, "Contributions").
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                Contributions may be viewable by other users of the Site and through third-party websites. As such, any Contributions you transmit may be treated as non-confidential and non-proprietary. When you create or make available any Contributions, you thereby represent and warrant that:
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                the creation, distribution, transmission, public display, or performance, and the accessing, downloading, or copying of your Contributions do not and will not infringe the proprietary rights, including but not limited to the copyright, patent, trademark, trade secret, or moral rights of any third party.
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                you are the creator and owner of or have the necessary licenses, rights, consents, releases, and permissions to use and to authorize us, the Service, and other users of the Service to use your Contributions in any manner contemplated by the Service and these Terms of Service.
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                you have the written consent, release, and/or permission of each and every identifiable individual person in your Contributions to use the name or likeness of each and every such identifiable individual person to enable inclusion and use of your Contributions in any manner contemplated by the Service and these Terms of Service.
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                your Contributions are not false, inaccurate, or misleading.
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                your Contributions are not unsolicited or unauthorized advertising, promotional materials, pyramid schemes, chain letters, spam, mass mailings, or other forms of solicitation.
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                your Contributions are not obscene, lewd, lascivious, filthy, violent, harassing, libelous, slanderous, or otherwise objectionable.
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                your Contributions do not depict violence, threats of violence or criminal activity.
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                your Contributions do not advocate or incite, encourage, or threaten physical harm against another.
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                your Contributions do not violate any applicable law, regulation, or rule.
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                your Contributions do not violate the privacy or publicity rights of any third party.
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                your Contributions do not contain any material that solicits personal information from anyone under the age of 18 or exploits people under the age of 18 in a sexual or violent manner.
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                your Contributions do not violate any federal or state law concerning child pornography, or otherwise intended to protect the health or well-being of minors;
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                Any use of the Service in violation of the foregoing violates these Terms of Service and may result in, among other things, termination or suspension of your rights to use the Service and removal or deletion of your Contributions.
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                <strong>9. CONTRIBUTION LICENSE</strong>
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                By posting your Contributions to any part of the Service [or making Contributions accessible to the Service by linking your account from the Service to any of your social networking accounts], you automatically grant, and you represent and warrant that you have the right to grant, to us an unrestricted, unlimited, irrevocable, perpetual, non-exclusive, transferable, royalty-free, fully-paid, worldwide right, and license to host, use, copy, reproduce, disclose, sell, resell, publish, broadcast, retitle, archive, store, cache, publicly perform, publicly display, reformat, translate, transmit, excerpt (in whole or in part), and distribute such Contributions (including, without limitation, your image and voice) for any purpose, commercial, advertising, or otherwise, and to prepare derivative works of, or incorporate into other works, such Contributions, and grant and authorize sublicenses of the foregoing. The use and distribution may occur in any media formats and through any media channels.
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                This license will apply to any form, media, or technology now known or hereafter developed, and includes our use of your name, company name, and franchise name, as applicable, and any of the trademarks, service marks, trade names, logos, and personal and commercial images you provide.
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                We do not assert any ownership over your Contributions. You retain full ownership of all of your Contributions and any intellectual property rights or other proprietary rights associated with your Contributions. We are not liable for any statements or representations in your Contributions provided by you in any area on the Service.
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                You are solely responsible for your Contributions to the Service and you expressly agree to exonerate us from any and all responsibility and to refrain from any legal action against us regarding your Contributions. We do not support, endorse or guarantee the accuracy, truthfulness or reliability of any content or Contributions.
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                We have the right, in our sole and absolute discretion, (1) to edit, redact, or otherwise change any Contributions; (2) to re-categorize any Contributions to place them in more appropriate locations on the Site; and (3) to pre-screen or delete any Contributions at any time and for any reason, without notice. We have no obligation to monitor your Contributions.
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                <strong>10. GUIDELINES FOR REVIEWS/RATINGS OR COMMENTS</strong>
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                We may provide you areas on the Service to leave reviews and ratings, post comments or replies or to otherwise communicate with other users on the Service ("Interactive Content"). When posting an Interactive Content, please use care and caution when posting or providing Interactive Content to other users. Additionally, you must comply with the following criteria:
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                (1) you should have firsthand experience with the person/entity being reviewed or Interactive Content being posted;
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                (2) your Interactive Content should not contain profanity, or abusive or racist, language;
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                (3) your Interactive Content should not contain discriminatory references based on religion, race, gender, national origin, age, marital status, sexual orientation, or disability;
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                (4) your Interactive Content should not contain references to any illegal activity;
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                (5) you should not be affiliated with competitors if posting negative reviews or negative Interactive Content;
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                (6) you should not make any conclusions as to the legality of conduct in your Interactive Content;
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                (7) you may not post any false, unlawful, threatening, defamatory, harassing or misleading statements;
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                (8) you may not post any indecent, , pornographic, profane, or other unlawful statements;
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                (9) you may not organize a campaign encouraging others to post Interactive Content or reviews, whether positive or negative.
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                We may accept, reject, or remove Interactive Content in our sole discretion. We have absolutely no obligation to screen Interactive Content or to delete Interactive, even if anyone considers reviews objectionable or inaccurate. Interactive Content is not endorsed or supported by us, and do not necessarily represent our opinions or the views of any of our affiliates or partners.
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                We do not assume liability for any Interactive Content or for any claims, liabilities, or losses resulting from any Interactive Content. By posting Interactive Content, you hereby grant to us a perpetual, non-exclusive, worldwide, royalty-free, fully-paid, assignable, and sublicensable right and license to reproduce, modify, translate, transmit by any means, display, perform, and/or distribute all content relating to Interactive Content.
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                <strong>11. MOBILE APPLICATION LICENSE</strong>
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                Use License
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                If you access the Service via a mobile application, then we grant you a revocable, non-exclusive, non-transferable, limited right to install and use the mobile application on wireless electronic devices owned or controlled by you, and to access and use the mobile application on such devices strictly in accordance with the Terms of Service of this mobile application license contained in these Terms of Service.
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                You shall not:
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                (1) decompile, reverse engineer, disassemble, attempt to derive the source code of, or decrypt the application;
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                (2) violate any applicable laws, rules, or regulations in connection with your access or use of the application;
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                (3) remove, alter, or obscure any proprietary notice (including any notice of copyright or trademark) posted by us or any licensors;
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                (4) use the application for any revenue generating endeavor, commercial enterprise, or other purpose for which it is not designed or intended;
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                (5) make the application available over a network or other environment permitting access or use by multiple devices or users at the same time;
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                (6) use the application for creating a product, service, or software that is, directly or indirectly, competitive with or in any way a substitute for the application;
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                (7) use the application to send automated queries to any website or to send any unsolicited commercial e-mail;
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                (9) use any proprietary information or any other intellectual property in the design, development, manufacture, licensing, or distribution of any applications, accessories, or devices for use with the application, without appropriate consent or license.
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                Apple and Android Devices
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                The following terms apply when you use a mobile application obtained from either the Apple Store or Google Play, if applicable, (each an "App Distributor") to access the Service:
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                (1) the license granted to you for our mobile application is limited to a non-transferable license to use the application on a device that utilizes the Apple iOS or Android operating systems, as applicable, and in accordance with the usage rules set forth in the applicable App Distributor's Terms of Service;
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                (2) we are responsible for providing any maintenance and support services with respect to the mobile application as specified in the Terms of Service of this mobile application license contained in these Terms of Service or as otherwise required under applicable law, and you acknowledge that each App Distributor has no obligation whatsoever to furnish any maintenance and support services with respect to the mobile application;
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                (3) in the event of any failure of the mobile application to conform to any applicable warranty, you may notify the applicable App Distributor, and the App Distributor, in accordance with its terms and policies, may refund the purchase price, if any, paid for the mobile application, and to the maximum extent permitted by applicable law, the App Distributor will have no other warranty obligation whatsoever with respect to the mobile application;
                            </>
                        )}
                    </Text>

                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                (4) you represent and warrant that (i) you are not located in a country that is subject to a U.S. government embargo, or that has been designated by the U.S. government as a "terrorist supporting" country and (ii) you are not listed on any U.S. government list of prohibited or restricted parties;
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                (5) you must comply with applicable third-party terms of agreement when using the mobile application, e.g., if you have a VoIP application, then you must not be in violation of their wireless data service agreement when using the mobile application;
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                (6) you acknowledge and agree that the App Distributors are third-party beneficiaries of the Terms of Service in this mobile application license contained in these Terms of Service, and that each App Distributor will have the right (and will be deemed to have accepted the right) to enforce the Terms of Service in this mobile application license contained in these Terms of Service against you as a third-party beneficiary thereof.
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                <strong>12. SOCIAL MEDIA</strong>
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                As part of the functionality of the Service, you may link your account with online accounts you have with third-party service providers (each such account, a "Third-Party Account") by either: (1) providing your Third-Party Account login information through the Service; or (2) allowing us to access your Third-Party Account, as is permitted under the applicable Terms of Service that govern your use of each Third-Party Account.
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                You represent and warrant that you are entitled to disclose your Third-Party Account login information to us and/or grant us access to your Third-Party Account, without breach by you of any of the Terms of Service that govern your use of the applicable Third-Party Account, and without obligating us to pay any fees or making us subject to any usage limitations imposed by the third-party service provider of the Third-Party Account.
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                By granting us access to any Third-Party Accounts, you understand that (1) we may access, make available, and store (if applicable) any content that you have provided to and stored in your Third-Party Account (the "Social Network Content") so that it is available on and through the Service via your account, including without limitation any friend lists and (2) we may submit to and receive from your Third-Party Account additional information to the extent you are notified when you link your account with the Third-Party Account.
                            </>
                        )}
                    </Text>

                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                Depending on the Third-Party Accounts you choose and subject to the privacy settings that you have set in such Third-Party Accounts, personally identifiable information that you post to your Third-Party Accounts may be available on and through your account on the Service.
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                Please note that if a Third-Party Account or associated service becomes unavailable or our access to such Third-Party Account is terminated by the third-party service provider, then Social Network Content may no longer be available on and through the Service. You will have the ability to disable the connection between your account on the Service and your Third-Party Accounts at any time.
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                PLEASE NOTE THAT YOUR RELATIONSHIP WITH THE THIRD-PARTY SERVICE PROVIDERS ASSOCIATED WITH YOUR THIRD-PARTY ACCOUNTS IS GOVERNED SOLELY BY YOUR AGREEMENT(S) WITH SUCH THIRD-PARTY SERVICE PROVIDERS.
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                We make no effort to review any Social Network Content for any purpose, including but not limited to, for accuracy, legality, or non-infringement, and we are not responsible for any Social Network Content.
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                You acknowledge and agree that we may access your email address book associated with a Third-Party Account and your contacts list stored on your mobile device or tablet computer solely for purposes of identifying and informing you of those contacts who have also registered to use the Service.
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                You can deactivate the connection between the Service and your Third-Party Account by contacting us using the contact information below or through your account settings (if applicable). We will delete any information stored on our servers that was obtained through such Third-Party Account, except the username and profile picture that become associated with your account.
                            </>
                        )}
                    </Text>

                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                <strong>13. SUBMISSIONS</strong>
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                You acknowledge and agree that any questions, comments, suggestions, ideas, feedback, or other information regarding the Service ("Submissions") provided by you to us are non-confidential and shall become our sole property. We shall own exclusive rights, including all intellectual property rights, and shall be entitled to the unrestricted use and dissemination of these Submissions for any lawful purpose, commercial or otherwise, without acknowledgment or compensation to you.
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                You hereby waive all moral rights to any such Submissions, and you hereby warrant that any such Submissions are original with you or that you have the right to submit such Submissions. You agree there shall be no recourse against us for any alleged or actual infringement or misappropriation of any proprietary right in your Submissions.
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                <strong>14. THIRD-PARTY WEBSITES AND CONTENT</strong>
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                The Service may contain (or you may be sent via the Service) links to other websites ("Third-Party Websites") as well as articles, photographs, text, graphics, pictures, designs, music, sound, video, information, applications, software, and other content or items belonging to or originating from third parties ("Third-Party Content").
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                Such Third-Party Websites and Third-Party Content are not investigated, monitored, or checked for accuracy, appropriateness, or completeness by us, and we are not responsible for any Third-Party Websites accessed through the Service or any Third-Party Content posted on, available through, or installed from the Service, including the content, accuracy, offensiveness, opinions, reliability, privacy practices, or other policies of or contained in the Third-Party Websites or the Third-Party Content.
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                Inclusion of, linking to, or permitting the use or installation of any Third-Party Websites or any Third-Party Content does not imply approval or endorsement thereof by us. If you decide to leave the Service and access the Third-Party Websites or to use or install any Third-Party Content, you do so at your own risk, and you should be aware these Terms of Service no longer govern.
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                You should review the applicable terms and policies, including privacy and data gathering practices, of any website to which you navigate from the Service or relating to any applications you use or install from the Service. Any purchases you make through Third-Party Websites will be through other websites and from other companies, and we take no responsibility whatsoever in relation to such purchases which are exclusively between you and the applicable third party.
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                You agree and acknowledge that we do not endorse the products or services offered on Third-Party Websites and you shall hold us harmless from any harm caused by your purchase of such products or services. Additionally, you shall hold us harmless from any losses sustained by you or harm caused to you relating to or resulting in any way from any Third-Party Content or any contact with Third-Party Websites.
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                <strong>15. ADVERTISERS</strong>
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                From time to time, we may allow advertisers to display their advertisements and other information in certain areas of the Service, such as sidebar advertisements or banner advertisements. If you are an advertiser, you shall take full responsibility for any advertisements you place on the Service and any services provided on the Service or products sold through those advertisements.
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                Further, as an advertiser, you warrant and represent that you possess all rights and authority to place advertisements on the Service, including, but not limited to, intellectual property rights, publicity rights, and contractual rights.
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                As an advertiser, you agree that such advertisements are subject to our Digital Millennium Copyright Act ("DMCA") Notice and Policy provisions as described below, and you understand and agree there will be no refund or other compensation for DMCA takedown-related issues. We simply provide the space to place such advertisements, and we have no other relationship with advertisers.
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                <strong>16. MONITORING AND RESTRICTIONS</strong>
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                We reserve the right, but not the obligation, to:
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                (1) monitor the Service for violations of these Terms of Service;
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                (2) take appropriate legal action against anyone who, in our sole discretion, violates the law or these Terms of Service, including without limitation, reporting such user to law enforcement authorities;
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                (3) in our sole discretion and without limitation, refuse, restrict access to, limit the availability of, or disable (to the extent technologically feasible) any of your Contributions or any portion thereof;
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                (4) in our sole discretion and without limitation, notice, or liability, to remove from the Service or otherwise disable all files and content that are excessive in size or are in any way burdensome to our systems;
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                (5) otherwise manage the Service in a manner designed to protect our rights and property and to facilitate the proper functioning of the Service.
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                <strong>17. DIGITAL MILLENNIUM COPYRIGHT ACT (DMCA) NOTICE AND POLICY</strong>
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                A. Notifications
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                We respect the intellectual property rights of others. If you believe that any material available on or through the Service infringes upon any copyright you own or control, please immediately notify our Designated Copyright Agent using the contact information provided below (a "Notification").
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                A copy of your Notification will be sent to the person who posted or stored the material addressed in the Notification. Please be advised that pursuant to federal law you may be held liable for damages if you make material misrepresentations in a Notification. Thus, if you are not sure that material located on or linked to by the Service infringes your copyright, you should consider first contacting an attorney.
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                All Notifications should meet the requirements of DMCA 17 U.S.C. § 512(c)(3) and include the following information:
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                (1) A physical or electronic signature of a person authorized to act on behalf of the owner of an exclusive right that is allegedly infringed;
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                (2) identification of the copyrighted work claimed to have been infringed, or, if multiple copyrighted works on the Service are covered by the Notification, a representative list of such works on the Service;
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                (3) identification of the material that is claimed to be infringing or to be the subject of infringing activity and that is to be removed or access to which is to be disabled, and information reasonably sufficient to permit us to locate the material;
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                (4) information reasonably sufficient to permit us to contact the complaining party, such as an address, telephone number, and, if available, an email address at which the complaining party may be contacted;
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                (5) a statement that the complaining party has a good faith belief that use of the material in the manner complained of is not authorized by the copyright owner, its agent, or the law;
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                (6) a statement that the information in the notification is accurate, and under penalty of perjury, that the complaining party is authorized to act on behalf of the owner of an exclusive right that is allegedly infringed upon.
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                B. Counter Notification
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                If you believe your own copyrighted material has been removed from the Site as a result of a mistake or misidentification, you may submit a written counter notification to [us/our Designated Copyright Agent] using the contact information provided below (a "Counter Notification").
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                To be an effective Counter Notification under the DMCA, your Counter Notification must include substantially the following:
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                (1) identification of the material that has been removed or disabled and the location at which the material appeared before it was removed or disabled;
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                (2) a statement that you consent to the jurisdiction of the Federal District Court in which your address is located, or if your address is outside the United States, for any judicial district in which we are located;
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                (3) a statement that you will accept service of process from the party that filed the Notification or the party\'s agent;
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                (4) your name, address, and telephone number;
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                (5) a statement under penalty of perjury that you have a good faith belief that the material in question was removed or disabled as a result of a mistake or misidentification of the material to be removed or disabled;
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                (6) your physical or electronic signature.
                            </>
                        )}
                    </Text><Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                If you send us a valid, written Counter Notification meeting the requirements described above, we will restore your removed or disabled material, unless we first receive notice from the party filing the Notification informing us that such party has filed a court action to restrain you from engaging in infringing activity related to the material in question.
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                Please note that if you materially misrepresent that the disabled or removed content was removed by mistake or misidentification, you may be liable for damages, including costs and attorney\'s fees. Filing a false Counter Notification constitutes perjury.
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                Attn: Copyright Agent
                            </>
                        )}
                    </Text><Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                1100 South Ocean Blvd.
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                Palm Beach, Florida 33480
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                Email: <Link href="mailto:legal@tmtgcorp.com" color="blue.500" textDecoration="underline">legal@tmtgcorp.com</Link>
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                <strong>18. COPYRIGHT INFRINGEMENTS</strong>
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                We respect the intellectual property rights of others. If you believe that any material available on or through the Service infringes upon any copyright you own or control, please immediately notify us using the contact information provided below (a "Notification"). A copy of your Notification will be sent to the person who posted or stored the material addressed in the Notification.
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                Please be advised that pursuant to federal law you may be held liable for damages if you make material misrepresentations in a Notification. Thus, if you are not sure that material located on or linked to by the Service infringes your copyright, you should consider first contacting an attorney.
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                <strong>19. TERM AND TERMINATION</strong>
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                These Terms of Service shall remain in full force and effect while you use the Service. WITHOUT LIMITING ANY OTHER PROVISION OF THESE TERMS OF SERVICE, WE RESERVE THE RIGHT TO, IN OUR SOLE DISCRETION AND WITHOUT NOTICE OR LIABILITY, DENY ACCESS TO AND USE OF THE SERVICE (INCLUDING BLOCKING CERTAIN IP ADDRESSES), TO ANY PERSON FOR ANY REASON OR FOR NO REASON, INCLUDING WITHOUT LIMITATION FOR BREACH OF ANY REPRESENTATION, WARRANTY, OR COVENANT CONTAINED IN THESE TERMS OF SERVICE OR OF ANY APPLICABLE LAW OR REGULATION. WE MAY TERMINATE YOUR USE OR PARTICIPATION IN THE SERVICE OR DELETE [YOUR ACCOUNT AND] ANY CONTENT OR INFORMATION THAT YOU POSTED AT ANY TIME, WITHOUT WARNING, IN OUR SOLE DISCRETION.
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                If we terminate or suspend your account for any reason, you are prohibited from registering and creating a new account under your name, a fake or borrowed name, or the name of any third party, even if you may be acting on behalf of the third party.
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                In addition to terminating or suspending your account, we reserve the right to take appropriate legal action, including without limitation pursuing civil, criminal, and injunctive redress.
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                <strong>20. MODIFICATIONS AND INTERRUPTIONS</strong>
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                We reserve the right to change, modify, or remove the contents of the Service at any time or for any reason at our sole discretion without notice. However, we have no obligation to update any information on our Service. We also reserve the right to modify or discontinue all or part of the Service without notice at any time.
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                We will not be liable to you or any third party for any modification, price change, suspension, or discontinuance of the Service.
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                We cannot guarantee the Service will be available at all times. We may experience hardware, software, or other problems or need to perform maintenance related to the Service, resulting in interruptions, delays, or errors.
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                We reserve the right to change, revise, update, suspend, discontinue, or otherwise modify the Service at any time or for any reason without notice to you. You agree that we have no liability whatsoever for any loss, damage, or inconvenience caused by your inability to access or use the Site during any downtime or discontinuance of the Service.
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                Nothing in these Terms of Service will be construed to obligate us to maintain and support the Service or to supply any corrections, updates, or releases in connection therewith.
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                <strong>21. GOVERNING LAW</strong>
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                These Terms of Service and your use of the Service are governed by and construed in accordance with the laws of the State of Florida applicable to agreements made and to be entirely performed within the State of Florida, without regard to its conflict of law principles.
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                <strong>22. DISPUTE RESOLUTION</strong>
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                ANY DISPUTE, CONTROVERSY OR CLAIM YOU HAVE ARISING OUT OF OR RELATED TO THESE TERMS OF SERVICE, A BREACH THEREOF OR YOUR USE OF THE WEBSITE OR THE APP SHALL BE EXCLUSIVELY SUBMITTED AND DECIDED BY BINDING ARBITRATION ADMINISTERED BY THE AMERICAN ARBITRATION ASSOCIATION ("AAA"). ANY LEGAL ACTION OF WHATEVER NATURE BROUGHT BY US AGAINST YOU MAY BE COMMENCED OR PROSECUTED IN THE STATE AND FEDERAL COURTS LOCATED IN BROWARD COUNTY, FLORIDA, AND YOU HEREBY CONSENT TO SAME, AND WAIVE ALL DEFENSES OF LACK OF PERSONAL JURISDICTION AND FORUM NON CONVENIENS WITH RESPECT TO VENUE AND JURISDICTION IN SUCH STATE AND FEDERAL COURTS.
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                <strong>23. WAIVER OF CLASS ACTION</strong>
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                NOTWITHSTANDING ANYTHING CONTAINED HEREIN, YOU AGREE TO ABSOLUTELY AND UNCONDITIONALLY WAIVE ANY AND ALL RIGHTS TO PARTICIPATE IN OR TO BE INCLUDED IN ANY CLASS ACTION LAWSUITS OR INCLUSION IN ANY MULTI-PARTY ACTIONS OR SUITS AGAINST US, ANY OF OUR AFFILIATES, SPONSORS, SUBSIDIARIES, VENDORS, EMPLOYEES, AGENTS OR ANY OTHER PERSON OR ENTITY ASSOCIATED THEREWITH.
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                <strong>24. CORRECTIONS</strong>
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                There may be information on the Service that contains typographical errors, inaccuracies, or omissions that may relate to the Service, including descriptions, pricing, availability, and various other information. We reserve the right to correct any errors, inaccuracies, or omissions and to change or update the information on the Service at any time, without prior notice.
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                <strong>25. DISCLAIMER</strong>
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                THE SERVICE IS PROVIDED ON AN AS-IS AND AS-AVAILABLE BASIS. YOU AGREE THAT YOUR USE OF THE SERVICE AND ITS CONTENT WILL BE AT YOUR SOLE RISK. TO THE FULLEST EXTENT PERMITTED BY LAW, WE DISCLAIM ALL WARRANTIES, EXPRESS OR IMPLIED, IN CONNECTION WITH THE SERVICE AND YOUR USE THEREOF, INCLUDING, WITHOUT LIMITATION, THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND NON-INFRINGEMENT. WE MAKE NO WARRANTIES OR REPRESENTATIONS ABOUT THE ACCURACY OR COMPLETENESS OF THE SERVICE'S CONTENT OR THE CONTENT OF ANY WEBSITES LINKED TO THE SERVICE AND WE WILL ASSUME NO LIABILITY OR RESPONSIBILITY FOR ANY (1) ERRORS, MISTAKES, OR INACCURACIES OF CONTENT AND MATERIALS, (2) PERSONAL INJURY OR PROPERTY DAMAGE, OF ANY NATURE WHATSOEVER, RESULTING FROM YOUR ACCESS TO AND USE OF THE SERVICE, (3) ANY UNAUTHORIZED ACCESS TO OR USE OF OUR SECURE SERVERS AND/OR ANY AND ALL PERSONAL INFORMATION AND/OR FINANCIAL INFORMATION STORED THEREIN, (4) ANY INTERRUPTION OR CESSATION OF TRANSMISSION TO OR FROM THE SERVICE, (5) ANY BUGS, VIRUSES, TROJAN HORSES, OR THE LIKE WHICH MAY BE TRANSMITTED TO OR THROUGH THE SERVICE BY ANY THIRD PARTY, AND/OR (6) ANY ERRORS OR OMISSIONS IN ANY CONTENT AND MATERIALS OR FOR ANY LOSS OR DAMAGE OF ANY KIND INCURRED AS A RESULT OF THE USE OF ANY CONTENT POSTED, TRANSMITTED, OR OTHERWISE MADE AVAILABLE VIA THE SERVICE. WE DO NOT WARRANT, ENDORSE, GUARANTEE, OR ASSUME RESPONSIBILITY FOR ANY PRODUCT OR SERVICE ADVERTISED OR OFFERED BY A THIRD PARTY THROUGH THE SERVICE, ANY HYPERLINKED WEBSITE, OR ANY WEBSITE OR MOBILE APPLICATION FEATURED IN ANY BANNER OR OTHER ADVERTISING, AND WE WILL NOT BE A PARTY TO OR IN ANY WAY BE RESPONSIBLE FOR MONITORING ANY TRANSACTION BETWEEN YOU AND ANY THIRD-PARTY PROVIDERS OF PRODUCTS OR SERVICES.
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                AS WITH THE PURCHASE OF A PRODUCT OR SERVICE THROUGH ANY MEDIUM OR IN ANY ENVIRONMENT, YOU SHOULD USE YOUR BEST JUDGMENT AND EXERCISE CAUTION WHERE APPROPRIATE.
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                <strong>26. LIMITATIONS OF LIABILITY</strong>
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                IN NO EVENT WILL WE OR OUR DIRECTORS, EMPLOYEES, OR AGENTS BE LIABLE TO YOU OR ANY THIRD PARTY FOR ANY DIRECT, INDIRECT, CONSEQUENTIAL, EXEMPLARY, INCIDENTAL, SPECIAL, OR PUNITIVE DAMAGES, INCLUDING LOST PROFIT, LOST REVENUE, LOSS OF DATA, OR OTHER DAMAGES ARISING FROM YOUR USE OF THE SERVICE, EVEN IF WE HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                NOTWITHSTANDING ANYTHING TO THE CONTRARY CONTAINED HEREIN, OUR LIABILITY TO YOU FOR ANY CAUSE WHATSOEVER AND REGARDLESS OF THE FORM OF THE ACTION, WILL AT ALL TIMES BE LIMITED TO THE LESSER OF THE AMOUNT PAID, IF ANY, BY YOU TO US DURING THE ONE MONTH PERIOD PRIOR TO ANY CAUSE OF ACTION ARISING OR \$0.01. CERTAIN STATE LAWS DO NOT ALLOW LIMITATIONS ON IMPLIED WARRANTIES OR THE EXCLUSION OR LIMITATION OF CERTAIN DAMAGES.
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                <strong>27. INDEMNIFICATION</strong>
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                You agree to defend, indemnify, and hold us harmless, including our subsidiaries, affiliates, and all of our respective officers, agents, partners, and employees, from and against any loss, damage, liability, claim, or demand, including reasonable attorneys' fees and expenses, made by any third party due to or arising out of: (1) [your Contributions]; (2) use of the Service; (3) breach of these Terms of Service; (4) any breach of your representations and warranties set forth in these Terms of Service; (5) your violation of the rights of a third party, including but not limited to intellectual property rights; or (6) any overt harmful act toward any other user of the Service with whom you connected via the Service.
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                Notwithstanding the foregoing, we reserve the right, at your expense, to assume the exclusive defense and control of any matter for which you are required to indemnify us, and you agree to cooperate, at your expense, with our defense of such claims. We will use reasonable efforts to notify you of any such claim, action, or proceeding which is subject to this indemnification upon becoming aware of it.
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                <strong>28. USER DATA</strong>
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                We will maintain certain data that you transmit to the Service for the purpose of managing the Service, as well as data relating to your use of the Service. Although we perform regular routine backups of data, you are solely responsible for all data that you transmit or that relates to any activity you have undertaken using the Service.
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                You agree that we shall have no liability to you for any loss or corruption of any such data, and you hereby waive any right of action against us arising from any such loss or corruption of such data.
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                <strong>29. ELECTRONIC COMMUNICATIONS, TRANSACTIONS, AND SIGNATURES</strong>
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                Visiting the Service, creating a Profile or an Account, registering as a User, sending us emails, and completing online forms constitute electronic communications. You consent to receive electronic communications, and you agree that all agreements, notices, disclosures, and other communications we provide to you electronically, via email and on the Service, satisfy any legal requirement that such communication be in writing.
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                YOU HEREBY AGREE TO THE USE OF ELECTRONIC SIGNATURES, CONTRACTS, ORDERS, AND OTHER RECORDS, AND TO ELECTRONIC DELIVERY OF NOTICES, POLICIES, AND RECORDS OF TRANSACTIONS INITIATED OR COMPLETED BY US OR VIA THE SERVICE.
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                You hereby waive any rights or requirements under any statutes, regulations, rules, ordinances, or other laws in any jurisdiction which require an original signature or delivery or retention of non-electronic records, or to payments or the granting of credits by any means other than electronic means.
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                <strong>30. CALIFORNIA USERS AND RESIDENTS</strong>
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                If any complaint with us is not satisfactorily resolved, you can contact the Complaint Assistance Unit of the Division of Consumer Services of the California Department of Consumer Affairs in writing at 1625 North Market Blvd., Suite N 112, Sacramento, California 95834 or by telephone at (800) 952-5210 or (916) 445-1254.
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                <strong>31. MISCELLANEOUS</strong>
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                These Terms of Service and any policies or operating rules posted by us on the Service, including privacy policies, constitute the entire agreement and understanding between you and us. Our failure to exercise or enforce any right or provision of these Terms of Service shall not operate as a waiver of such right or provision.
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                These Terms of Service operate to the fullest extent permissible by law. We may assign any or all of our rights and obligations to others at any time. We shall not be responsible or liable for any loss, damage, delay, or failure to act caused by any cause beyond our reasonable control.
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                If any provision or part of a provision of these Terms of Service is determined to be unlawful, void, or unenforceable, that provision or part of the provision is deemed severable from these Terms of Service and does not affect the validity and enforceability of any remaining provisions.
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                There is no joint venture, partnership, employment or agency relationship created between you and us as a result of these Terms of Service or use of the Service. You agree that these Terms of Service will not be construed against us by virtue of having drafted them.
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                You hereby waive any and all defenses you may have based on the electronic form of these Terms of Service and the lack of signing by the parties hereto to execute these Terms of Service.
                            </>
                        )}
                    </Text>
                </Stack>
            </Container>
        </Stack>
    );
}