import { Stack, Image, Heading, Text, Box, Button, Spinner } from '@chakra-ui/react';
import { useState } from "react";
const BASE_URL = process.env.REACT_APP_BASE_URL;

export default function ShopCard({ id, title, slug, price, image, target_link, description, onCheckout }) {

    const [isLoading, setIsLoading] = useState(false); // Initialize loading state
    
    const sanitizeHTML = (html) => {
        const tempElement = document.createElement('div');
        tempElement.innerHTML = html;
        return tempElement.textContent || tempElement.innerHTML || '';
    };

    const handleCheckout = () => {
        // Set loading state to true when the button is clicked
        setIsLoading(true);

        // Call the onCheckout callback from the parent component
        onCheckout();

        // You can reset the loading state after the checkout operation is complete
        // For this example, I'm simulating a delay with setTimeout.
        setTimeout(() => {
            setIsLoading(false);
        }, 2000); // Simulated 2-second delay
    }

    return (
        <Stack
            boxShadow={'0px 0px 25px -14px #000'}
            borderRadius={12}
            w={{base: '100%', lg: '32%'}}
            px={'15px'}
            py={'20px'}
        >
            <Image src={BASE_URL + image} />
            <Stack
                direction={'row'}
                alignItems={'center'}
                justifyContent={'space-between'}
                pt={'8px'}
                pb={'15px'}
            >
                <Heading fontSize={22} fontFamily={'Poppins'} >{title}</Heading>
                <Text fontFamily={'Poppins'} color={'primaryBlue.100'} fontWeight={'600'}>{price}</Text>
            </Stack>
            <Text fontSize={'16'} fontFamily={'Poppins'}>{sanitizeHTML(description)}</Text>
            <Box
                w={'100%'}
                mb={'20px'}
            >
                {/* <Button
                    onClick={handleCheckout}
                    fontFamily={"Poppins"}
                    bgGradient='linear(to-b, primaryBlue.100, primaryBlue.200)'
                    _hover={{
                        bg: 'primaryBlue.100',
                        color: '#fff'
                    }}
                    w={'full'}
                    borderRadius={8}
                    py={6}
                    isDisabled={isLoading} // Disable the button when loading
                >
                    {isLoading ? <Spinner thickness='4px' speed='0.65s' emptyColor='primaryBlue.200' color='white' size='lg' /> : "BUY NOW"}
                </Button> */}
                <Button
                    fontFamily={"Poppins"}
                    bgGradient='linear(to-b, primaryBlue.100, primaryBlue.200)'
                    _hover={{
                        bg: 'primaryBlue.100',
                        color: '#fff'
                    }}
                    w={'full'}
                    borderRadius={8}
                    py={6}
                    isDisabled={isLoading} // Disable the button when loading
                >
                    COMING SOON
                </Button>
            </Box>
        </Stack>
    )
}