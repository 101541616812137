import {
    Box,
    Text,
    Stack,
    Button,
    Flex,
  } from '@chakra-ui/react';
  import { ArrowBackIcon } from '@chakra-ui/icons'
  import { Link } from 'react-router-dom';
  import '../assets/css/Main.css'

  export default function Success() {
    return (
      <>
        <Box textAlign="center" fontSize="xl">
          <Flex minH="60vh" p={3} alignItems='center' justifyContent='center'>
            <Stack spacing={8} justifyContent='center'>
              <Text fontSize={50} fontWeight={800} className='bgText'>Thank You</Text>
              <Text fontSize={16}>You have just completed your payment. <br /> The seller will reach out to you as soon as possible.</Text>
              <Box textAlign='center'>
                <Link to='/'>
                  <Button leftIcon={<ArrowBackIcon />} variant='outline' colorScheme='teal' w='140px' h='45px' >
                    Go Back
                  </Button>
                </Link>
              </Box>
            </Stack>
          </Flex>
        </Box>
      </>
    );
  }