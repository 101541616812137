import { Stack, Box, Button, Icon, Menu, MenuList, MenuItem, MenuButton, Image, Text, } from '@chakra-ui/react'
import { BsBell } from 'react-icons/bs';
import { AiFillCaretDown } from 'react-icons/ai';
import UserPic from '../../assets/images/profile.png';
import { useNavigate } from 'react-router-dom';
import { signOut } from "../../utilities/ApiProvider";

export default function LoginUser() {

    const navigate = useNavigate();
    const authenticatedUserData = JSON.parse(localStorage.getItem('authenticatedUserData'));
    const imageUrl = authenticatedUserData ? authenticatedUserData.image : null;
    const BASE_URL = process.env.REACT_APP_BASE_URL; // Replace with your base URL
    const profileImage = imageUrl ? `${BASE_URL}${imageUrl}` : null;

    function logoutHandler() {
        signOut()
            .then(responseData => {
                localStorage.clear();
                window.location.href = "/";
            }).catch(error => {
                console.log(error);
            });
    }

    return (
        <Stack
            direction={'row'}
        >
            {/* <Box>
                <Menu>
                    <Button
                        as={MenuButton}
                        backgroundColor={'transparent'}
                        _hover={{ backgroundColor: 'transparent' }}
                        _active={{ backgroundColor: 'transparent' }}
                    >
                        <Icon as={BsBell} fontSize={26} />
                    </Button>
                    <MenuList>
                        <MenuItem>
                            <Stack
                                direction={'row'}
                                alignItems={'center'}
                                gap={5}
                            >
                                <Image src={UserPic} />
                                <Box>
                                    <Text fontFamily={'Poppins'} color={'#1a1a1a'} fontSize={15}>John added your artwork to his collection</Text>
                                    <Text fontFamily={'Poppins'} color={'#1a1a1a'} fontSize={13}>12 mins ago</Text>
                                </Box>
                            </Stack>
                        </MenuItem>
                        <MenuItem>
                            <Stack
                                direction={'row'}
                                alignItems={'center'}
                                gap={5}
                            >
                                <Image src={UserPic} />
                                <Box>
                                    <Text fontFamily={'Poppins'} color={'#1a1a1a'} fontSize={15}>John added your artwork to his collection</Text>
                                    <Text fontFamily={'Poppins'} color={'#1a1a1a'} fontSize={13}>12 mins ago</Text>
                                </Box>
                            </Stack>
                        </MenuItem>
                        <MenuItem>
                            <Stack
                                direction={'row'}
                                alignItems={'center'}
                                gap={5}
                            >
                                <Image src={UserPic} />
                                <Box>
                                    <Text fontFamily={'Poppins'} color={'#1a1a1a'} fontSize={15}>John added your artwork to his collection</Text>
                                    <Text fontFamily={'Poppins'} color={'#1a1a1a'} fontSize={13}>12 mins ago</Text>
                                </Box>
                            </Stack>
                        </MenuItem>
                    </MenuList>
                </Menu>
            </Box> */}
            <Box w={'170px'}>
                <Menu>
                    <Button
                        as={MenuButton}
                        backgroundColor={'transparent'}
                        _hover={{ backgroundColor: 'transparent' }}
                        _active={{ backgroundColor: 'transparent' }}
                    >
                        <Stack
                            direction={'row'}
                            alignItems={'center'}
                        >
                            <Image src={profileImage} style={{ width: '52px', height: '52px' }} />
                            <Text fontFamily={'poppins'}>{ authenticatedUserData && authenticatedUserData.name }</Text>
                            <Icon as={AiFillCaretDown} />
                        </Stack>
                    </Button>
                    <MenuList>
                        <MenuItem onClick={() => navigate('/profile')}>Profile</MenuItem>
                        <MenuItem onClick={() => navigate('/change-password')}>Change Password</MenuItem>
                        <MenuItem onClick={logoutHandler}>Logout</MenuItem>
                    </MenuList>
                </Menu>
            </Box>
        </Stack>
    )
}
