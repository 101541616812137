import { Box, Container, Heading, Image, Stack, Text, Link, Button, useToast, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter, useDisclosure, Spinner } from "@chakra-ui/react";
import MyLand from "../components/Home/MyLand";
import Collections from "../components/Home/Collections";
import Gimage from '../assets/images/g.png'
import DiscoverNft from "../components/Home/DiscoverNft";
import { useState, useEffect } from "react";
import Profile from '../assets/images/profile.png';
import NoRecordFound from '../assets/images/no-record-found.webp';
import EnvironmentFeedBox from "../components/Home/EnvironmentFeedBox";
import { useNavigate } from 'react-router-dom';
import { useParams } from "react-router-dom";
import { getEnvironments, deleteEnvironment } from "../utilities/ApiProvider";

export default function Home() {
  
  const { isOpen, onOpen, onClose } = useDisclosure();
  const navigate = useNavigate();
  const toast = useToast();
  const [selectedBtn, setSelectedBtn] = useState('All');
  const [environments, setEnvironments] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [walletInfo, setWalletInfo] = useState(null);
  const { id } = useParams(); // Get the "id" parameter from the URL for social share case

  const connectWallet = () => {
    if (window.ethereum) {
      window.ethereum.request({
        method: 'eth_requestAccounts'
      }).then(result => {
        setWalletInfo(result[0])
      })
    } else {
      onOpen();
    }
  };

  const toMetaMask = () => {
    window.open('https://chrome.google.com/webstore/detail/metamask/nkbihfbeogaeaoehlefnkodbefgpgknn', '_blank');
    onClose();
  }

  useEffect(() => {
    getEnvironments(id || null)
      .then(responseData => {
        setEnvironments(responseData.data.records);
        setIsLoading(false);
      }).catch(error => {
        console.log(error);
        setIsLoading(false);
      });
  }, [walletInfo]);

  const handleDeleteEnvironment = (environmentId) => {
    // Call the deleteEnvironment API or perform the deletion logic here
    deleteEnvironment(environmentId)
        .then(responseData => {
            toast({
                title: 'Success',
                description: responseData.message,
                status: 'success',
                duration: 2000,
                isClosable: true,
            });

            // Update the myEnvironments state by removing the deleted Environment
            const updatedEnvironments = environments.filter(environment => environment.id !== environmentId);
            setEnvironments(updatedEnvironments);
        }).catch(error => {
            toast({
                title: 'Error',
                description: error.response.data.message,
                status: 'error',
                duration: 2000,
                isClosable: true,
            });
        });

}

  const buttonCss = {
    bgColor: 'transparent',
  }

  return (
    <Stack
      pb={12}
    >
      <Container maxW={{ base: '100%', lg: '8xl' }}>
        <Stack
          flexWrap={{ base: 'warp', lg: 'no-wrap' }}
          direction={{ base: 'column', lg: 'row' }}
          justifyContent={'space-between'}
          gap={6}
        >
          <Stack
            flex={1}
            gap={6}
            display={{ base: 'none', lg: 'initial' }}
          >
            {/* My Land Component */}
            <MyLand />
            {/* My Collection Component */}
            <Collections />
          </Stack>
          <Stack
            flex={2}
          >
            {/* Feed Component */}
            <Stack
              boxShadow={'0px 0px 25px -14px #000'}
              borderRadius={12}
              px={'15px'}
              py={'20px'}
            >
              <Stack
                direction={'row'}
                justifyContent={'space-between'}
                alignItems={'center'}
                mb={'35px'}
              >
                <Heading fontSize={24} fontFamily={'Poppins'}>Environment</Heading>
                {/* <Box
                  display={'flex'}
                  alignItems={'center'}
                  gap={{ base: 0, lg: 2 }}
                  fontFamily={'Poppins'}
                  flexWrap={{ base: 'wrap', lg: 'no-wrap' }}
                  justifyContent={'flex-end'}
                >
                  <Button color={selectedBtn === 'All' ? '#573cc2' : '#c2c2c2'} sx={buttonCss} _hover={{ bgColor: 'transparent' }} _focus={{ bgColor: 'transparent' }} onClick={() => setSelectedBtn('All')}>All</Button>
                  <Button color={selectedBtn === 'Lands' ? '#573cc2' : '#c2c2c2'} sx={buttonCss} _hover={{ bgColor: 'transparent' }} _focus={{ bgColor: 'transparent' }} onClick={() => setSelectedBtn('Lands')}>Lands</Button>
                  <Button color={selectedBtn === 'Environment' ? '#573cc2' : '#c2c2c2'} sx={buttonCss} _hover={{ bgColor: 'transparent' }} _focus={{ bgColor: 'transparent' }} onClick={() => setSelectedBtn('Environment')}>Environment</Button>
                  <Button color={selectedBtn === 'NFT' ? '#573cc2' : '#c2c2c2'} sx={buttonCss} _hover={{ bgColor: 'transparent' }} _focus={{ bgColor: 'transparent' }} onClick={() => setSelectedBtn('NFT')}>NFT</Button>
                </Box> */}
              </Stack>
              <Stack>
                {isLoading ? ( // Check if loading is true
                    <div
                        style={{
                        position: "fixed",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        }}
                    >
                        <Spinner size="xl" color="blue.500" />
                    </div>
                ) : environments && environments.length > 0 ? (
                    <>
                      {
                        environments.length &&
                        environments.map((v, i) => <EnvironmentFeedBox key={i} {...v} onDelete={() => handleDeleteEnvironment(v.id) } />)
                      }
                    </>
                ) : (
                  <Stack
                      justifyContent="center"
                      alignItems="center"
                      w="100%" // Set the width to 100% to center horizontally
                      h="50vh" // Set the height to 100vh to center vertically
                  >
                      <Box textAlign="center" p={4} color="gray.500">
                          <Image src={NoRecordFound} w="200px" />
                      </Box>
                      <Box textAlign="center" p={4} color="gray.500">
                          <Heading fontSize="xl" mb={2}>
                              Oops, No Records Found!
                          </Heading>
                      </Box>
                  </Stack>
                )}
              </Stack>
            </Stack>
          </Stack>
          <Stack
            flex={1}
            display={{ base: 'none', lg: 'initial' }}
            gap={12}
          >
            <Button
              fontFamily={"Poppins"}
              bgGradient='linear(to-b, primaryBlue.100, primaryBlue.200)'
              _hover={{
                bg: 'primaryBlue.100',
                color: '#fff'
              }}
              w={'full'}
              borderRadius={8}
              py={6}
              color={'#fff'}
              mb={'10px !important'}
              onClick={() => navigate('/upload-environment')}
            >
              Upload Environment
            </Button>

            {/* Coins Component */}
            {
              walletInfo ?
                <Box
                  boxShadow={'0px 0px 25px -14px #000'}
                  borderRadius={12}
                  padding={6}
                  position={'relative'}
                  // _before={{
                  //   content: '""',
                  //   position: 'absolute',
                  //   left: '105px',
                  //   top: '50px',
                  //   w: '4px',
                  //   h: '40px',
                  //   bgColor: '#c2c2c2'
                  // }}
                  mb={'20px !important'}
                >
                  <Box
                    display={'flex'}
                    alignItems={'center'}
                    justifyContent={'space-between'}
                  >
                    <Image src={Gimage} alt="gallery land logo" />
                    <Box>
                      <Text fontFamily={'Poppins'} fontSize={'18px'} fontWeight={'600'}>Coins</Text>
                      <Heading color={'#573cc2'} fontFamily={'Poppins'} fontStyle={'italic'} fontSize={'36px'}>3845550m</Heading>
                      <>
                        <Link onClick={connectWallet} _hover={{ textDecoration: 'none' }} fontSize={'18px'} color={'#573cc2'} fontWeight={'600'}>Buy More</Link>
                      </>
                    </Box>
                  </Box>
                </Box>
                :
                <Button
                  fontFamily={"Poppins"}
                  bgGradient='linear(to-b, primaryBlue.100, primaryBlue.200)'
                  _hover={{
                    bg: 'primaryBlue.100',
                    color: '#fff'
                  }}
                  w={'full'}
                  borderRadius={8}
                  py={6}
                  color={'#fff'}
                  mb={'10px !important'}
                  onClick={() => connectWallet()}
                >
                  Connect Wallet
                </Button>
            }
            {/* Discover NFTs component */}
            <DiscoverNft />
          </Stack>
        </Stack>
        <Modal isOpen={isOpen} onClose={onClose} size={'2xl'}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Wallet not found!</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Text>
                It seems to be like there is no wallet available on your browser, please install MetaMask and restart your browser to connect it with Gallery Land.
              </Text>
            </ModalBody>

            <ModalFooter>
              <Button colorScheme='blue' mr={3} onClick={toMetaMask}>Get MetaMask</Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </Container>
    </Stack >
  );
}