import { Container, Heading, Stack, Text, Link, Skeleton } from "@chakra-ui/react";
import { useState } from "react";

export default function PrivacyPolicy() {
    const [isLoading, setIsLoading] = useState(true);

    // Simulate a delay to demonstrate loading
    setTimeout(() => {
        setIsLoading(false);
    }, 600);

    // Function to generate content conditionally
    const generateContent = (text) => {
        if (isLoading) {
            return (
                <Stack>
                    <Skeleton height='15px' />
                    <Skeleton height='15px' />
                    <Skeleton height='15px' />
                    <Skeleton height='15px' />
                </Stack>
            );
        } else {
            return text;
        }
    }

    return (
        <Stack pb={12}>
            <Container maxW={'8xl'}>
                <Stack
                    boxShadow={'0px 0px 25px -14px #000'}
                    borderRadius={12}
                    w={'full'}
                    p={'20px'}
                    spacing={4}
                >
                    <Stack
                        direction={{ base: 'column', lg: 'row' }}
                        justifyContent={'space-between'}
                        alignItems={'center'}
                        mb={'25px'}
                        pb={'25px'}
                        borderBottom={'2px solid #adadad'}
                    >
                        <Heading fontSize={{ base: 'xl', lg: '3xl' }} fontFamily={'Poppins'}>Privacy Policy</Heading>
                    </Stack>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                <strong>Gallery Land Privacy Policy</strong>
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                The information we collect and how we use it.
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                Gallery Land operates a website, a mobile application and related platform features available through its website (“Website”) or its mobile application (“App”). We may refer to Gallery Land as “we,” “us,” or “our.” We may refer to you as “you” or “your.” The purpose of this policy is to inform you about the personal information we collect, how we use and share that information, and the ways in which you can control how we use and share that information. By using our Website or our App, you agree to the terms of this policy and you expressly consent to the collection, use, and disclosure of your personal information in accordance with this policy.
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                <strong>A. Definitions</strong>
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                The words of which the initial letter is capitalized have meanings defined under the following conditions. The following definitions shall have the same meaning regardless of whether they appear in singular or in plural.
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                Account means a unique account created for you to access our Website or App.
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                Account Information refers to your name, email address, mailing address, date of birth, location and phone number or similar information associated with your Account.
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                App refers to the Gallery Land mobile application.
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                CCPA, The California Consumer Privacy Act of 2018, Cal. Civ. Code §§ 1798.100–1798.199.
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                Consumer, for the purpose of the CCPA, means a natural person who is a California resident. A resident, as defined in the law, includes (1) every individual who is in the USA for other than a temporary or transitory purpose, and (2) every individual who is domiciled in the USA who is outside the USA for a temporary or transitory purpose.
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                Cookies are small files that are placed on your computer, mobile Device or any other Device by a website, containing the details of your browsing history on that website among its many uses.
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                Country refers to the United States of America.
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                Data Controller, for the purposes of the GDPR, refers to the Company as the legal person which alone or jointly with others determines the purposes and means of the processing of Personal Data.
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                Data Processor, for the purposes of the GDPR refers to the Company as the legal person which processes Personal Data.
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                Device means any device that can access the Website or App such as a computer, a cellphone, or a digital tablet.
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                Do Not Track (DNT) is a concept that has been promoted by United States regulatory authorities, in particular the U.S. Federal Trade Commission (FTC), for the internet industry to develop and implement a mechanism for allowing internet users to control the tracking of their online activities across websites.
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                GDPR refers to the General Data Protection Regulation, (EU) 2016/679. (Relatedly, U.K. GDPR refers to the United Kingdom’s privacy rules which adopted the privacy compliance requirements of GDPR.)
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                Personal Data is any information that relates to an identified or identifiable individual. For the purposes for GDPR, Personal Data means any information relating to you such as a name, an identification number, location data, online identifier or to one or more factors specific to the physical, physiological, genetic, mental, economic, cultural or social identity. For the purposes of the CCPA, Personal Data means any information that identifies, relates to, describes or is capable of being associated with, or could reasonably be linked, directly or indirectly, with you.
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                Personal Information is, for the purpose of the CCPA, information that identifies, relates to, describes, references, is reasonably capable of being associated with, or could reasonably be linked, directly or indirectly, with a particular Consumer.
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                               <strong>Privacy Policy or Policy means this policy.</strong>
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                Sale, for the purpose of the CCPA, means selling, renting, releasing, disclosing, disseminating, making available, transferring, or otherwise communicating orally, in writing, or by electronic or other means, a Consumer’s personal information to another business or a third party for monetary or other valuable consideration.
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                Service Provider means any natural or legal person who processes the data on behalf of the Company. It refers to third-party companies or individuals employed by the Company to facilitate the Website or App, to provide the Website or App on behalf of the Company, to perform services related to the Website or App or to assist the Company in analyzing how the Website or App is used. For the purpose of the GDPR, Service Providers are considered Data Processors.
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                Usage Data refers to data collected automatically, either generated by the use of the Website or the App or from the Website or the App infrastructure itself (for example, the duration of a page visit).
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                User Registration Data means data collected at the time of registration on the Website or App, including your email address, name, date of birth, phone number and location as well as username and password for your profile and your Account.
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                Website refers to Gallery Land, accessible at{' '}
                                <Link href="https://galleryland.io/" color="blue.500" textDecoration="underline" isExternal>
                                    galleryland.io
                                </Link>
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                <strong>B. Information Collected and Received from You</strong>
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                We may receive or collect Personal Data or Personal Information from you when you utilize the Website and App or when you voluntarily provide Personal Data or Personal Information to us. This may include the following:
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                <strong>Account Information and User Registration Data</strong>
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                We collect and maintain Account Information. When you register or sign up on the Website or App for certain features, newsletters and/or special offers, you may provide us, and we may collect, your User Registration Data.
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                <strong>Information Collected and Received from You</strong>
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                You may elect to provide a variety of information during your interactions with us and the Website and App, such as emails you may send us, ads you respond to, comments, posts, or interactions that you may make through your Account, SMS or text messages that you may sign up for through the Website or App, and emails or newsletters that you sign up to receive from us. We, or third parties acting on our behalf, receive data from you whenever you provide us with this information.
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                <strong>Information as to your Device</strong>
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                When you access and use the Website or App from a computer, mobile phone, tablet, or other Device, we may user information about those Devices, including your browser type and Internet Protocol (IP) address. If you access the Website from a mobile phone, tablet, automobile, or other Device, we may collect and store information such as Device type, operating system version and type, wireless carrier, performance metrics of our Website or App, and other related information.
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                <strong>Information as to your Location</strong>
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                When you register for the Website or App, we may collect location information, derived from your IP address, from Devices or web browsers you use while accessing the Website or App.
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                <strong>Usage Data</strong>
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                We may collect data on the pages of our Website or App you visit, the time and date of your visit, and time, frequency, and duration spent on those pages, as well as the particular features you use.
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                <strong>Cookies</strong>
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                Gallery Land may collect Personal Data or Personal Information through the use of cookies or other tracking technologies. For complete information about our cookie policy, see Section E of this Privacy Policy.
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                <strong>Information that you post</strong>
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                Our Website and App store content you post until you delete it. Additionally, Gallery Land may store backup copies of content that you post through our Website and App for archival and data redundancy purposes.
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                <strong>Other Information</strong>
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                We may collect certain information through the use of log files and servers. These servers create log files as part of the process of setup and configuration. Information in a log file may include IP address (and associated geographic information), browser type, Internet service provider, date/time stamps, and other usage or statistical information.
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                <strong>C. Information Collected and Received from Others</strong>
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                We may receive or collect information about you from third parties or others when we use an outside service provider to display ads on the Website or App. Where these ads contain cookies that store your Personal Data or Personal Information, your Personal Data or Personal Information may be collected and stored on our servers with other information we may have already received or collected from you. This Personal Data and Personal Information is used to serve relevant ads to you and is based on items that may be considered Personal Data or Personal Information under the law, such as your IP address, browser type, time and date, and subject of advertisements clicked or interacted with, but that may be anonymized or non-personally identifiable information.
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                These third parties may provide us with certain information about their customers for the purposes of serving advertisements and/or marketing offers to their customers on the Website or through the App. We are not responsible for, and will assume no liability, if a business partner or other entity collects, uses, or shares any information about you in violation of its own privacy policy or any applicable laws, rules, or agreements. You are directed to review the privacy policies of any third parties that advertise on or through the Website or App.
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                <strong>D. How We Use Information</strong>
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                We may use your User Registration Data, Account Information, Personal Data, and Personal Information, as well as data which we derive or infer from combinations of the foregoing, for a variety of purposes and situations, such as:
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                To facilitate the creation of and secure your Account on the Website or App.
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                To provide and improve the Website and App and to develop new products and services.
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                To customize and personalize the advertising and other content we deliver to you both on the Website and App and on other services offered by our publishing partners. We use this information to provide you with relevant and interesting advertising and other content.
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                To fulfill your requests for certain products and services, such as distributing electronic newsletters and enabling you to participate in surveys and public forums.
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                To send you information that you requested or agreed to receive. To alert you to the latest developments and features on our Website and App and to notify you of administrative information, such as security or support and maintenance advisories.
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                To invite you to participate in events or special promotions related to products we think you may like or in which you may be interested. To advertise and market to you, which includes sending promotional communications and advertisements.
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                To allow our business partners to offer you certain products, services or promotions.
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                To transfer Personal Data and Personal Information in the event of a merger, divestiture, restructuring, reorganization, dissolution, or other sale or transfer of some or all of our assets, whether as a going concern or as part of bankruptcy, liquidation, or similar proceeding.
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                To measure, analyze, and improve our services.
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                We do not sell or give your Personal Data or Personal Information to companies, including political candidates or campaigns, for their own marketing purposes without your permission. We do use Personal Data and Personal Information, however, to contact you, provide you with technical support, send you notices about the Website and App or other promotional offers you have elected to receive, and to serve you with ads that are more relevant to your interests.
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                If you provided your Personal Data or Personal Information as part of your use of the Website, the App or your Account, we may use your Personal Data or Personal Information to update you on new features and products. You may contact us at any time to request that we cease using your Personal Data or Personal Information.
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                <strong>E. Information Collection Technologies/Cookies</strong>
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                Gallery Land may collect Personal Data or Personal Information through the use of cookies or other tracking technologies. Cookies and other tracking technologies help Gallery Land understand how you use the Gallery Land Website and App and provide us with an understanding of how our users utilize our services. Gallery Land's use of cookies aids in developing or improving our services in response to your needs and wants and to provide you with relevant offers from our advertisers, where applicable.
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                Gallery Land may use session or persistent cookies. Session cookies are only stored on your computer or mobile Device while you use the Gallery Land services and are automatically deleted when you close your web browser or cease using the App. If you are located in Canada, the United Kingdom, or the European Union, Gallery Land requests your consent before storing session cookies on your computer or mobile Device.
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                Persistent cookies can be read by the website or mobile applications that placed the cookie when you revisit the website or mobile application. Persistent cookies authenticate you as a user and track your behavior on the website or through the mobile application. Persistent cookies thereby allow a fast, consistent user experience tailored to your preferences. Gallery Land requests your consent to utilize persistent cookies when you register a user account with Gallery Land and agree to its Terms of Use Agreement and Privacy Policy.
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                If you object to Gallery Land's use of cookies, you can clear your web browser cache and stop using the Website or App to remove session cookies. To remove persistent cookies, clear your web browser, delete your user account, and stop using the Website or App.
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                Gallery Land uses third-party session cookies.
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                <strong>F. Retention of Your Personal Data</strong>
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                We will retain your Personal Data and Personal Information only for as long as we determine is necessary to achieve the purposes set out in this Privacy Policy. We will retain and use your Personal Data to the extent necessary to comply with our legal obligations (for example, if we are required to retain your data to comply with applicable laws), resolve disputes, and enforce our legal agreements and policies.
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                Your Account Information will be retained by us while your Account and profile is active. We will retain and use this information for the purposes for which it was collected (as specified in this Policy or as we disclose to you outside of this Policy), including, to provide information and products to you, comply with our legal obligations, resolve disputes, and enforce our agreements.
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                <strong>G. Transfer and Processing of Your Personal Data</strong>
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                Your Personal Data and Personal Information is processed at our offices and data centers or the offices and data centers of our third-party partners. This means that this information may be transferred to — and maintained on — computers located outside of your state, province, country or other governmental jurisdiction where the applicable data protection laws may differ than those from your jurisdiction. Your consent to this Privacy Policy followed by your submission of such information represents your agreement to that transfer.
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                We will take all steps reasonably necessary to ensure that your Personal Data and Personal Information, is treated securely and in accordance with this Privacy Policy and that no transfer of your Personal Data and Personal Information will take place to an organization or a country unless there are adequate access and security controls in place to maintain the same level of protection as is provided under this Privacy Policy.
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                <strong>H. Sharing Information with Others</strong>
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                We may share information that we collect with our subsidiaries, successors, or related companies for the purposes described in our respective privacy policies, and to offer, provide, and improve services and products offered both individually and jointly with these companies.
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                We may share information that we receive or collect, such as when you give us permission through an affirmative election (for example, clicking “yes” in response to a message or email inquiring as to whether to “share my email with this advertiser”). We may also share information if we partner with a third party to provide services on our behalf, such as credit card processors, customer support, advertising services, or bulk email processors who may send out emails on our behalf.
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                These companies are authorized to use your Personal Data and Personal Information only as necessary to provide these services to us. They are not authorized to use your Personal Data and Personal Information for their own, unrelated purposes.
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                We may also share your personal information in the good faith belief that such action is necessary to:
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                Comply with a legal obligation, such as upon receipt of a duly authorized subpoena or court order or in the event of an audit.
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                Protect and defend the rights or property of the Gallery Land or the legal rights of third parties or our employees, agents, and contractors (including under us agreements).
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                Prevent or investigate possible wrongdoing in connection with the Website or App.
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                Protect the personal safety of users of the Website or App or the public.
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                Protect against legal liability.
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                Additionally, we may share information we gather from Devices you use to access the Website or App with third-party vendors, manufacturers, service providers or advertising entities. We share this information for a variety of purposes, such as tracking advertising, estimating the number of users, security and fraud detection, debugging problems, and for providing you with more relevant advertisements on the Website and App.
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                If there is a transfer or sharing of your Personal Data or Personal Information in accordance with this Policy, we will seek assurances from the recipients of such information (prior to the transfer) that they will safeguard the information in a manner consistent with this Policy.
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                <strong>I. Direct Messaging</strong>
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                Gallery Land offers users the ability to send direct messages to their followers, allowing for more private, one-to-one communications. Gallery Land does not encrypt these direct messages. When you communicate with others by sending or receiving Gallery Land's direct messages, we will save the messages along with certain information necessary for processing them for either seven (7), fourteen (14), thirty (30), or ninety (90) days, as elected by the user. (The default setting is 14 days.)
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                In the event that a user reports a message for a violation of Gallery Land's rules or any applicable law, Gallery Land typically will keep the reported message – and associated portions of the related conversation – for ninety (90) days after reporting.
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                Remember that when you use Gallery Land's Direct Messages to communicate, recipients retain their own copy. Recipients may duplicate, store, or re-send your messages. If you delete your copy of a message sent from your account, you will delete that message in your recipient’s account as well, but Gallery Land will retain the message for the time periods described above. Gallery Land does not use information collected from direct messages for advertising purposes.
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                <strong>J. Account Management and Settings</strong>
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                You will have the ability to access and modify your Account Information on your Account, which you may do through the settings section of our Website or App at any time. You have control through settings on your Account and on your profile to limit data we collect from you and how we use it. You can also download the information you have shared with the Website and App.
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                Upon your registration of an Account on the Website or App, you may elect to receive promotional, marketing, or other similar emails tailored to your interests. You may change this election at any time. Additionally, you may also unsubscribe from any promotional, marketing, or other similar emails you receive. We will send you confirmation emails and other Website-related or App-related announcements when it is necessary to do so.
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                <strong>K. Security Measures</strong>
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                When necessary, we have implemented security measures designed to protect against the loss, misuse, and alteration of the information we collect or receive from you. Nevertheless, no security measures are impenetrable and there are always security risks.
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                We will use the information we collect in ways that are relevant and compatible with the purpose for which that information was collected or provided to us as disclosed in this Policy. We will use safeguards to ensure that all information collected, processed, and/or stored is protected from loss, destruction, or use in a manner inconsistent with our policies or the purpose for which we received it.
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                               Our Website and App, as well as advertisements on our Website and App, may include links to other websites whose privacy practices may differ from ours. If you submit Personal Information and Personal Data to any of those websites, the privacy statements and practices of those websites govern their use of your information. Please be sure to review the privacy statement of any website you visit.
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                Other third parties or advertisers may utilize framing techniques to serve content to and from webpages accessible through our Website and App while preserving the look and feel of our website. Please note that you may be providing your Personal Information and Personal Data to this third-party (and not to us) in this situation.
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                <strong>L. GDPR Privacy</strong>
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                This Privacy Policy addresses the concerns set forth by the GDPR, including what and how we collect your data, how we use and store your data, marketing, data protection rights, cookies and how we use them, relevant privacy policies of other websites, how we change our Privacy Policy, and how to contact us and appropriate privacy authorities. For information about these topics, please consult the relevant sections of this Policy.
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                Legal Basis for Processing Personal Data under GDPR. We may process Personal Data under the following conditions:
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                Consent: You have given your consent for processing Personal Data for one or more specific purposes.
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                Performance of a contract: Provision of Personal Data is necessary for the performance of an agreement with you and/or for any pre-contractual obligations thereof.
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                Legal obligations: Processing Personal Data is necessary for compliance with a legal obligation to which we are subject.
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                Vital interests: Processing Personal Data is necessary in order to protect your vital interests or those of another natural person.
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                Public interests: Processing Personal Data is related to a task that is carried out in the public interest or in the exercise of official authority vested in us.
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                Legitimate interests: Processing Personal Data is necessary for the purposes of the legitimate interests pursued by us.
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                In any case, we will gladly help to clarify the specific legal basis that applies to the processing, and in particular whether the provision of Personal Data is a statutory or contractual requirement, or a requirement necessary to enter into a contract.
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                Your Rights under the GDPR. We undertake to respect the confidentiality of your Personal Data and to guarantee you can exercise your rights. You have the right under this Privacy Policy, and by law if you are within the EU, to:
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                Request access to your Personal Data. The right to access, update, or delete the Personal Data we have on you. Whenever made possible, you can access, update or request deletion of your Personal Data directly within your Account settings section. If you are unable to perform these actions yourself, please contact us to assist you. This right also allows you to receive a copy of the Personal Data we hold about you.
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                Request rectification or correction of the Personal Data that we hold about you. You have the right to have any incomplete or inaccurate Personal Data we hold about you corrected.
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                Object to processing of your Personal Data. You have the right to object to the processing of your Personal Data. This right exists where we are relying on a legitimate interest as the legal basis for our processing of your Personal Data and there is something about your situation that makes you want to object to our processing of your Personal Data on this ground. You also have the right to object where we are processing your Personal Data for direct marketing purposes.
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                Request erasure of your Personal Data. You have the right to ask us to delete or remove your Personal Data when there is no good reason for us to continue processing it.
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                Request the transfer of your Personal Data. You have the right to request the transfer of your Personal Data. We will provide to you, or to a third-party that you have chosen, your Personal Data in a structured, commonly used, machine-readable format. Please note that this right only applies to automated information which you initially provided consent for us to use or where we used the information to perform a contract with you.
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                Withdraw your consent. You have the right to withdraw your consent to the processing or use of your Personal Data. If you withdraw your consent, we may not be able to provide You with access to certain specific functionalities of the Website or App.
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                Exercising of your GDPR Data Protection Rights. You may exercise your rights by contacting us. Please note that we may ask you to verify your identity before responding to such requests. If you make a request, we will try our best to respond to you as soon as possible. You have the right to complain to a data protection authority about our collection and use of your Personal Data. For more information, if you are in the European Economic Area (EEA), please contact your local data protection authority in the EEA.
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                M. California Consumer Privacy Act of 2018 (CCPA)
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                These rules apply solely to all visitors, users, and others who are residents of the State of California (“California residents”). We adopt these rules to comply with the California Consumer Privacy Act (“CCPA”) and any terms defined in the CCPA have the same meaning when used here. Where noted, the CCPA temporarily exempts Personal Information reflecting a written or verbal business-to-business communication (“B2B personal information”) from some of its requirements.
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                California residents have the right to request that we disclose what Personal Information we collect, use, disclose, and sell (if applicable). Our uses of Personal Information and instructions for submitting a verifiable Consumer request for such disclosures are detailed further below.
                            </>
                        )}
                    </Text>

                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                <strong>1. Information We Collect</strong>
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                In particular, we collected the following categories (A, B, and F), as defined in the CCPA, of Personal Information from our Consumers within the last twelve (12) months:
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                <strong>2. We obtain the categories of Personal Information listed above from the following categories of sources:</strong>
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                Directly from California residents. For example, from forms California residents complete, documents or information California residents provide us as part of a transaction or inquiry, or products and services California residents obtain from us;
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                Indirectly from California residents. For example, from observing Consumers’ actions on our Website or App; or
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                From third parties or service providers that you have authorized to receive and share Personal Information.
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                <strong>3. Use of Personal Information for Commercial Purposes.</strong>
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                We may use or disclose personal information we collect for "business purposes" or "commercial purposes" (as defined under the CCPA), which may include the following examples:
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                To fulfill or meet the reason a Consumer provided the information. For example, if a Consumer shares his or her name and contact information to set up an Account or profile on our Website or App or to ask a question about our products or services, we will use that Personal Information to respond to his or her inquiry.
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                To provide, support, personalize, and develop our Website, App, products, and services.
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                To create, maintain, customize, and secure a customer’s Personal Information and Account.
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                To personalize the Website and App experience and to deliver content and product and service offerings relevant to a Consumer’s interests, including targeted offers and ads through our Website, App, third-party sites, and via email or text message (with Consumer consent, where required by law).
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                To help maintain the safety, security, and integrity of our Website, App, products and services, databases and other technology assets, and business.
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                For testing, research, analysis, and product development, including to develop and improve our Website, App, products, and services.
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                To respond to law enforcement requests and as required by applicable law, court order, or governmental regulations.
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                As described to Consumers when collecting their Personal Information or as otherwise set forth in the CCPA.
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                We will not collect additional categories of Personal Information or use the Personal Information we collected for materially different, unrelated, or incompatible purposes without providing you notice.
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                <strong>4. Sharing Personal Information.</strong>
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                We may disclose Consumers’ Personal Information to a third party for a business purpose. When we disclose Personal Information for a business purpose, we enter into a contract that describes the purpose and requires the recipient to both keep that Personal Information confidential and not use it for any purpose except for performing the contract. The CCPA prohibits third parties who purchase the Personal Information we hold from reselling it unless you have received explicit notice and an opportunity to opt-out of further sales.
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                We may share Consumers’ Personal Information for a business purpose to the following categories of third parties:
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                service providers;
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                advertising and marketing companies (as disclosed in our Privacy Policy);
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                our business partners; and
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                third party vendors to whom Consumers or their agents authorize us to disclose your Personal Information in connection with products or services we provide to consumers.
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                In the preceding twelve (12) months, Company has disclosed the following categories of Personal Information for a business purpose:
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                Category A: Identifiers.
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                Category B: California Customer Records Personal Information categories.
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                Category F: Internet or other similar network activity
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                <strong>5. Sales of Personal Information</strong>
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                In the preceding twelve (12) months, we have not sold Personal Information.
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                <strong>6. Sale of Personal Information of Minors Under 16 Years of Age</strong>
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                We do not knowingly collect Personal Information from minors under the age of 16 through our Website or App, although certain third party websites that we link to may do so.
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                These third-party websites have their own terms of use and privacy policies, and we encourage parents and legal guardians to monitor their children's internet usage and instruct their children to never provide information on other websites without their permission.
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                At this time, we do not sell the Personal Information of Consumers, including Consumers we actually know are less than 16 years of age.
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                If a Consumer has reason to believe that a minor under the age of 16 has provided us with Personal Information, please contact us with sufficient detail to enable us to delete that information.
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                <strong>7. California Residents’ Rights and Choices.</strong>
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                The CCPA provides Consumers (California residents) with specific rights regarding their Personal Information. This section describes Consumers’ CCPA rights and explains how to exercise those rights.
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                a) The right to notice. You have the right to be notified which categories of Personal Information are being collected and the purposes for which the Personal Information is being used.
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                b) Request Access to Specific Information and Data Portability Rights Consumers have the right to request that we disclose certain information to them about our collection and use of their Personal Information over the past 12 months. Once we receive and confirm a verifiable Consumer request (see Exercising Consumer CCPA Data Protection Rights), we will disclose to the requesting Consumer, as permitted by the degree of verification we are able to perform:
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                c) The right to say no to the sale of Personal Information (opt-out). Consumers have the right to direct us to not sell their Personal Information. At this time, we do not sell Personal Information.
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                d) Deletion Request Rights. Consumers have the right to request that we delete any Consumer Personal Information that we collected from Consumers and retained, subject to certain exceptions. Once we receive and confirm your verifiable Consumer request (see Exercising Consumer CCPA Data Protection Rights), we will delete (and direct our service providers to delete) the relevant Personal Information from our records, unless an exception applies. We may deny a Consumer deletion request if retaining the information is necessary for us or our service provider(s) to:
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                Complete the transaction for which we collected the Personal Information, provide a good or service that the Consumer requested, respond to an inquiry or process a request from you, take actions reasonably anticipated (or actions needed or requested by a Consumer) within the context of our ongoing business relationship with you, maintain or service any ongoing product or Consumer Account, or otherwise perform our contract or transaction with a Consumer.
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                Debug products to identify and repair errors that impair existing intended functionality.
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                Detect security incidents; protect against malicious, deceptive, fraudulent, or illegal activity; or prosecute those responsible for such activities.
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                Exercise another right provided for by law.
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                Comply with a legal obligation.
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                Comply with the California Electronic Communications Privacy Act (Cal. Penal Code § 1546 et. seq.).
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                e) The right not to be discriminated against. Consumers have the right not to be discriminated against for exercising any of their rights, including by:
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                Denying goods or services to consumers.
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                Charging different prices or rates for goods or services, including the use of discounts. or other benefits or imposing penalties.
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                Providing a different level or quality of goods or services to Consumers.
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                Suggesting that Consumers will receive a different price or rate for goods or services or a different level or quality of goods or service.
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                f) Exercising Consumer CCPA Data Protection Rights In order to exercise any of Consumer rights under the CCPA, a California resident can contact us: By email:{' '}
                                <Link href="mailto:g_land@myyahoo.com" color="blue.500" textDecoration="underline">
                                    g_land@myyahoo.com
                                </Link>
                                .
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                Only a Consumer, or a person registered with the California Secretary of State that a Consumer authorizes to act on his or her behalf, may make a verifiable request related to a Consumer’s Personal Information. A Consumer’s request to us must:
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                Provide sufficient information that allows us to reasonably verify the Consumer is the person about whom we collected Personal Information or an authorized representative
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                Describe the Consumer’s request with sufficient detail that allows us to properly understand, evaluate, and respond to it
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                We cannot respond to a Consumer request or provide a Consumer with the required information if we cannot:
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                Verify a Consumer identity or authority to make the request; and,
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                Confirm that the Personal Information relates to the requesting Consumer
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                We will disclose and deliver the required information free of charge within 45 days of receiving a Consumer’s verifiable request. The time period to provide the required information may be extended once by an additional 45 days when reasonably necessary and with prior notice.
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                Any disclosures we provide will only cover the 12-month period preceding the verifiable request's receipt. For data portability requests, we will select a format to provide Consumer Personal Information that is readily useable and should allow a Consumer to transmit the information from one entity to another entity without hindrance.
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                <strong>N. Children’s Privacy</strong>
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                We do not knowingly collect any information from anyone under the age of 18 without explicit parental approval as specified by the applicable privacy law. As a general matter, prospective users must enter a birthdate reflecting an age of 18 or greater when signing up for the Website or App. For more information about our policy towards minors using the Website or App, please see our Terms of Service. If you are a parent or guardian and you are aware that you child has provided us with Personal Data, please contact us. If we become aware that we have collected Personal Data from a minor without verification of parental consent, we take appropriate steps to remove that information from our servers.
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                <strong>O. State Laws</strong>
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                Please see below for additional provisions, as required under the consumer privacy law of your state.
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                <strong>1. California Residents</strong>
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                Your rights under Cal. Civ. Code § 1798.83 (Shine the Light law): We only share Personal Information (as defined) with third parties for direct marketing purposes if you either specifically opt-in, or are offered the opportunity to opt-out and elect not to opt-out of such sharing at the time you provide Personal Information or when you choose to participate in a feature on the Website or on the App. If you do not opt-in or if you opt-out at that time, we will not share your Personal Information with that identified third party for direct marketing purposes.
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                Your rights under Cal. Bus. & Prof. Code § 22575(b) (“Do Not Track” Browser Settings): You are entitled to know how we respond to “Do Not Track” browser settings. Like many other websites, we do not currently alter our practices when we receive Do Not Track signals as there is no consensus among industry participants as to what “Do Not Track” means in this context. However, you have the option to opt-out of interest-based advertising. To find out more about “Do Not Track,” you may wish to visit{' '}
                                <Link href="https://www.allaboutdnt.com/" color="blue.500" textDecoration="underline" isExternal>
                                    allaboutdnt.com
                                </Link>
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                Your rights under California Privacy Rights for Minor Users (California Business and Professions Code Section 22581):California Business and Professions Code § 22581 allow California residents under the age of 18 who are registered users of online sites, services, or applications to request and obtain removal of content or information they have publicly posted.
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                To request removal of such data, and if you are a California resident, you can contact us, and include the email address associated with your Account. Be aware that your request does not guarantee complete or comprehensive removal of content or information posted online and that the law may not permit or require removal in certain circumstances.
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                <strong>2. Nevada Residents</strong>
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                If you are a resident of Nevada, you may apply limits to the sale of certain Personal Information to third parties for resale or licensing purposes, subject to applicable law. We do not sell your Personal Information for such use. You are entitled to register your preference for limits on such sales in the future by sending an email to us with the subject line, “Nevada Do Not Sell Request” along with your first and last name, zip code, and whether you are a former or current Account holder or user. Your email address must match the email address on your Account to process this request.
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                <strong>P. Changes to our Privacy Policy</strong>
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                Our Privacy Policy will be periodically reviewed and evaluated in connection with new products, new services, new technologies, changes in law, and your needs. We may make changes or modifications to our Privacy Policy as a result thereof. Our Privacy Policy will be updated from time to time. If we make any material changes to this Privacy Policy, we will post the updated terms of the Privacy Policy on the Website and the App. Notice of such changes may include notice by email through a message sent to the email address you use to access the Website and App or to register as a user on the Website and App or posting a message on the Website or App. You are responsible for updating your Account Information to provide us with your most current email address as the email notice constitutes effective notice of said changes.
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                <strong>Q. Miscellaneous</strong>
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                From time to time, we may conduct compliance audits of our relevant privacy practices to verify adherence to this Privacy Policy and that said Privacy Policy is current and accurate. Any employee that we determine is in violation of this Privacy Policy may be subject to disciplinary action.
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                Any complaints or disputes as to collection or use of Personal Information or Personal Data under this Policy shall be completely investigated to seek a resolution or cooperate with dispute resolution procedures, if applicable to the dispute.
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                <strong>R. Contact Us</strong>
                            </>
                        )}
                    </Text>
                    <Text fontFamily={'Poppins'} fontSize={'sm'}>
                        {generateContent(
                            <>
                                If you have any questions about this Privacy Policy, our privacy practices, or how we collect or process your personal data, please contact us - Attn: User Support at{' '}
                                <Link href="mailto:g_land@myyahoo.com" color="blue.500" textDecoration="underline">
                                    g_land@myyahoo.com
                                </Link>
                                .
                            </>
                        )}
                    </Text>
                </Stack>
            </Container>
        </Stack>
    );
}
