import {
    Stack,
    Container,
    FormControl,
    FormLabel,
    Input,
    Button,
    Heading,
    Box,
    Text,
    Image,
    useToast,
    Spinner
} from "@chakra-ui/react";
import { useRef, useState } from 'react';
import Image1 from '../assets/images/1.png';
import Image2 from '../assets/images/2.png';
import { updateProfile } from "../utilities/ApiProvider";

export default function EditProfile() {

    // Retrieve existing data from localStorage
    const authenticatedUserData = JSON.parse(localStorage.getItem('authenticatedUserData'));

    const toast = useToast();
    const pictureRef = useRef(null);
    const [validation, setValidation] = useState(null);
    const [isLoading, setIsLoading] = useState(false); // Initialize loading state
    const [formData, setFormData] = useState({
        name: authenticatedUserData ? authenticatedUserData.name : '',
        email: authenticatedUserData ? authenticatedUserData.email : '',
    });
    const [selectedImage, setSelectedImage] = useState(null);

    const handleImageUpload = (event) => {
        const file = event.target.files[0];
        const reader = new FileReader();
    
        if (file && file.size > 1048576) { // Check if the file size exceeds 1 MB
            setValidation({
                data: {
                    image: ["Image size should not exceed 1 MB"]
                }
            });
            return; // Exit the function if the image size exceeds the limit
        } else {
            setValidation({
                data: {
                    ...validation?.data,
                    image: null // Clear the validation message for the image
                }
            });
        }
    
        reader.onloadend = () => {
            setSelectedImage(reader.result);
        };
    
        if (file) {
            reader.readAsDataURL(file);
        }
    };

    const handleImageChange = () => {
        // Reset the selected image
        setSelectedImage(null);
        // Clear the input value
        pictureRef.current.value = null;
    };

    const handleInputChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };
    
    const handleFormSubmit = (event, data) => {
        event.preventDefault();

        setIsLoading(true);

        const formDataWithImage = {
            ...data,
            image: selectedImage // Add the selected image to the form data
        };
        
        updateProfile(formDataWithImage)
            .then(responseData => {
                setValidation(responseData); // Set the data in validation
                toast({
                    title: 'Success',
                    description: responseData.message,
                    status: 'success',
                    duration: 1000,
                    isClosable: true,
                    onCloseComplete: () => {
                        // Update name and image properties
                        authenticatedUserData.name = responseData.data.name;
                        authenticatedUserData.image = responseData.data.image;
                
                        // Store updated data back into localStorage
                        localStorage.setItem('authenticatedUserData', JSON.stringify(authenticatedUserData));
                        window.location.href = "/edit-profile"; // Redirect to the "Edit Profile" screen upon successfully Update Profile
                    },
                });
                setIsLoading(false);
            }).catch(error => {
                setValidation(error.response.data);
                toast({
                    title: 'Error',
                    description: error.response.data.message,
                    status: 'error',
                    duration: 2000,
                    isClosable: true,
                });
                setIsLoading(false);
            });
    }

    const formLabelCss = {
        color: '#000',
        fontFamily: 'Poppins',
        fontSize: '16px',
        ml: '10px',
    }

    const inputCss = {
        px: 3,
        py: 6,
        bgColor: '#f3f3f3',
        borderRadius: '50px',
        placeholder: 'John Doe',
        border: 0,
    }

    return (
        <Container maxW={'6xl'}>
            <Stack
                boxShadow={'0px 0px 25px -14px #000'}
                px={'30px'}
                py={'40px'}
                mx={{ base: '0%', lg: '14%' }}
                mb={'80px'}
                borderRadius={12}
                bgColor={'#fff'}
            >
                <Heading
                    fontSize={'28px'}
                    fontFamily={'Poppins'}
                    color={'#000'}
                    mb={'30px'}
                >Edit Profile</Heading>
                <form onSubmit={(event) => handleFormSubmit(event, formData)}>
                    <Stack
                        direction={{base: 'column', lg: 'row'}}
                        flexWrap={'wrap'}
                        spacing={0}
                        justifyContent={'space-between'}
                    >
                        <Box
                            w={{base: '100%', lg: '47%'}}
                            mb={'20px !important'}
                        >
                            <FormLabel sx={formLabelCss}>Full Name</FormLabel>
                            <Input
                                sx={inputCss}
                                _placeholder={{ fontWeight: '600' }}
                                _focusVisible={{ outline: 'none' }}
                                type='text'
                                name='name'
                                value={formData.name}
                                onChange={handleInputChange}
                            />
                            {validation && validation.status == 0 && validation.data && validation.data.name && validation.data.name[0] && (
                                <Text color='red'>{validation.data.name[0]}</Text>
                            )}
                        </Box>
                        <Box
                            w={{base: '100%', lg: '47%'}}
                            mb={'20px !important'}
                        >
                            <FormLabel sx={formLabelCss}>Email</FormLabel>
                            <Input
                                sx={inputCss}
                                _placeholder={{ fontWeight: '600' }}
                                _focusVisible={{ outline: 'none' }}
                                type='email' 
                                name='email'
                                value={formData.email}
                                onChange={handleInputChange}
                            />
                            {validation && validation.status == 0 && validation.data && validation.data.email && validation.data.email[0] && (
                                <Text color='red'>{validation.data.email[0]}</Text>
                            )}
                        </Box>
                        <Box w="100%" mb="40px !important">
                            <FormLabel sx={formLabelCss}>Upload Profile Picture</FormLabel>
                            <Input
                                ref={pictureRef}
                                display="none"
                                type="file"
                                name="image"
                                accept="image/*"
                                onChange={handleImageUpload}
                            />
                            <Box
                                display={'flex'}
                                justifyContent={'center'}
                                alignItems={'center'}
                                flexDir={'column'}
                                bgColor={'#f3f3f3'}
                                border={'4px dashed'}
                                borderRadius={'25px'}
                                borderColor={'primaryBlue.100'}
                                py={'40px'}
                            >
                                {selectedImage ? (
                                <>
                                    <img src={selectedImage} alt="Uploaded Profile" style={{ maxWidth: '100%', maxHeight: '200px' }} />
                                    <Button
                                    onClick={handleImageChange}
                                    fontFamily="Poppins"
                                    bgGradient="linear(to-b, blue.500, blue.600)"
                                    px={14}
                                    mt={4}
                                    _hover={{
                                        bg: 'blue.500',
                                        color: '#fff'
                                    }}
                                    >
                                    Change Image
                                    </Button>
                                </>
                                ) : (
                                <>
                                    <Text
                                        fontFamily={'Poppins'}
                                        color={"primaryBlue.100"}
                                        fontWeight={'600'}
                                        fontSize={{base: '18', lg: '22'}}
                                    >Drag and Drop your file here</Text>
                                    <Text
                                        fontFamily={'Poppins'}
                                        color={"primaryBlue.100"}
                                        fontWeight={'600'}
                                        fontSize={'18'}
                                        py={2}
                                    >or</Text>
                                    <Button
                                        onClick={() => pictureRef.current.click()}
                                        fontFamily={"Poppins"}
                                        bgGradient='linear(to-b, primaryBlue.100, primaryBlue.200)'
                                        px={14}
                                        _hover={{
                                            bg: 'primaryBlue.100',
                                            color: '#fff'
                                        }}
                                    >Browse Files</Button>
                                </>
                                )}
                            </Box>
                            {validation && validation.data && validation.data.image && validation.data.image[0] && (
                                <Text color='red'>{validation.data.image[0]}</Text>
                            )}
                        </Box>
                        <Box
                            w={'100%'}
                            mb={'20px'}
                        >
                            <Button
                                type='submit'
                                fontFamily={"Poppins"}
                                bgGradient='linear(to-b, primaryBlue.100, primaryBlue.200)'
                                _hover={{
                                    bg: 'primaryBlue.100',
                                    color: '#fff'
                                }}
                                w={'full'}
                                borderRadius={8}
                                py={6}
                                isDisabled={isLoading} // Disable the button when loading
                            >
                                {isLoading ? <Spinner thickness='4px' speed='0.65s' emptyColor='primaryBlue.200' color='white' size='lg' /> : "Update Profile"}
                            </Button>
                        </Box>
                    </Stack>
                </form>
            </Stack>
            <Image src={Image1} position={'absolute'} top={0} right={0} zIndex={'-1'} />
            <Image src={Image2} position={'absolute'} bottom={0} left={0} zIndex={'-1'} />
        </Container>
    );
}