import { Box, Container, Heading, Image, Stack, Link, Text, Spinner, Button } from "@chakra-ui/react";
import SearchNftBox from "../components/Home/SearchNftBox";
import EnvironmentFeedBox from "../components/Home/EnvironmentFeedBox";
import ShopPic from '../assets/images/shop.png';
import NoRecordFound from '../assets/images/no-record-found.webp';
import ProfileMain from '../assets/images/profileMain.png';
import { Link as ReactLink, useLocation } from 'react-router-dom';
import { useEffect, useState } from "react";
import { searchEnvironment, searchNft, searchUser } from "../utilities/ApiProvider";
const BASE_URL = process.env.REACT_APP_BASE_URL;

export default function SearchUser() {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const name = searchParams.get("name");
    const [userData, setUserData] = useState([]);
    const [environments, setEnvironments] = useState([]);
    const [nfts, setNfts] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [selectedBtn, setSelectedBtn] = useState('People');

    useEffect(() => {
        if (name) {
          // Only make the API request if the "name" parameter is present
          if (selectedBtn === 'People') {
            searchUser(name)
                .then(responseData => {
                    setUserData(responseData.data.records);
                    setIsLoading(false);
                }).catch(error => {
                    setUserData([]);
                    console.log(error);
                    setIsLoading(false);
                });
          } else if (selectedBtn === 'Environment') {
            searchEnvironment(name)
                .then(responseData => {
                    setEnvironments(responseData.data.records);
                    setIsLoading(false);
                }).catch(error => {
                    setEnvironments([]);
                    console.log(error);
                    setIsLoading(false);
                });
          } else if (selectedBtn === 'Nfts') {
            searchNft(name)
                .then(responseData => {
                    setNfts(responseData.data.records);
                    setIsLoading(false);
                }).catch(error => {
                    setNfts([]);
                    console.log(error);
                    setIsLoading(false);
                });
          }
        }
    }, [name, selectedBtn]); // Include "name" in the dependency array

    return (
        <Stack
            pb={12}
        >
            <Container maxW={'8xl'}>
                <Stack
                    flexWrap={{ base: 'warp', lg: 'no-wrap' }}
                    direction={'row'}
                    justifyContent={'space-between'}
                    gap={6}
                    w={'full'}
                >
                <Stack
                    boxShadow={'0px 0px 25px -14px #000'}
                    borderRadius={12}
                    w={'full'}
                    px={'15px'}
                    py={'20px'}
                >
                    <Stack
                        direction={{base: 'column', lg: 'row'}}
                        justifyContent={'space-between'}
                        alignItems={'center'}
                        mb={'25px'}
                        pb={'25px'}
                        borderBottom={'2px solid #adadad'}
                    >
                        <Heading fontSize={{base: 28, lg: 38}} fontFamily={'Poppins'}>Search People</Heading>
                        <Box
                            display={'flex'}
                            alignItems={'center'}
                            gap={2}
                            fontFamily={'Poppins'}
                            flexWrap={{base: 'wrap', lg: 'no-wrap'}}
                        >
                            <Button bgGradient={selectedBtn === 'People' ? 'linear(to-b, primaryBlue.100, primaryBlue.200)' : 'transparent'} color={selectedBtn === 'People' ? '#fff' : '#c2c2c2'} _hover={{ bgColor: 'transparent' }} _focus={{ bgColor: '#573cc2' }} onClick={() => setSelectedBtn('People')}>People</Button>
                            <Button bgGradient={selectedBtn === 'Environment' ? 'linear(to-b, primaryBlue.100, primaryBlue.200)' : 'transparent'} color={selectedBtn === 'Environment' ? '#fff' : '#c2c2c2'} _hover={{ bgColor: 'transparent' }} _focus={{ bgColor: '#573cc2' }} onClick={() => setSelectedBtn('Environment')}>Environments</Button>
                            <Button bgGradient={selectedBtn === 'Nfts' ? 'linear(to-b, primaryBlue.100, primaryBlue.200)' : 'transparent'} color={selectedBtn === 'Nfts' ? '#fff' : '#c2c2c2'} _hover={{ bgColor: 'transparent' }} _focus={{ bgColor: '#573cc2' }} onClick={() => setSelectedBtn('Nfts')}>NFTs</Button>
                        </Box>
                    </Stack>
                    <Stack spacing={0} direction={selectedBtn === 'Environment' ? 'column' : 'row'} alignItems={selectedBtn === 'Environment' ? '' : 'center'} gap={6} flexWrap={'wrap'}>
                        {isLoading ? (
                            // Display a loading spinner when isLoading is true
                            <Spinner size="lg" color="blue.500" />
                        ) : selectedBtn === 'People' && userData.length > 0 ? (
                                userData.map(user => (
                                    <Stack
                                        key={user.id}
                                        boxShadow={'0px 0px 25px -14px #000'}
                                        borderRadius={12}
                                        w={{ base: '100%', lg: '15.2%' }}
                                        px={'15px'}
                                        py={'20px'}
                                    >
                                        <Link
                                            as={ReactLink}
                                            to={`/profile/${user.id}`}
                                            _hover={{
                                                textDecoration: 'none'
                                            }}
                                        >
                                            <Image src={BASE_URL + user.image} />
                                            <Stack
                                                direction={'row'}
                                                alignItems={'center'}
                                                justifyContent={'space-between'}
                                                pt={'8px'}
                                                pb={'15px'}
                                            >
                                                <Heading fontSize={16} fontFamily={'Poppins'}>
                                                    {user.name}
                                                </Heading>
                                                {/* Add additional user data as needed */}
                                            </Stack>
                                        </Link>
                                    </Stack>
                                ))
                        ) : selectedBtn === 'Environment' ? (
                                environments && environments.length > 0 ? (
                                    <>
                                    {environments.map((v, i) => (
                                        <EnvironmentFeedBox key={i} {...v} />
                                    ))}
                                    </>
                                ) : (
                                    <Stack
                                        justifyContent="center"
                                        alignItems="center"
                                        w="100%" // Set the width to 100% to center horizontally
                                        h="50vh" // Set the height to 100vh to center vertically
                                    >
                                        <Box textAlign="center" p={4} color="gray.500">
                                            <Image src={NoRecordFound} w="200px" />
                                        </Box>
                                        <Box textAlign="center" p={4} color="gray.500">
                                            <Heading fontSize="xl" mb={2}>
                                                Oops, No Results Found!
                                            </Heading>
                                            <Text>
                                                Unfortunately, we couldn't find any results for your search. Please try a different query.
                                            </Text>
                                        </Box>
                                    </Stack>
                                )
                        ) : selectedBtn === 'Nfts' ? (
                            <>
                                { nfts.length > 0 ? (
                                    nfts.length &&
                                    nfts.map((v, i) => <SearchNftBox key={i} {...v} />)
                                ) : (
                                    <Stack
                                        justifyContent="center"
                                        alignItems="center"
                                        w="100%" // Set the width to 100% to center horizontally
                                        h="50vh" // Set the height to 100vh to center vertically
                                    >
                                        <Box textAlign="center" p={4} color="gray.500">
                                            <Image src={NoRecordFound} w="200px" />
                                        </Box>
                                        <Box textAlign="center" p={4} color="gray.500">
                                            <Heading fontSize="xl" mb={2}>
                                                Oops, No Results Found!
                                            </Heading>
                                            <Text>
                                                Unfortunately, we couldn't find any results for your search. Please try a different query.
                                            </Text>
                                        </Box>
                                    </Stack>
                                )}
                            </>
                        ) : (
                            <Stack
                                justifyContent="center"
                                alignItems="center"
                                w="100%" // Set the width to 100% to center horizontally
                                h="50vh" // Set the height to 100vh to center vertically
                            >
                                <Box textAlign="center" p={4} color="gray.500">
                                    <Image src={NoRecordFound} w="200px" />
                                </Box>
                                <Box textAlign="center" p={4} color="gray.500">
                                    <Heading fontSize="xl" mb={2}>
                                        Oops, No Results Found!
                                    </Heading>
                                    <Text>
                                        Unfortunately, we couldn't find any results for your search. Please try a different query.
                                    </Text>
                                </Box>
                            </Stack>
                        )}
                    </Stack>
                </Stack>
                </Stack>
            </Container>
        </Stack>
    );
}