import { Stack, Image, Heading, Text } from '@chakra-ui/react';
const BASE_URL = process.env.REACT_APP_BASE_URL;

export default function SearchNftBox({ id, title, slug, price, image, target_link, description, }) {
    
    const sanitizeHTML = (html) => {
        const tempElement = document.createElement('div');
        tempElement.innerHTML = html;
        return tempElement.textContent || tempElement.innerHTML || '';
    };

    return (
        <Stack
            boxShadow={'0px 0px 25px -14px #000'}
            borderRadius={12}
            w={{base: '100%', lg: '32%'}}
            px={'15px'}
            py={'20px'}
        >
            <Image src={BASE_URL + image} />
            <Stack
                direction={'row'}
                alignItems={'center'}
                justifyContent={'space-between'}
                pt={'8px'}
                pb={'15px'}
            >
                <Heading fontSize={22} fontFamily={'Poppins'} >{title}</Heading>
                <Text fontFamily={'Poppins'} color={'primaryBlue.100'} fontWeight={'600'}>{price}</Text>
            </Stack>
            <Text fontSize={'16'} fontFamily={'Poppins'}>{sanitizeHTML(description)}</Text>
        </Stack>
    )
}