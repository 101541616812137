import { Stack, Textarea, Box, FormLabel, Input, Text, Button, useToast, Progress } from '@chakra-ui/react';
import { useRef, useState } from 'react';
import axios from 'axios';
import CustomFile from '../Customs/CustomFile.js';
const BASE_URL = process.env.REACT_APP_BASE_URL;

export default function UploadEnvForm() {

    const [selectedFiles, setSelectedFiles] = useState([]);
    const [formData, setFormData] = useState({
        name: '',
        description: '',
    });
    const [uploadProgress, setUploadProgress] = useState(0);
    const [isUploading, setIsUploading] = useState(false);
    const toast = useToast();

    const handleFileChange = (event) => {
        setSelectedFiles(Array.from(event.target.files));
    };

    const handleInputChange = (event) => {
        setFormData({
        ...formData,
        [event.target.name]: event.target.value,
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const uploadData = new FormData();
        selectedFiles.forEach((file) => {
            uploadData.append('modelFile', file);
        });
        uploadData.append('name', formData.name);
        uploadData.append('description', formData.description);

        setIsUploading(true); // Start the upload process

        try {
            const response = await axios.post('https://api.sketchfab.com/v3/models', uploadData, {
                headers: {
                    Authorization: 'Token 15919ce211c3f8aaa8e40fe0ff9742f9', // Replace with your Sketchfab API key
                    'Content-Type': 'multipart/form-data',
                },
                onUploadProgress: (progressEvent) => {
                    const progress = Math.round((progressEvent.loaded / progressEvent.total) * 100);
                    setUploadProgress(progress);
                },
            });
            const { uid } = response.data;
            // console.log('After Success Get UID ', uid);
            toast({
                title: 'Success',
                description: 'Files uploaded successfully!',
                status: 'success',
                duration: 2000,
                isClosable: true,
            });
            getModelData(uid);
            setIsUploading(false); // End the upload process
            setUploadProgress(0); // Reset the progress bar
        } catch (error) {
            // console.error('Error', error);
            toast({
                title: 'Upload Failed',
                description: 'Failed to upload files.',
                status: 'error',
                duration: 3000,
                isClosable: true,
            });
            setIsUploading(false); // End the upload process
            setUploadProgress(0); // Reset the progress bar
        }
    };

    const saveToDatabase = async (data) => {
        try {
            const authenticatedUserData = JSON.parse(localStorage.getItem('authenticatedUserData'));
            const headers = {
                Authorization: `${authenticatedUserData?.token}`,
                'Content-Type': 'application/json',
            };

            // Make your API call to save the data to your database
            await axios.post(`${BASE_URL}api/v1/environments/addEnvironment`, {
                name: data.name,
                description: data.description,
                sketchfab_uid: data.uid,
                embedUrl: data.embedUrl,
            }, { headers });
            // Clear the input fields
            setFormData({
                name: '',
                description: '',
            });
            setSelectedFiles([]);
            // console.log('Data saved to database:', data);
        } catch (error) {
            // console.error('Failed to save data to database:', error);
        }
    };

    const getModelData = async (uid) => {
        try {
            const response = await axios.get(`https://api.sketchfab.com/v3/models/${uid}`, {
                headers: {
                Authorization: 'Token 15919ce211c3f8aaa8e40fe0ff9742f9', // Replace with your Sketchfab API key
                },
            });
            // console.log(response.data);
            saveToDatabase(response.data); // Save the data to your database
        } catch (error) {
            // console.error(error);
        }
    };


    const formLabelCss = {
        color: '#000',
        fontFamily: 'Poppins',
        fontSize: '16px',
        ml: '10px',
    }

    const inputCss = {
        px: 3,
        py: 6,
        bgColor: '#f3f3f3',
        borderRadius: '50px',
        placeholder: 'John Doe',
        border: 0,
    }

    const areaCss = {
        px: 3,
        py: 6,
        bgColor: '#f3f3f3',
        borderRadius: '25px',
        placeholder: 'John Doe',
        border: 0,
    }

    const envFilesRef = useRef(null);
    const landThumbnailRef = useRef(null);

    return (
        <form onSubmit={(e) => handleSubmit(e)}>
            <Stack>
                <Box mb={'20px !important'}>
                    <FormLabel sx={formLabelCss}>Environment Name</FormLabel>
                    <Input
                        sx={inputCss}
                        _placeholder={{ fontWeight: '600' }}
                        _focusVisible={{ outline: 'none' }}
                        type='text'
                        name="name" 
                        value={formData.name} 
                        onChange={handleInputChange}
                    />
                </Box>
                <Box mb={'20px !important'}>
                    <FormLabel sx={formLabelCss}>Description</FormLabel>
                    <Textarea
                        sx={areaCss}
                        resize={'none'}
                        height={'140px'}
                        _placeholder={{ fontWeight: '600' }}
                        _focusVisible={{ outline: 'none' }}
                        name="description"
                        value={formData.description}
                        onChange={handleInputChange}
                    >

                    </Textarea>
                </Box>
                <Box
                    w={'100%'}
                    mb={'40px !important'}
                >
                    <FormLabel sx={formLabelCss}>Upload Environment Files</FormLabel>
                    <Input
                        ref={envFilesRef}
                        display={'none'}
                        type="file" 
                        multiple 
                        onChange={handleFileChange}
                    />
                    <Box
                        display={'flex'}
                        justifyContent={'center'}
                        alignItems={'center'}
                        flexDir={'column'}
                        bgColor={'#f3f3f3'}
                        border={'4px dashed'}
                        borderRadius={'25px'}
                        borderColor={'primaryBlue.100'}
                        py={'40px'}
                    >
                        <Text
                            fontFamily={'Poppins'}
                            color={"primaryBlue.100"}
                            fontWeight={'600'}
                            fontSize={{base: '18', lg: '22'}}
                        >Drag and Drop your files here</Text>
                        <Text
                            fontFamily={'Poppins'}
                            color={"primaryBlue.100"}
                            fontWeight={'600'}
                            fontSize={'18'}
                            py={2}
                        >or</Text>
                        <Button
                            onClick={() => envFilesRef.current.click()}
                            fontFamily={"Poppins"}
                            bgGradient='linear(to-b, primaryBlue.100, primaryBlue.200)'
                            px={14}
                            _hover={{
                                bg: 'primaryBlue.100',
                                color: '#fff'
                            }}
                        >Browse Files</Button>
                    </Box>

                    <Text fontFamily={'Poppins'} fontSize={'13'} py={2}>
                        We support{' '}
                        <strong>
                        FBX, OBJ, BLEND
                        </strong>
                        , and many others, make sure to upload textures and materials{' '}
                        <strong>
                        PNG or JPG
                        </strong>
                        {' '}files as well. You can also upload an archive like{' '}
                        <strong>
                        ZIP, RAR, 7z
                        </strong>
                        , containing your textures, materials, and mesh.
                    </Text>
                    
                    {selectedFiles.length > 0 && (
                        <Stack
                            direction={'row'}
                            flexWrap={'wrap'}
                            justifyContent={'space-between'}
                            spacing={0}
                            mt={10}
                        >
                            {selectedFiles.map((file, index) => (
                                <CustomFile key={index} name={file.name} />
                            ))}
                        </Stack>
                    )}

                </Box>

                {isUploading && (
                    <Box mb={'20px !important'}>
                        <Progress value={uploadProgress} size="lg" colorScheme="blue" />
                        <Box textAlign="center" mt={2}>
                            {uploadProgress}% uploaded
                        </Box>
                    </Box>
                )}

                <Box
                    w={'100%'}
                    mb={'20px'}
                >
                    <Button
                        type="submit"
                        fontFamily={"Poppins"}
                        bgGradient='linear(to-b, primaryBlue.100, primaryBlue.200)'
                        _hover={{
                            bg: 'primaryBlue.100',
                            color: '#fff'
                        }}
                        w={'full'}
                        borderRadius={8}
                        py={6}
                        disabled={isUploading}
                    >
                        {isUploading ? 'Uploading...' : 'Upload Environment'}
                    </Button>
                </Box>
            </Stack>
        </form>
    )
}
