import React, { createContext } from 'react';
import { BrowserRouter as Router, Route, useNavigate } from 'react-router-dom';
import RouteSwitch from './RouteSwitch';
import NotFound from '../views/NotFound';
import Home from '../views/Home';
import Header from '../components/Header';
import Footer from '../components/Footer';
import SignIn from '../views/SignIn';
import SignUp from '../views/SignUp';
import VerifyOtp from '../views/VerifyOtp';
import { Stack } from '@chakra-ui/react';
import UploadEnv from '../views/UploadEnv';
import SingleLand from '../views/SingleLand';
import Shop from '../views/Shop';
import LandRegistration from '../views/LandRegistration';
import Profile from '../views/Profile';
import OtherUserProfile from '../views/OtherUserProfile';
import EditProfile from '../views/EditProfile';
import ChangePassword from '../views/ChangePassword';
import ForgetPassword from '../views/ForgetPassword';
import Explore from '../views/Explore';
import EditEnv from '../views/EditEnv';
import ShareEnv from '../views/ShareEnv';
import EditLand from '../views/EditLand';
import SearchUser from '../views/SearchUser';
import PrivacyPolicy from '../views/PrivacyPolicy';
import TermsCondition from '../views/TermsCondition';
import Success from '../views/Success';
import Cancel from '../views/Cancel';

export const UserContext = createContext();

export default function AppRoute() {

  const authenticatedUserData = JSON.parse(localStorage.getItem('authenticatedUserData'));

  return (
    <div>
      <Router>
        <Header />
        <Stack marginTop={{ base: '40px', lg: '140px' }}>
          <RouteSwitch>
            <Route exact path="/" element={<Home />} />
            <Route exact path="/environment/:id" element={<Home />} /> 
            <Route exact path="/shop" element={<Shop />} />
            <Route exact path="/explore" element={<Explore />} />
            <Route exact path="/sign-in" element={<SignIn />} />
            <Route exact path="/sign-up" element={<SignUp />} />
            <Route exact path="/verify-otp" element={<VerifyOtp />} />
            <Route exact path="/forget-password" element={<ForgetPassword />} />
            <Route exact path="/profile/:id" element={<OtherUserProfile />} />
            <Route exact path="/search" element={<SearchUser />} />
            <Route exact path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route exact path="/terms-and-conditions" element={<TermsCondition />} />
            <Route exact path="/success" element={<Success />} />
            <Route exact path="/cancel" element={<Cancel />} />

            {authenticatedUserData === null ? (
              <Route exact path="*" element={<SignIn />} />
            ) : (
              <>
                <Route exact path="/profile" element={<Profile />} />
                <Route exact path="/edit-profile" element={<EditProfile />} />
                <Route exact path="/change-password" element={<ChangePassword />} />
                <Route exact path="/upload-environment" element={<UploadEnv />} />
                <Route exact path="/environment/edit/:id" element={<EditEnv />} />
                <Route exact path="/environment/share/:id" element={<ShareEnv />} />
                <Route exact path="/single-land" element={<SingleLand />} />
                <Route exact path="/land-registration" element={<LandRegistration />} />
                <Route exact path="/land/edit/:id" element={<EditLand />} />
              </>
            )}

            <Route exact path="*" element={<NotFound />} />
          </RouteSwitch>
        </Stack>
        <Footer />
      </Router>
    </div>
  );
}
