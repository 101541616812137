import { Box, Container, Heading, Image, Stack, Text, Link } from "@chakra-ui/react";
import MyLand from "../components/Home/MyLand";
import Collections from "../components/Home/Collections";
import Gimage from '../assets/images/g.png'
import { Link as ReactLink } from "react-router-dom";
import DiscoverNft from "../components/Home/DiscoverNft";
import LandRegForm from '../components/Land/LandRegForm'

export default function LandRegistration() {

    return (
        <Stack
            pb={12}
        >
            <Container maxW={'8xl'}>
                <Stack
                    flexWrap={{ base: 'warp', lg: 'no-wrap' }}
                    direction={'row'}
                    justifyContent={'space-between'}
                    gap={6}
                >
                    <Stack
                        flex={1}
                        display={{base: 'none', lg: 'initial'}}
                        gap={6}
                    >
                        {/* My Land Component */}
                        <MyLand />
                        {/* My Collection Component */}
                        <Collections />
                    </Stack>
                    <Stack
                        flex={2}
                    >
                        {/* Upload Environment Component */}
                        <Stack
                            boxShadow={'0px 0px 25px -14px #000'}
                            borderRadius={12}
                            px={'15px'}
                            py={'20px'}
                        >
                            <Heading fontSize={24} fontFamily={'Poppins'} mb={'35px'}>Register Land</Heading>
                            <LandRegForm />
                        </Stack>
                    </Stack>
                    <Stack
                        flex={1}
                        gap={6}
                        display={{base: 'none', lg: 'initial'}}
                    >
                        {/* Coins Component */}
                        <Box
                            boxShadow={'0px 0px 25px -14px #000'}
                            borderRadius={12}
                            padding={6}
                            display={'flex'}
                            alignItems={'center'}
                            justifyContent={'space-between'}
                            position={'relative'}
                            mb={'10px'}
                        >
                            <Image src={Gimage} alt="gallery land logo" />
                            <Box>
                                <Text fontFamily={'Poppins'} fontSize={'18px'} fontWeight={'600'}>Coins</Text>
                                <Heading color={'#573cc2'} fontFamily={'Poppins'} fontStyle={'italic'} fontSize={'36px'}>3845550m</Heading>
                                <Link _hover={{ textDecoration: 'none' }} to={'/'} as={ReactLink} fontSize={'18px'} color={'#573cc2'} fontWeight={'600'}>Buy More</Link>
                            </Box>
                        </Box>
                        {/* Discover NFTs component */}
                        <DiscoverNft />
                    </Stack>
                </Stack>
            </Container>
        </Stack>
    );
}