import { Box, Button, Heading, Icon, Image, MenuButton, Stack, Text, Menu, MenuList, MenuItem, Link } from '@chakra-ui/react';
import { BsThreeDots } from 'react-icons/bs';
import { Link as ReactLink } from 'react-router-dom';
import Land from '../../assets/images/loc.png';
import { useState, useEffect } from 'react';

export default function LandBox({ id, name, slug, location, latitude, longitude, full_address, area, created_at, user, onDelete }) {

    const authenticatedUserData = JSON.parse(localStorage.getItem('authenticatedUserData'));
    const isUserLand = authenticatedUserData && authenticatedUserData.id === user.id;
    const imageUrl = user ? user.image : null;
    const BASE_URL = process.env.REACT_APP_BASE_URL; // Replace with your base URL
    const profileImage = imageUrl ? `${BASE_URL}${imageUrl}` : null;
    const [isDeleted, setIsDeleted] = useState(false); // Add isDeleted state

    const handleDeleteLand = () => {
        // Call the onDelete callback from the parent component
        onDelete();
        // Set isDeleted to true to visually cue the deletion
        setIsDeleted(true);

        // Reset isDeleted to false after a certain delay (e.g., 600 ms)
        setTimeout(() => {
            setIsDeleted(false);
        }, 600); // Adjust the delay as needed (600 ms)
    }

    // useEffect to reset isDeleted when the component unmounts
    useEffect(() => {
        return () => {
            setIsDeleted(false);
        };
    }, []);

    return (
        <Stack
            marginBottom={'30px !important'}
            borderRadius={'12px'}
            px={{ base: '10px', lg: '25px' }}
            py={'20px'}
            border={'1px solid #e1e1e1'}
            transition="opacity 0.3s ease-in-out" // Add a CSS transition for opacity
            opacity={isDeleted ? 0 : 1} // Set opacity to 0 when isDeleted is true
        >
            <Stack
                direction={'row'}
                alignItems={'flex-start'}
                justifyContent={'space-between'}
            >
                <Link
                    as={ReactLink}
                    to={`/profile/${user.id}`}
                    _hover={{
                        textDecoration: 'none'
                    }}
                >
                    <Box
                        display={'flex'}
                        alignItems={'center'}
                        gap={4}
                        mb={'15px'}
                    >
                        <Image src={profileImage} alt={user?.name} border={'4px solid'} borderColor={'primaryBlue.100'} borderRadius={'100%'} width="52px" height="52px" />
                        <Box>
                            <Heading fontFamily={'Poppins'} fontSize={'24px'} fontWeight={'700'}>{user?.name}</Heading>
                            <Text fontFamily={'Poppins'} fontSize={'14px'}>{created_at}</Text>
                        </Box>
                    </Box>
                </Link>
                <>
                    {isUserLand && (
                        <Menu>
                            <Button
                                as={MenuButton}
                                bgColor={'transparent'}
                                _hover={{ bgColor: 'transparent' }}
                                _focus={{ bgColor: 'transparent' }}
                            >
                                <Icon as={BsThreeDots} fontSize={28} />
                            </Button>
                            <MenuList>
                                <Link 
                                    as={ReactLink}
                                    to={`/land/edit/${id}`}
                                    _hover={{
                                        textDecoration: 'none'
                                    }}
                                >
                                    <MenuItem>Edit</MenuItem>
                                </Link>
                                <MenuItem onClick={handleDeleteLand}>Delete</MenuItem>
                            </MenuList>
                        </Menu>
                    )}
                </>
            </Stack>
            <Heading fontFamily={'Poppins'} fontSize={'18px'} fontWeight={'700'}>{name}</Heading>
            <Text fontFamily={'Poppins'} fontSize={{ base: '14px', lg: '18px' }} pb={3}>{full_address}</Text>
            <Image src={Land} />
        </Stack>
    )
}
