import { Stack, Image, Heading, Text, Box } from '@chakra-ui/react'

export default function ExploreCard({ nftPic, name, caption, userPic, time }) {
    return (
        <Stack
            boxShadow={'0px 0px 25px -14px #000'}
            borderRadius={12}
            w={{base: '100%', lg: '32%'}}
            px={'15px'}
            py={'20px'}
        >
            <Stack
                direction={'row'}
                alignItems={'center'}
                pb={'10px'}
            >
                <Image src={userPic} w={'60px'} />
                <Box>
                    <Heading fontSize={22} fontFamily={'Poppins'} >{name}</Heading>
                    <Text>{time}</Text>
                </Box>
            </Stack>
            <Image src={nftPic} />
            <Stack
                direction={'row'}
                alignItems={'center'}
                justifyContent={'space-between'}
                pt={'8px'}
                pb={'0px'}
            >
            </Stack>
            <Text fontSize={'16'} fontFamily={'Poppins'}>{caption}</Text>
        </Stack>
    )
}