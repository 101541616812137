import { Box, Container, Heading, Image, Stack, Text, Link, Button, Spinner, Skeleton, SimpleGrid } from "@chakra-ui/react";
import { useState, useEffect } from "react";
import ShopPic from '../assets/images/shop.png';
import ShopCard from '../components/Shop/ShopCard.js';
import { getNfts, getCheckout } from "../utilities/ApiProvider";
import NoRecordFound from '../assets/images/no-record-found.webp';

export default function Shop() {

    const [nfts, setNfts] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [selectedBtn, setSelectedBtn] = useState('All');

    useEffect(() => {
        getNfts()
            .then(responseData => {
                setNfts(responseData.data.records);
                // Simulate a delay to demonstrate Skeleton loading
                setTimeout(() => {
                    setIsLoading(false);
                }, 600); // Adjust the delay time as needed
            }).catch(error => {
                console.log(error);
                setIsLoading(false);
            });
    }, []);

    const handleCheckout = (nftId) => {
        // Call the getCheckout API or perform the deletion logic here
        getCheckout(nftId)
            .then(responseData => {
                // Check if responseData contains payment_url
                if (responseData.data.payment_url) {
                    // Open a new tab or window with the payment_url
                    window.open(responseData.data.payment_url, '_blank');
                } else {
                    console.log("Payment URL not found in response data.");
                    // Handle the case where payment_url is not present in the response.
                }
            }).catch(error => {
                console.log(error);
                // Check if the error indicates that the user is not authenticated
                if (error.response && error.response.status === 401) {
                    // Redirect to the sign-in page
                    window.history.pushState({}, '', 'sign-in');
                    // Trigger a popstate event to notify React Router about the URL change
                    const event = new Event('popstate');
                    window.dispatchEvent(event);
                }
            });
    }

    return (
        <Stack
            pb={12}
        >
            <Container maxW={'8xl'}>
                <Stack
                    flexWrap={{ base: 'warp', lg: 'no-wrap' }}
                    direction={'row'}
                    justifyContent={'space-between'}
                    gap={6}
                    w={'full'}
                >
                    <Stack
                        boxShadow={'0px 0px 25px -14px #000'}
                        borderRadius={12}
                        w={'full'}
                        px={'15px'}
                        py={'20px'}
                    >
                        <Stack
                            direction={{base: 'column', lg: 'row'}}
                            justifyContent={'space-between'}
                            alignItems={'center'}
                            mb={'25px'}
                            pb={'25px'}
                            borderBottom={'2px solid #adadad'}
                        >
                            <Heading fontSize={{base: 28, lg: 38}} fontFamily={'Poppins'}>Gallery Land NFTs</Heading>
                            {/* <Box
                                display={'flex'}
                                alignItems={'center'}
                                gap={2}
                                fontFamily={'Poppins'}
                                flexWrap={{base: 'wrap', lg: 'no-wrap'}}
                            >
                                <Button bgGradient={selectedBtn === 'All' ? 'linear(to-b, primaryBlue.100, primaryBlue.200)' : 'transparent'} color={selectedBtn === 'All' ? '#fff' : '#c2c2c2'} _hover={{ bgColor: 'transparent' }} _focus={{ bgColor: '#573cc2' }} onClick={() => setSelectedBtn('All')}>All</Button>
                                <Button bgGradient={selectedBtn === 'Lands' ? 'linear(to-b, primaryBlue.100, primaryBlue.200)' : 'transparent'} color={selectedBtn === 'Lands' ? '#fff' : '#c2c2c2'} _hover={{ bgColor: 'transparent' }} _focus={{ bgColor: '#573cc2' }} onClick={() => setSelectedBtn('Lands')}>Lands</Button>
                                <Button bgGradient={selectedBtn === 'Environment' ? 'linear(to-b, primaryBlue.100, primaryBlue.200)' : 'transparent'} color={selectedBtn === 'Environment' ? '#fff' : '#c2c2c2'} _hover={{ bgColor: 'transparent' }} _focus={{ bgColor: '#573cc2' }} onClick={() => setSelectedBtn('Environment')}>Environment</Button>
                                <Button bgGradient={selectedBtn === 'NFT' ? 'linear(to-b, primaryBlue.100, primaryBlue.200)' : 'transparent'} color={selectedBtn === 'NFT' ? '#fff' : '#c2c2c2'} _hover={{ bgColor: 'transparent' }} _focus={{ bgColor: '#573cc2' }} onClick={() => setSelectedBtn('NFT')}>NFT</Button>
                            </Box> */}
                        </Stack>
                        {isLoading ? ( // Check if loading is true
                            <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} spacing={4}>
                                {nfts.map((nft, index) => (
                                    <Box key={index}>
                                        <Stack
                                            borderWidth="1px"
                                            borderRadius="lg"
                                            overflow="hidden"
                                            boxShadow="md"
                                            p={4}
                                        >
                                            {/* Skeleton for image */}
                                            <Skeleton height="400px" width="100%" mb={2} />
                                            <Stack direction="row" justify="space-between" align="center">
                                                {/* Skeleton for title */}
                                                <Skeleton height="20px" width="70%" mb={2} />
                                                {/* Skeleton for price */}
                                                <Skeleton height="20px" width="30%" mb={2} />
                                            </Stack>
                                            {/* Skeleton for description */}
                                            <Skeleton height="40px" width="100%" mb={2} />
                                        </Stack>
                                    </Box>
                                ))}
                            </SimpleGrid>
                        ) : nfts && nfts.length > 0 ? (
                            <Stack
                                spacing={0}
                                direction={'row'}
                                alignItems={'center'}
                                gap={6}
                                flexWrap={'wrap'}
                            >
                                {
                                    nfts.length &&
                                    nfts.map((v, i) => <ShopCard key={i} {...v} onCheckout={() => handleCheckout(v.id) } />)
                                }
                            </Stack>
                        ) : (
                            <Stack
                                justifyContent="center"
                                alignItems="center"
                                w="100%" // Set the width to 100% to center horizontally
                                h="50vh" // Set the height to 100vh to center vertically
                            >
                                <Box textAlign="center" p={4} color="gray.500">
                                    <Image src={NoRecordFound} w="200px" />
                                </Box>
                                <Box textAlign="center" p={4} color="gray.500">
                                    <Heading fontSize="xl" mb={2}>
                                        Oops, No Records Found!
                                    </Heading>
                                </Box>
                            </Stack>
                        )}
                    </Stack>
                </Stack>
            </Container>
        </Stack>
    );
}