import {
  Box,
  Text,
  Stack,
  Button,
  Flex,
} from '@chakra-ui/react';
import { ArrowBackIcon } from '@chakra-ui/icons'
import { Link } from 'react-router-dom';
import '../assets/css/Main.css'

export default function Cancel() {
  return (
    <>
      <Box textAlign="center" fontSize="xl">
        <Flex minH="60vh" p={3} alignItems='center' justifyContent='center'>
          <Stack spacing={8} justifyContent='center'>
            <Text fontSize={50} fontWeight={800} className='bgText'>Order Canceled</Text>
            <Text fontSize={16}>Your order could not be completed. <br /> If you have any questions or need further assistance, please contact our support team.</Text>
            <Box textAlign='center'>
              <Link to='/'>
                <Button leftIcon={<ArrowBackIcon />} variant='outline' colorScheme='teal' w='140px' h='45px' >
                  Go Back
                </Button>
              </Link>
            </Box>
          </Stack>
        </Flex>
      </Box>
    </>
  );
}