import {
    Stack,
    Container,
    FormControl,
    FormLabel,
    Input,
    Button,
    Heading,
    Box,
    Text,
    Image,
    useToast,
    Link,
    Spinner
} from "@chakra-ui/react";
import { useRef, useState } from 'react';
import Image1 from '../assets/images/1.png';
import Image2 from '../assets/images/2.png';
import { Link as ReactLink } from 'react-router-dom';
import { verifyOtp, resendOTP } from "../utilities/ApiProvider";

export default function VerifyOtp() {

    const toast = useToast();
    const [validation, setValidation] = useState(null);
    const [isLoading, setIsLoading] = useState(false); // Initialize loading state
    const [formData, setFormData] = useState({
        otp: ''
    });

    const handleInputChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    const handleFormSubmit = (event, data) => {
        event.preventDefault();

        setIsLoading(true);
        
        verifyOtp(data)
            .then(responseData => {
                setValidation(responseData); // Set the data in validation
                toast({
                    title: 'Success',
                    description: responseData.message,
                    status: 'success',
                    duration: 1000,
                    isClosable: true,
                    onCloseComplete: () => {
                        localStorage.setItem('authenticatedUserData', JSON.stringify(responseData.data)); // Set the data in localStorage
                        window.location.href = "/"; // Redirect to the "Home" screen upon successfully verify otp
                    },
                });
                setIsLoading(false);
            }).catch(error => {
                setValidation(error.response.data);
                toast({
                    title: 'Error',
                    description: error.response.data.message,
                    status: 'error',
                    duration: 2000,
                    isClosable: true,
                });
                setIsLoading(false);
            });
    }

    const ResendOTP = () => {
        resendOTP()
            .then(responseData => {
                setValidation(responseData); // Set the date in validation
            }).catch(error => {
                setValidation(error.response.data);
            });
    }

    const formLabelCss = {
        color: '#000',
        fontFamily: 'Poppins',
        fontSize: '16px',
        ml: '10px',
    }

    const inputCss = {
        px: 3,
        py: 6,
        bgColor: '#f3f3f3',
        borderRadius: '50px',
        placeholder: 'John Doe',
        border: 0,
    }

    return (
        <Container maxW={'6xl'}>
            <Stack
                boxShadow={'0px 0px 25px -14px #000'}
                px={'30px'}
                py={'40px'}
                mx={{base: '0%', lg: '20%'}}
                mb={'80px'}
                borderRadius={12}
                bgColor={'#fff'}
            >
                <Heading
                    fontSize={'28px'}
                    fontFamily={'Poppins'}
                    color={'#000'}
                    mb={'30px'}
                >Verification Code</Heading>
                <Text fontFamily={'Poppins'} fontSize={'14px'} spacing={0}>Enter your Verification code that we sent you through you email</Text>
                <form onSubmit={(event) => handleFormSubmit(event, formData)}>
                    <Stack
                        direction={{base: 'column', lg: 'row'}}
                        flexWrap={'wrap'}
                        spacing={0}
                        justifyContent={'space-between'}
                    >
                        <Box
                            w={'100%'}
                            mb={'20px !important'}
                        >
                            <FormLabel sx={formLabelCss}>OTP</FormLabel>
                            <Input
                                sx={inputCss}
                                _placeholder={{ fontWeight: '600' }}
                                _focusVisible={{ outline: 'none' }}
                                type='text'
                                name='otp'
                                value={formData.otp}
                                onChange={handleInputChange}
                            />
                            {validation && validation.data && validation.data.otp && validation.data.otp[0] && (
                                <Text color='red'>{validation.data.otp[0]}</Text>
                            )}
                        </Box>
                        <Box
                            w={'100%'}
                            mb={'20px'}
                        >
                            <Button
                                type='submit'
                                fontFamily={"Poppins"}
                                bgGradient='linear(to-b, primaryBlue.100, primaryBlue.200)'
                                _hover={{
                                    bg: 'primaryBlue.100',
                                    color: '#fff'
                                }}
                                w={'full'}
                                borderRadius={8}
                                py={6}
                                isDisabled={isLoading} // Disable the button when loading
                            >
                                {isLoading ? <Spinner thickness='4px' speed='0.65s' emptyColor='primaryBlue.200' color='white' size='lg' /> : "Verify"}
                            </Button>
                        </Box>
                        <Text
                            textAlign={'center'}
                            pt={6}
                            w={'full'}
                        >
                            <Button
                                onClick={ResendOTP}
                                color={'#858585'}
                                fontWeight={'600'}
                                fontSize={'18px'}
                                _hover={{textDecoration: 'none'}}
                            >Resend OTP</Button>
                        </Text>
                    </Stack>
                </form>
            </Stack>
            <Image src={Image1} position={'absolute'} top={0} right={0} zIndex={'-1'} />
            <Image src={Image2} position={'absolute'} bottom={0} left={0} zIndex={'-1'} />
        </Container>
    );
}