import { Heading, Stack, Link, Box, Text, Image, Spinner } from "@chakra-ui/react";
import { Link as ReactLink } from 'react-router-dom';
import Map from '../../assets/images/map.png';
import { useState, useEffect } from 'react';
import { getMyLands } from "../../utilities/ApiProvider";
import NoRecordFound from '../../assets/images/no-record-found.webp';

export default function MyLand() {

    const [isLoading, setIsLoading] = useState(true);
    const [myLands, setMyLands] = useState([]);

    useEffect(() => {
        getMyLands()
            .then(responseData => {
                setMyLands(responseData.data.records);
                setIsLoading(false);
            }).catch(error => {
                console.log(error);
                setIsLoading(false);
            });
    }, []);

    return (
        <Stack
            boxShadow={'0px 0px 25px -14px #000'}
            borderRadius={12}
            py={'20px'}
            px={'15px'}
        >
            <Stack
                direction={'row'}
                alignItems={'center'}
                justifyContent={"space-between"}
                fontFamily={'Poppins'}
                mb={'20px'}
            >
                <Heading
                    fontSize={'24px'}
                    fontWeight={'700'}
                    fontFamily={'Poppins'}
                >
                    My Land</Heading>
                <Link to="/land-registration" as={ReactLink} color={"#6249c6"} _hover={{ textDecoration: 'none' }}>Register Land</Link>
            </Stack>
            {isLoading ? ( // Check if loading is true...
                <div
                    style={{
                    position: "fixed",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    }}
                >
                    <Spinner size="xl" color="blue.500" />
                </div>
            ) : myLands && myLands.length > 0 ? (
                <Stack>
                    {
                        myLands.length &&
                        myLands?.map((v, i) => {
                            const isOwned = v.status === 1;
                            return (
                                <Link
                                    key={i}
                                    // as={ReactLink}
                                    // to="/single-land"
                                    _hover={{ textDecoration: 'none' }}
                                >
                                    <Box
                                        display={'flex'}
                                        marginBottom={'10px !important'}
                                        alignItems={'center'}
                                        boxShadow={'0px 0px 20px -14px #000'}
                                        borderRadius={12}
                                    >
                                        <Image src={Map} alt="loc image" marginRight={'20px'} flex={1} />
                                        <Box>
                                            <Heading fontSize={'22px'} fontFamily={'Poppins'} flex={1}>{v?.name}</Heading>
                                            <Text fontSize={'18px'} color={'#8f8f8f'} >{v?.full_address}</Text>
                                        </Box>
                                        <Text flex={2} color={"#6249c6"} textAlign={'right'} pr={4} pt={3} alignSelf={'flex-start'}>
                                            {isOwned ? "Owned" : "Pending"} {/* Display "Owned" or "Pending" based on the condition */}
                                        </Text>
                                    </Box>
                                </Link>
                            )
                        })
                    }
                </Stack>
            ) : (
                <Stack
                    justifyContent="center"
                    alignItems="center"
                    w="100%" // Set the width to 100% to center horizontally
                    h="30vh" // Set the height to 100vh to center vertically
                >
                    <Box textAlign="center" p={4} color="gray.500">
                        <Image src={NoRecordFound} w="150px" />
                    </Box>
                </Stack>
            )}
        </Stack>
    );
}