import { Box, Container, Heading, Image, Stack, Button, Flex, Spinner } from "@chakra-ui/react";
import MyLand from "../components/Home/MyLand";
import LandBox from "../components/Home/LandBox";
import NftBox from "../components/Home/NftBox";
import { Link as ReactLink, useParams } from "react-router-dom";
import NoRecordFound from '../assets/images/no-record-found.webp';
import ProfileMain from '../assets/images/profileMain.png';
import { useEffect, useState } from "react";
import EnvironmentFeedBox from "../components/Home/EnvironmentFeedBox";
import NftPic from '../assets/images/shop.png';
import { getOtherUserProfile } from "../utilities/ApiProvider";

export default function Profile() {

    const { id } = useParams();
    const [isLoading, setIsLoading] = useState(true);
    const [otherUserProfile, setOtherUserProfile] = useState({});
    const [myLands, setMyLands] = useState([]);
    const [myEnvironments, setMyEnvironments] = useState([]);
    const [myNfts, setMyNfts] = useState([]);
    const [myNft, setMyNft] = useState([
        NftPic, NftPic, NftPic, NftPic, NftPic, NftPic, NftPic, NftPic, NftPic,
    ]);
    const [selectedBtn, setSelectedBtn] = useState('Environment');
    
    const imageUrl = otherUserProfile ? otherUserProfile.image : null;
    const BASE_URL = process.env.REACT_APP_BASE_URL; // Replace with your base URL
    const profileImage = imageUrl ? `${BASE_URL}${imageUrl}` : null;


    useEffect(() => {
        getOtherUserProfile(id)
            .then(responseData => {
                setOtherUserProfile(responseData.data.records);
                setMyEnvironments(responseData.data.records.environments);
                setMyLands(responseData.data.records.lands);
                setMyNfts(responseData.data.records.nfts);
                setIsLoading(false);
            }).catch(error => {
                console.log(error);
                setIsLoading(false);
            });
    }, []);

    const buttonCss = {
        bgColor: 'transparent',
    }

    return (
        <Stack pb={12}>
        <Container maxW={'8xl'}>
            {isLoading ? ( // Render Spinner while isLoading is true
                <Flex
                    justifyContent="center"
                    alignItems="center"
                    height="100vh"
                >
                    <Spinner size="xl" color="blue.500" thickness="4px" speed="0.65s" emptyColor="gray.200" />
                </Flex>
            ) : (
                <Stack
                    boxShadow={'0px 0px 25px -14px #000'}
                    borderRadius={12}
                    px={'15px'}
                    py={'20px'}
                    mb={'40px'}
                    direction={{ base: 'column', lg: 'row' }}
                    alignItems={'center'}
                    justifyContent={'space-between'}
                    w={'full'}
                >
                    <Box
                        flex={1}
                        display={{ base: 'block', lg: 'flex' }}
                        alignItems={'center'}
                        gap={'5'}
                    >
                        <Image src={profileImage} style={{ width: '190px', height: '190px' }} alt={otherUserProfile.name} />
                        <Heading fontFamily={'Poppins'}>{otherUserProfile.name}</Heading>
                    </Box>
                </Stack>
            )}

            {isLoading ? ( // Render Spinner while isLoading is true
                <Flex
                    justifyContent="center"
                    alignItems="center"
                    height="100vh"
                >
                    <Spinner size="xl" color="blue.500" thickness="4px" speed="0.65s" emptyColor="gray.200" />
                </Flex>
            ) : (
                <Stack
                    flexWrap={{ base: 'warp', lg: 'no-wrap' }}
                    direction={'row'}
                    justifyContent={'space-between'}
                    gap={6}
                >
                    <Stack
                        flex={1}
                        gap={6}
                        display={{ base: 'none', lg: 'none' }}
                    >
                        {/* My Land Component */}
                        <MyLand />
                    </Stack>
                    <Stack
                        flex={2}
                    >
                        {/* Feed Component */}
                        <Stack
                            boxShadow={'0px 0px 25px -14px #000'}
                            borderRadius={12}
                            px={'15px'}
                            py={'20px'}
                        >
                            <Stack
                                direction={'row'}
                                justifyContent={'space-between'}
                                alignItems={'center'}
                                mb={'35px'}
                            >
                                <Heading fontSize={24} fontFamily={'Poppins'}>Feed</Heading>
                                <Box
                                    display={'flex'}
                                    alignItems={'center'}
                                    gap={2}
                                    fontFamily={'Poppins'}
                                    flexWrap={{ base: 'wrap', lg: 'no-wrap' }}
                                    justifyContent={'flex-end'}
                                >
                                    <Button bgGradient={selectedBtn === 'Environment' ? 'linear(to-b, primaryBlue.100, primaryBlue.200)' : 'transparent'} color={selectedBtn === 'Environment' ? '#fff' : '#c2c2c2'} _hover={{ bgColor: 'transparent' }} _focus={{ bgColor: '#573cc2' }} onClick={() => setSelectedBtn('Environment')}>Environments</Button>
                                    <Button bgGradient={selectedBtn === 'Lands' ? 'linear(to-b, primaryBlue.100, primaryBlue.200)' : 'transparent'} color={selectedBtn === 'Lands' ? '#fff' : '#c2c2c2'} _hover={{ bgColor: 'transparent' }} _focus={{ bgColor: '#573cc2' }} onClick={() => setSelectedBtn('Lands')}>Lands</Button>
                                    <Button bgGradient={selectedBtn === 'Nfts' ? 'linear(to-b, primaryBlue.100, primaryBlue.200)' : 'transparent'} color={selectedBtn === 'Nfts' ? '#fff' : '#c2c2c2'} _hover={{ bgColor: 'transparent' }} _focus={{ bgColor: '#573cc2' }} onClick={() => setSelectedBtn('Nfts')}>NFTs</Button>
                                </Box>
                            </Stack>
                            <Stack>
                                {isLoading ? (
                                    <div
                                        style={{
                                        position: "fixed",
                                        top: "50%",
                                        left: "50%",
                                        transform: "translate(-50%, -50%)",
                                        }}
                                    >
                                        <Spinner size="xl" color="blue.500" />
                                    </div>
                                ) : selectedBtn === 'Environment' ? (
                                    myEnvironments && myEnvironments.length > 0 ? (
                                        <>
                                        {myEnvironments.map((v, i) => (
                                            <EnvironmentFeedBox key={i} {...v} />
                                        ))}
                                        </>
                                    ) : (
                                        <Stack
                                            justifyContent="center"
                                            alignItems="center"
                                            w="100%" // Set the width to 100% to center horizontally
                                            h="50vh" // Set the height to 100vh to center vertically
                                        >
                                            <Box textAlign="center" p={4} color="gray.500">
                                                <Image src={NoRecordFound} w="200px" />
                                            </Box>
                                            <Box textAlign="center" p={4} color="gray.500">
                                                <Heading fontSize="xl" mb={2}>
                                                    Oops, No Records Found!
                                                </Heading>
                                            </Box>
                                        </Stack>
                                    )
                                ) : selectedBtn === 'Lands' ? (
                                    myLands && myLands.length > 0 ? (
                                        <>
                                        {myLands.map((v, i) => (
                                            <LandBox key={i} {...v} />
                                        ))}
                                        </>
                                    ) : (
                                        <Stack
                                            justifyContent="center"
                                            alignItems="center"
                                            w="100%" // Set the width to 100% to center horizontally
                                            h="50vh" // Set the height to 100vh to center vertically
                                        >
                                            <Box textAlign="center" p={4} color="gray.500">
                                                <Image src={NoRecordFound} w="200px" />
                                            </Box>
                                            <Box textAlign="center" p={4} color="gray.500">
                                                <Heading fontSize="xl" mb={2}>
                                                    Oops, No Records Found!
                                                </Heading>
                                            </Box>
                                        </Stack>
                                    )
                                ) : selectedBtn === 'Nfts' ? (
                                    <Stack
                                        spacing={0}
                                        direction={'row'}
                                        alignItems={'center'}
                                        gap={6}
                                        flexWrap={'wrap'}
                                    >
                                        { myNfts.length > 0 ? (
                                            myNfts.length &&
                                            myNfts.map((v, i) => <NftBox key={i} {...v} />)
                                        ) : (
                                            <Stack
                                                justifyContent="center"
                                                alignItems="center"
                                                w="100%" // Set the width to 100% to center horizontally
                                                h="50vh" // Set the height to 100vh to center vertically
                                            >
                                                <Box textAlign="center" p={4} color="gray.500">
                                                    <Image src={NoRecordFound} w="200px" />
                                                </Box>
                                                <Box textAlign="center" p={4} color="gray.500">
                                                    <Heading fontSize="xl" mb={2}>
                                                        Oops, No Records Found!
                                                    </Heading>
                                                </Box>
                                            </Stack>
                                        )}
                                    </Stack>
                                ) : null}
                            </Stack>
                        </Stack>
                    </Stack>
                    <Stack
                        flex={1}
                        display={{ base: 'none', lg: 'none' }}
                    >
                        <Stack
                            boxShadow={'0px 0px 25px -14px #000'}
                            gap={6}
                            borderRadius={12}
                            px={'15px'}
                            py={'20px'}>

                            <Heading fontSize={24} fontFamily={'Poppins'}>My NFT</Heading>
                            <Stack
                                direction={'row'}
                                flexWrap={'wrap'}
                                spacing={'0'}
                                gap={1}
                            >
                                {
                                    myNft?.length &&
                                    myNft?.map((v, i) => <Image src={v} key={i} w={'32%'} />)
                                }
                            </Stack>
                        </Stack>
                    </Stack>
                </Stack>
            )}
        </Container>
        </Stack>
    );
}