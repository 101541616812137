import { Stack, Textarea, Box, FormLabel, Input, Text, Button, useToast, Spinner } from '@chakra-ui/react';
import { useRef, useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { editEnvironment, updateEnvironment } from '../../utilities/ApiProvider';

export default function EditEnvForm() {
    
    const { id } = useParams(); // Get the "id" parameter from the URL
    const [formData, setFormData] = useState({
        name: '',
        description: '',
    });
    const [validation, setValidation] = useState(null);
    const [isLoading, setIsLoading] = useState(false); // Initialize loading state
    const toast = useToast();

    const handleInputChange = (event) => {
        setFormData({
        ...formData,
        [event.target.name]: event.target.value,
        });
    };

    const handleFormSubmit = (event, data) => {
        event.preventDefault();

        setIsLoading(true);

        // Include environment_id in the data object
        data.environment_id = id;

        updateEnvironment(data)
            .then(responseData => {
                setValidation(responseData);
                toast({
                    title: 'Success',
                    description: responseData.message,
                    status: 'success',
                    duration: 2000,
                    isClosable: true,
                });
                setIsLoading(false);
            }).catch(error => {
                setValidation(error.response.data);
                toast({
                    title: 'Error',
                    description: error.response.data.message,
                    status: 'error',
                    duration: 2000,
                    isClosable: true,
                });
                setIsLoading(false);
            });
    };

    useEffect(() => {
        editEnvironment(id)
            .then(responseData => {
                const { name, description } = responseData.data; // Destructure name and description from responseData
                // Set the form data with the values from responseData
                setFormData({
                    name: name,
                    description: description,
                });
            }).catch(error => {
                console.log(error);
                if (error.response && error.response.status === 400) {
                    toast({
                        title: 'Error',
                        description: error.response.data.message,
                        status: 'error',
                        duration: 2000,
                        isClosable: true,
                    });
                    // Redirect to the home page
                    window.history.pushState({}, '', '/');
                    // Trigger a popstate event to notify React Router about the URL change
                    const event = new Event('popstate');
                    window.dispatchEvent(event);
                }
            });
    }, []);


    const formLabelCss = {
        color: '#000',
        fontFamily: 'Poppins',
        fontSize: '16px',
        ml: '10px',
    }

    const inputCss = {
        px: 3,
        py: 6,
        bgColor: '#f3f3f3',
        borderRadius: '50px',
        placeholder: 'John Doe',
        border: 0,
    }

    const areaCss = {
        px: 3,
        py: 6,
        bgColor: '#f3f3f3',
        borderRadius: '25px',
        placeholder: 'John Doe',
        border: 0,
    }

    const envFilesRef = useRef(null);
    const landThumbnailRef = useRef(null);

    return (
        <form onSubmit={(event) => handleFormSubmit(event, formData)}>
            <Stack>
                <Box mb={'20px !important'}>
                    <FormLabel sx={formLabelCss}>Environment Name</FormLabel>
                    <Input
                        sx={inputCss}
                        _placeholder={{ fontWeight: '600' }}
                        _focusVisible={{ outline: 'none' }}
                        type='text'
                        name="name" 
                        value={formData.name} 
                        onChange={handleInputChange}
                    />
                    {validation && validation.status == 0 && validation.data && validation.data.name && validation.data.name[0] && (
                        <Text color='red'>{validation.data.name[0]}</Text>
                    )}
                </Box>
                <Box mb={'20px !important'}>
                    <FormLabel sx={formLabelCss}>Description</FormLabel>
                    <Textarea
                        sx={areaCss}
                        resize={'none'}
                        height={'140px'}
                        _placeholder={{ fontWeight: '600' }}
                        _focusVisible={{ outline: 'none' }}
                        name="description"
                        value={formData.description}
                        onChange={handleInputChange}
                    >

                    </Textarea>
                    {validation && validation.status == 0 && validation.data && validation.data.description && validation.data.description[0] && (
                        <Text color='red'>{validation.data.description[0]}</Text>
                    )}
                </Box>

                <Box
                    w={'100%'}
                    mb={'20px'}
                >
                    <Button
                        type="submit"
                        fontFamily={"Poppins"}
                        bgGradient='linear(to-b, primaryBlue.100, primaryBlue.200)'
                        _hover={{
                            bg: 'primaryBlue.100',
                            color: '#fff'
                        }}
                        w={'full'}
                        borderRadius={8}
                        py={6}
                        isDisabled={isLoading} // Disable the button when loading
                    >
                        {isLoading ? <Spinner thickness='4px' speed='0.65s' emptyColor='primaryBlue.200' color='white' size='lg' /> : "Update Environment"}
                    </Button>
                </Box>
            </Stack>
        </form>
    )
}
